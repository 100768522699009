import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class'
import * as masters from '@/store/modules/masters';
import { User } from '@/models/commadmin/users.model';
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import { ShopEntity } from '@/models/internal/shopEntity.model';
import { TerminalEntity } from '@/models/internal/terminalEntity.model';
import { formatEpochSec, formatEpochMilliSec } from '@/tools/datetime';

const Masters = namespace(masters.name);

@Component
export default class DialogWithMasters extends Vue { 
    @Masters.Getter('users')
    users!: User[];
    @Masters.Getter('companies')
    companies!: CompanyEntity[];
    @Masters.Getter('shops')
    shops!: ShopEntity[];
    @Masters.Getter('terminals')
    terminals!: TerminalEntity[];
    @Masters.Getter('isLoading')
    isMasterLoading!: boolean;

    protected closeEventName = 'close-event';

    protected debug = true;

    // -------------------------

    protected get isLoading(): boolean {
        return this.isMasterLoading;
        // NOTE: 継承先で個別のロード処理を入れる場合は実装すること
    }

    // -------------------------

    protected closeDialog() { 
        this.emitClose();
        // NOTE: 継承先で個別の処理を入れる場合は実装すること
    }

    protected emitClose() {
        if (this.debug) console.log('close dialog', this.closeEventName); 
        this.$emit(this.closeEventName);
    }

    // -------------------------

    protected getCompany(companyId: string) { 
        if (this.isMasterLoading) return null;
        const filtered = this.companies.filter(x => x.base.Id == companyId);
        if (filtered.length == 0) { 
            return null;
        }
        return filtered[0];
    }

    protected getCompanyName(companyId: string): string { 
        const company = this.getCompany(companyId);
        if (!company) { 
            // return '';
            return companyId;
        }
        return company.base.DisplayName;
        // return company.base.Name;
    }

    protected getShop(shopId: string) {  
        if (this.isMasterLoading) return null;
        if (shopId == '_') return null;
        const filtered = this.shops.filter(x => x.base.Id == shopId);
        if (filtered.length == 0) { 
            return null;
        }
        return filtered[0];
    }

    protected getShopName(shopId: string): string { 
        const shop = this.getShop(shopId);
        if (!shop) { 
            // return '';
            return shopId;
        }
        return shop.base.DisplayName;
        // return shop.base.Name;
    }

    protected getTerminal(terminalId: string) {  
        if (this.isMasterLoading) return null;
        const filtered = this.terminals.filter(x => x.base.Id == terminalId);
        if (filtered.length == 0) { 
            return null;
        }
        return filtered[0];
    }

    protected getTerminalCompanyName(terminalId: string) { 
        const terminal = this.getTerminal(terminalId);
        if (!terminal) { 
            return '';
        }
        const companyId = terminal.property?.CompanyId || null;
        if (!companyId) { 
            return '';
        }
        return this.getCompanyName(companyId);
    }

    protected getTerminalShopName(terminalId: string) { 
        const terminal = this.getTerminal(terminalId);
        if (!terminal) { 
            return '';
        }
        const shopId = terminal.property?.ShopId || null;
        if (!shopId) { 
            return '';
        }
        return this.getShopName(shopId);
    }

    // -------------------------

    /** UTCエポックミリ秒をフォーマットする(時刻あり) */
    protected unixMsToDateTime(ms: number): string { 
        return formatEpochMilliSec(ms, true);
    }

    /** UTCエポックミリ秒をフォーマットする(日付のみ) */
    protected unixMsToDate(ms: number): string { 
        return formatEpochMilliSec(ms);
    }

    /** UTCエポック秒をフォーマットする(時刻あり) */
    protected UnixSecToDateTime(sec: number): string { 
        return formatEpochSec(sec, true);
    }

    /** UTCエポック秒をフォーマットする(日付のみ) */
    protected UnixSecToDate(sec: number): string { 
        return formatEpochSec(sec);
    }
}