
































import Logo from "../Logo.vue";
import { namespace, Getter, Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { AuthUser } from "@/models/internal/user.model";
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import * as auth from '@/store/modules/common/auth';
import * as masters from '@/store/modules/masters';

const Auth = namespace(auth.name);
const Masters = namespace(masters.name);

@Component({
  components: {
    Logo,
  },
})
export default class Appbar extends Vue {
  @Getter('isRestrictionMode')
  isRestrictionMode!: boolean;
  @Auth.Getter('user')
  user!: AuthUser | null;
  @Auth.Getter('isSystemAdmin')
  isSystemAdmin!: boolean;
  @Auth.Action('logout')
  logout!: () => void;
  @Masters.Getter('myCompany')
  myCompany!: CompanyEntity | null;

  @Getter('isDrawerOpend')
  isDrawerOpend!: boolean;
  @Getter('isMobile')
  isMobile!: boolean;
  @Action('setIsDrawerOpend')
  setIsDrawerOpend!: (value: boolean) => void;

  get companyName() {
    if (!this.myCompany) return "Loading...";
    return this.myCompany.base.Name;
  }

  get userInitial() {
    if (!this.user) return "  ";
    return "UN"; // FIXME: [fkmy] ユーザ名があるならイニシャルを生成
  }

  get userEmail() {
    if (!this.user) return "...";
    return this.user.email;
  }

  get isInRestrictionMode() {
      return !this.isSystemAdmin && this.isRestrictionMode;
  }

  toggleDrawer() {
    console.log('toggleDrawer',this.isDrawerOpend );
    this.setIsDrawerOpend(!this.isDrawerOpend);
  }

  async logoutUser() {
    try {
      await this.logout();
      this.$router.push("/login");
    } catch (error) {
      console.error("logout error", error);
    }
  }
}
