
export const statusItems = [
    {
        label: "取引中",
        value: "inProgress",
    },
    {
        label: "取引完了",
        value: "succeeded",
    },
    {
        label: "取引中止",
        value: "canceled",
    },
    {
        label: "電断",
        value: "canceled.cloud.connectionError",
    },
    {
        label: "紙幣詰まり",
        value: "canceled.hardware.noteJam",
    },
];

export const kindItems = [
    {
        label: "QRコード",
        value: "shiagel.qr",
    },
    {
        label: "ICカード",
        value: "shiagel.ic",
    },
    // TODO: 磁気カードに正式対応する際にコメントアウトを外す
    // {
    //   label: '磁気カード',
    //   value: 'shiagel.mag',
    // },
];