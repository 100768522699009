import { RouteConfig } from 'vue-router';
//-------
import TerminalTransactions from '@/views/terminal/TerminalTransactions.vue';
//-------
import TerminalMalfunctions from '@/views/terminal/TerminalMalfunctions.vue';
import UniqueTerminalMalfunction from '@/views/terminal/UniqueTerminalMalfunction.vue';

export const terminals: RouteConfig[] = [
    // 端末別 / 取引履歴
    {
        path: '/terminals/:terminalId/transactions',
        name: 'StoreTerminalTransactions',
        component: TerminalTransactions,
        meta: { requiresAuth: true },
    },
    //  端末別 / 障害履歴
    {
        path: '/terminals/:terminalId/malfunctions',
        name: 'TerminalMalfunctions',
        component: TerminalMalfunctions,
        meta: { requiresAuth: true },
    },
    //  端末別 / 障害履歴 / 個別
    {
        path: '/terminals/:terminalId/malfunctions/:id',
        name: 'UniqueTerminalMalfunction',
        component: UniqueTerminalMalfunction,
        meta: { requiresAuth: true },
    },
];