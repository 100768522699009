



















import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { formatEpochMilliSec } from '@/tools/datetime';

@Component
export default class Trouble extends Vue {
  @Action('terminalPlannedMaintSchedules/getTerminalPlannedMaintSchedules')
  getTerminalPlannedMaintSchedules!: (payload: any) => void;
  @Getter('terminalPlannedMaintSchedules/terminalPlannedMaintSchedules')
  terminalPlannedMaintSchedules!: any;
  @Getter('auth/user') user!: any;
  loading = true;
  searchStore: any = '';
  searchCompany: any = '';
  searchTerminal: any = '';
  search = '';
  searchId = '';
  headers = [
    { text: '日時', value: 'CreatedAt' },
    { text: '端末ID', value: 'TerminalId' },
    { text: '企業ID', value: 'CompanyId' },
    { text: '店舗ID', value: 'ShopId' },
    { text: '種別', value: 'TerminalKind' },
    { text: '予約ID', value: 'ScheduleId' },
  ];

  async created() {
    this.loading = true;
    this.searchCompany = this.$route.query.company
      ? this.$route.query.company
      : this.user.userCompany;

    this.searchStore = this.$route.query.store
      ? this.$route.query.store
      : undefined;

    this.searchTerminal = this.$route.query.terminal
      ? this.$route.query.terminal
      : undefined;

    const payload = {
      companyId: this.searchCompany,
      shopId: this.searchStore,
      terminalId: this.searchTerminal,
    };
    this.getTerminalPlannedMaintSchedules(payload);
    this.loading = false;
  }

  formatEpochMilliSec(time: number) {
    return formatEpochMilliSec(time, true);
  }
}
