import GraphQL from '@/interfaces/graphql';
import { listSystemOperationHistories } from '@/graphql/queries';
import { createSystemOperationHistories } from '@/graphql/mutations';
import axios from 'axios';
import { AuthUser } from '@/models/internal/user.model';
import * as _ from 'lodash';

export interface LoggingOperationHistoryParam {
  user: AuthUser;
  viewName: string;
  viewId: string;
  operationName: string;
  operationId: string;
  detail?: object;
}

export const systemOperationHistories = {
  namespaced: true,
  state: { error: null, SystemOperationHistories: [] },
  mutations: {
    setSystemOperationHistories(state: any, payload: any) {
      state.SystemOperationHistories = payload;
    },
    appendSystemOperationHistory(state: any, payload: any) {
      state.SystemOperationHistories.push(payload);
    }
  },
  actions: {
    async getSystemOperationHistories({ commit }: any) {
      const SystemOperationHistories = await GraphQL.query(listSystemOperationHistories);
      const sortedObj = _.orderBy(
        SystemOperationHistories.data.listSystemOperationHistories.items,
        (a) => a.TimestampMs, ['desc']
      );
      commit(
        'setSystemOperationHistories',
        sortedObj
      );
    },
    async createSystemOperationHistories({ commit }: any, params: any) {
      const SystemOperationHistories = await GraphQL.query(createSystemOperationHistories, { input: params });
      commit(
        'appendSystemOperationHistory',
        SystemOperationHistories.data.createSystemOperationHistories
      );
    },
    /** システム操作履歴を保存する */
    async loggingOperationHistory(
      { commit }: any,
      value: LoggingOperationHistoryParam
    ) {
      const time = new Date().getTime();

      let clientIpAddress: any | null = null;
      try {
        const res = await axios.get('https://api.ipify.org/?format=json');
        clientIpAddress = res.data.ip;
      } catch (ex) {
        // console.error(ex);
        //IPアドレスが取得できないことは問題とはしない。
      }
      const detail = {
        clientIpAddress,
        ...value.detail
      };

      const params = {
        UserId: value.user.id,
        UserMail: value.user.email,
        TimestampMs: time,
        ViewName: value.viewName,
        ViewId: value.viewId,
        OperationName: value.operationName,
        OperationId: value.operationId,
        Detail: JSON.stringify(detail),
        CreatedAt: Math.floor(time / 1000.0),
      };

      const SystemOperationHistories = await GraphQL.query(createSystemOperationHistories, { input: params });

      commit(
        'appendSystemOperationHistory',
        SystemOperationHistories.data.createSystemOperationHistories
      );
    },
  },
  getters: {
    SystemOperationHistories: (state: any) => state.SystemOperationHistories
  },
};
