


















































































/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Component, Prop, Watch } from "vue-property-decorator";
import { namespace, Action, Getter } from "vuex-class";
import MasterDataList from "@/components/abstracts/MasterDataList";
import { TerminalEntity } from "@/models/internal/terminalEntity.model";
import SetupTerminal from "@/components/terminals/SetupTerminal.vue";
import TerminalDetail from "@/components/terminals/TerminalDetail.vue";
import TerminalEdit from "@/components/terminals/TerminalEdit.vue";
import { AuthUser } from "@/models/internal/user.model";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
/* @ts-ignore */
// import _ from "lodash";

@Component({
  components: {
    SetupTerminal,
    TerminalDetail,
    TerminalEdit,
  },
})
export default class TerminalList extends MasterDataList<TerminalEntity> {
  @Prop({ type: String, required: false, default: null })
  company?: string;
  @Prop({ type: String, required: false, default: null })
  store?: string;
  @Prop({ type: String, required: false, default: null })
  search?: string;
  @Prop({ type: String, required: false, default: null })
  status?: string;
  @Prop({ type: String, required: false, default: null })
  disability?: string;

  // --------------------

  @Getter("isMobile")
  isMobile!: boolean;

  @Action("terminals/createNewTerminalProperties")
  createNewTerminalProperties!: (payload: any) => void;

  @Getter("auth/user") user!: AuthUser;
  @Getter("auth/hasSystemPermission") hasSystemPermission!: boolean; // TODO: 使われていないので、端末編集の権限が制御されていない恐れがある

  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;

  // -------------------------

  headers = [
    { text: "ID", value: "base.Id" },
    { text: "表示名", value: "property.DisplayName" },
    { text: "企業", value: "companyName", filter: this.terminalCompanyFilter },
    { text: "店舗", value: "shopName", filter: this.searchDisplayNameFilter },
    { text: "店舗ID", value: "property.ShopId" },
    { text: "", value: "status", filter: this.searchStatusFilter }, // 稼働中|未設置, 設置済アイコン
    { text: "", value: "actions", align: "right", sortable: false }, // 設置, 設定
  ];

  // ---------------------



  deviceDelete = false;
  installDialog = false;

  // --------------------

  // @Watch("companyId", { immediate: false, deep: true })
  // onCompanyIdChange() {
  //   // Reload
  // }
  // @Watch("storeId", { immediate: false, deep: true })
  // onStoreIdChange() {
  //   // Reload
  // }

  // --------------------

  // mounted() {
  // }
  @Watch("search", { immediate: true, deep: true })
  watchSearch() {
    console.log("watchSearch", JSON.stringify(this.search));
    console.log(this.terminals);
    console.log(this.companies);
  }

  // -------------------------

  protected get items() {
    if (!this.terminals) return [];
    return this.terminals.filter((v) => {
      if (this.company && this.company.length > 0) {
        console.log(this.company, v.base.CompanyId, v);
        if (v.base.CompanyId && v.base.CompanyId != this.company) return false;
        if (v.property?.CompanyId && v.property.CompanyId != this.company) return false;
      }
      if (this.store && this.store.length > 0) {
        console.log(this.store, v.base.ShopId, v);
        if (v.base.ShopId && v.base.ShopId != this.store) return false;
        if (v.property?.ShopId && v.property.ShopId != this.store) return false;
      }
      // if (v.base.IsDelete == true) return false;
      return true;
    });
  }

  // -------------------------

  /** 端末ステータスの判断処理 */
  getItemStatus(item: TerminalEntity) {
    if (item && item.property && (!item.property.Shiagel || !item.property.Shiagel.Installed)) {
      return 'not-installed'; // 未設置
    }
    if (item && item.property && item.property.Shiagel && item.property.Shiagel.Installed && item.status) {
      return 'running'; // 稼働中
    }
    return 'stopped'; // 非稼働
  }

  // -------------------------

  // 店舗名 で フィルター
  searchDisplayNameFilter(value: any, search: any, item: TerminalEntity) {
    // console.log("searchDisplayNameFilter", value, search, item);
    // // ページ遷移時に条件として指定されている場合
    // if (this.store && this.store.length > 0) {
    //   return item.property?.ShopId === this.store;
    // }
    // NOTE: items() で実装済み
    return true;
  }

  terminalCompanyFilter(value: any, search: any, item: TerminalEntity) {
    // console.log('terminalCompanyFilter', value, search, item);
    // ページ遷移時に条件として指定されている場合
    // if (this.company) {
    //   return item.property?.CompanyId === this.company;
    // }
    // NOTE: items() で実装済み
    return true;
  }
  
  /** 端末ステータスフィルタ */
  searchStatusFilter(value: any, search: any, item: any): boolean {
    if (!this.status || this.status.length == 0) {
      return true;
    }

    const status = this.getItemStatus(item);
    // console.log('searchStatusFilter', item, status);
    if (this.status == status) return true; // 条件一致
    return false;
  }

  /** 障害状況フィルタ */
  searchDisabilityFilter(value: any, search: any, item: any): boolean {
    if (!this.disability || this.disability.length == 0) {
      return true; // 指定無し
    }
    // 指定あり
    const status = this.getItemStatus(item);
    if (status == 'not-installed') return false; // 未設置は対象外

    if (this.disability == 'none' && !item.disability) return true; // 障害無し
    if (this.disability == 'has-issue' && item.disability) return true; // 障害あり
    return false;
  }

  // -------------------------

  /*
    async deleteFileS3(filename: string) {
      try {
        await Storage.remove(filename, { level: 'protected' });
      } catch (error) {
        console.error('remove s3 file error', error);
      }
    }
    */

  /** 端末設置ダイアログを開く */
  async openInstall(item: TerminalEntity) {
    console.log("openInstall", item);
    this.selectedItem = item;
    this.installDialog = true;
  }

  onEditClose() {
    this.isEditOpened = false; // ダイアログの中の閉じるボタンによりダイアログを閉じる
  }

}
