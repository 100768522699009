













import { Component, Vue } from 'vue-property-decorator';
import CompanyList from '@/components/companies/CompanyList.vue';


@Component({
    components: {
        CompanyList,
    }
})
export default class Company extends Vue {

  search = '';

  // /** 削除済み企業を表示するかどうか */
  // isShowDeleted = false;
  // // NOTE: 共通管理と同じように削除済みを表示する必要が出た場合のために準備
  

  // async created() {
  // }
  
}
