























/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Component, Vue } from "vue-property-decorator";
import TerminalList from "@/components/terminals/TerminalList.vue";
import TerminalListHeader from "@/components/terminals/TerminalListHeader.vue";

@Component({
  components: {
    TerminalList,
    TerminalListHeader,
  },
})
export default class Device extends Vue {

  companyId: string | null = null;
  storeId: string | null = null;
  search = "";
  status = "";
  disability = "";

  created() {
    if (this.$route.query.company) this.companyId = this.$route.query.company as string;
    if (this.$route.query.store) this.storeId = this.$route.query.store as string;
  }
}
