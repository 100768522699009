import axios from 'axios';
import { Acl } from './type';
// import awsmobile from '@/aws-exports';
import { Company } from '@/models/commadmin/companies.model';
import { Group } from '@/models/commadmin/groups.model';
import { Shop } from '@/models/commadmin/shops.model';
import { Terminal } from '@/models/commadmin/terminals.model';
import { User } from '@/models/commadmin/users.model';

const TAG = '[CommAdminApi]';
export default class CommAdminApi {
    private baseUrl: string;
    private authKey: string;
    private apiKey = '';

    constructor(authKey: string) {
        this.baseUrl = process.env.VUE_APP_API_ENDPOINT as string;
        this.authKey = authKey;
        this.apiKey = process.env.VUE_APP_API_KEY as string;
    }

    private createConfig(admin: boolean) {
        const config = admin
            ? { 
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': this.apiKey,
                },
            }
            : {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.authKey,
                },
            };
        return config;
    }

    private async getRequest(path: string, admin = false) {
        const result = await axios.get(path, this.createConfig(admin));
        if (result.data.message) {
            throw new Error(result.data.message)
        }
        return result.data;
    }

    /**
     * GET /{stage}/companies
     * 導入先企業、日本カード自身などの組織を列挙する
     */
    async getCompanies() {
        const path = `${this.baseUrl}/companies`;
        const result = await this.getRequest(path);
        // console.log(TAG, 'getCompanies', result)
        const entities: Company[] = result.map((v: any) => {
            const entity = { ...v } as Company;
            entity.IsDelete = v.isDelete ? v.isDelete == 'true' : false;
            return entity;
        })
        return entities;
    }

    /**
     * GET /{stage}/companies/{companyId}/users
     */
    async getCompanyUsers(companyId: string) {
        const path = `${this.baseUrl}/companies/${companyId}/users`;
        const result = await this.getRequest(path);
        // console.log(TAG, 'getCompanyUsers', result);
        const entities: User[] = result.map((v: any) => {
            const entity = { ...v } as User;
            entity.IsDelete = v.isDelete ? v.isDelete == 'true' : false;
            return entity;
        })
        return entities;
    }

    /** 
     * GET /{stage}/companies/{companyId}/groups
     * 企業内のグループを列挙する(特定の店舗に所属しない)
     */
    async getCompanyGroups(companyId: string) {
        const path = `${this.baseUrl}/companies/${companyId}/groups`;
        const result = await this.getRequest(path);
        // console.log(TAG, 'getCompanyGroups', companyId, result)
        // return result[0] as Group[];
        const entities: Group[] = result.map((v: any) => {
            const entity = { ...v } as Group;
            // FIXME: 共通管理の修正が済んだら外す
            if (v.Kind == undefined) {
                if (v.Id.indexOf('Principal') != -1) {
                    entity.Kind = 'Principal';
                } else {
                    entity.Kind = 'Secondary';
                }
            }
            if (v.Members.Users) {
                entity.Members.Users = v.Members.Users.map((v: any) => {
                    if (v['Id']) {
                        return v;
                    }
                    return { Id: v };
                })
            }
            // entity.IsDelete = v.isDelete ? v.isDelete == 'true' : false;
            return entity;
        })
        return entities;
    }

    /**
     * GET /{stage}/companies/{companyId}/shops
     * 導入先企業に所属する導入先店舗を列挙する
     */
    async getStores(companyId: string) {
        const path = `${this.baseUrl}/companies/${companyId}/shops`;
        const result = await this.getRequest(path);
        // console.log(TAG, 'getStores', companyId, result)
        const entities: Shop[] = result.map((v: any) => {
            const entity = { ...v } as Shop;
            entity.IsDelete = v.isDelete ? v.isDelete == 'true' : false;
            return entity;
        })
        return entities;
    }

    /**
     * GET /{stage}/companies/{companyId}/shops/{shopId}/terminals
     * 導入先店舗に設置されている端末を列挙する
     */
    async getTerminals(companyId: string, shopId: string) {
        const path = `${this.baseUrl}/companies/${companyId}/shops/${shopId}/terminals`;
        const result = await this.getRequest(path);
        // console.log(TAG, 'getTerminals', companyId, shopId, result);
        const entities: Terminal[] = result.map((v: any) => {
            const entity = { ...v } as Terminal;
            entity.IsDelete = v.isDelete ? v.isDelete == 'true' : false;
            return entity;
        })
        return entities;
    }

    /**
     * GET /{stage}/user/{userId}/acl
     * 該当ユーザーがアクセス可能な企業・店舗・ターミナルを列挙する
     */
    async getUserAcl(userId: string) {
        //   console.log("getUserAcl", userId);
        const path = `${this.baseUrl}/users/${userId}/acl`;
        const result: Acl = await this.getRequest(path)
        //   console.log(TAG , 'getUserAcl', result)
        return result;
    }

    /** 端末情報 */
    async getTerminalById(id: string) {
        const terminalKind = 'TopUp';
        const path = `${this.baseUrl}/admin/terminals/${terminalKind}/${id}`;
        const result = await this.getRequest(path, true);
        const entity = { ...result } as Terminal;
        entity.IsDelete = result.isDelete ? result.isDelete == 'true' : false;
        return entity;
    }
}
