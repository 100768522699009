











import { Component, Vue } from "vue-property-decorator";
import TerminalMalfunctionList from "@/components/terminalMalfunctions/TerminalMalfunctionList.vue";
// import ListHeader from '@/components/functionalities/ListHeader.vue';

@Component({
  components: {
    TerminalMalfunctionList,
  },
})
export default class TerminalMalfunctions extends Vue {

  terminalId?: string;
  id?: string;

  async created() {
    this.terminalId = this.$route.params.terminalId;
    this.id = this.$route.params.id;
  }
}
