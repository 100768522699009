























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Notification extends Vue {
  data: Array<any> = [
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
    { log: 'log text', target: '対象', day: '2020年 9月10日' },
  ];
}
