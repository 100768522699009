import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { State } from './type';
import { RootState } from '@/store';
import { SystemPlannedMaintenanceSchedules } from "@/models/topup/systemPlannedMaintenanceSchedules.model";

export const state = (): State => ({
    version: '1.0.0',
    isLoading: false,
    isDrawerOpend: true,
    isMobile: false,
    isRestrictionMode: false,
});

export const getters: GetterTree<State, RootState> = {
    isDrawerOpend: (state) => state.isDrawerOpend,
    isMobile: (state) => state.isMobile,
    isRestrictionMode: (state) => state.isRestrictionMode,
};

export const actions: ActionTree<State, RootState> = {
    setIsDrawerOpend({ commit }, value: boolean) {
        commit('setIsDrawerOpend', value);
    },
    checkMobile({ commit }) {
        const sliderWidth = window.innerWidth;
        const mobile = sliderWidth > 768 ? false : true;
        commit('setIsMobile', mobile);
    },
    // setRestrictionMode({ commit }, value: boolean) {
    //     commit('setRestrictionMode', value);
    // },
    async checkRestrictionMode({ commit, rootGetters, dispatch }) { 
        await dispatch('systemPlannedMaintenanceSchedules/fetch', null, { root: true });
        const mainteSchedules = rootGetters['systemPlannedMaintenanceSchedules/items'] as SystemPlannedMaintenanceSchedules[];
        let isInRestriction = false;
        if (mainteSchedules[0].EndTimestampMs) {
            if (
                mainteSchedules[0]?.StartTimestampMs < Date.now() &&
                mainteSchedules[0]?.EndTimestampMs > Date.now()
            ) {
                isInRestriction = true;
            } else {
                isInRestriction = false;
            }
        } else {
            isInRestriction = false;
        }
        commit('setRestrictionMode', isInRestriction);
    }
}

export const mutations: MutationTree<State> = {
    setIsDrawerOpend(state, value: boolean) {
        state.isDrawerOpend = value;
    },
    setIsMobile(state, value: boolean) {
        state.isMobile = value;
    },
    setRestrictionMode(state, value: boolean) {
        state.isRestrictionMode = value;
    },
}
