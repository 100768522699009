









































import { Component, Prop, Vue } from "vue-property-decorator";
import { ShopEntity } from "@/models/internal/shopEntity.model";

@Component
export default class ShopDetail extends Vue {
  @Prop({ type: Object, required: true, default: null })
  shop?: ShopEntity;

  // -------------------------

//   async mounted() {
//   }

  // --------------------

  /** 企業・店舗別 / アカウント */
  moveToAccountsView() {
    if (!this.shop) {
      console.error("invalid operation. shop is null");
      return;
    }
    const companyId = this.shop.base.CompanyId;
    const storeId = this.shop.base.Id;

    const target = `/companies/${companyId}/stores/${storeId}/accounts`;
    this.$router.push({ path: target });
  }

  /** 企業・店舗別 / 端末 */
  moveToTerminalsView() {
    if (!this.shop) {
      console.error("invalid operation. shop is null");
      return;
    }
    const companyId = this.shop.base.CompanyId;
    const storeId = this.shop.base.Id;

    const target = `/companies/${companyId}/stores/${storeId}/terminals`;
    this.$router.push({ path: target });
  }

  /** 企業・店舗別 / 取引履歴 */
  moveToPaymentsView() {
    if (!this.shop) {
      console.error("invalid operation. shop is null");
      return;
    }
    const companyId = this.shop.base.CompanyId;
    const storeId = this.shop.base.Id;

    const target = `/companies/${companyId}/stores/${storeId}/transactions`;
    this.$router.push({ path: target });
  }
}
