







import { Component, Vue } from "vue-property-decorator";
import UserGroupList from "@/components/groups/UserGroupList.vue";

/** ユーザグループ(単一) */
@Component({
  components: {
    UserGroupList,
  },
})
export default class UniqueUserGroup extends Vue {

  groupId?: string;

  async created() {
    this.groupId = this.$route.params.groupId;
  }
}
