


















import { Component, Prop } from "vue-property-decorator";
import { namespace } from 'vuex-class'
import { FetchCondition} from '@/store/modules/journals/type';
import * as terminalOperationHistories from '@/store/modules/journals/terminalOperationHistories';
import JournalDataList from '@/components/abstracts/JournalDataList';
import { TerminalOperationHistory } from "@/models/topup/terminalOperationHistory.model";

const TerminalOperationHistories = namespace(terminalOperationHistories.name);


@Component
export default class TerminalOperationList extends JournalDataList<TerminalOperationHistory> {
    @Prop({ type: String, required: false, default: null })
    terminal?: string;
    @Prop({ type: String, required: false, default: null })
    search?: string;

    // -------------------------

    @TerminalOperationHistories.Getter('items')
    journalItems?: (condition?: FetchCondition) => TerminalOperationHistory[]; 
    @TerminalOperationHistories.Getter('hasNext')
    hasJournalDataNextToken?: (condition?: FetchCondition) => boolean; 
    @TerminalOperationHistories.Getter('isLoading')
    isJournalDataLoading?: boolean; 
    @TerminalOperationHistories.Action('fetch')
    fetchJournalData?: (condition?: FetchCondition) => Promise<void>; 

    // -------------------------

    headers = [
        { text: '発生日時', value: 'CreatedAt' },
        { text: '端末ID', value: 'TerminalId' },
        { text: '画面', value: 'ViewName' },
        { text: '操作', value: 'OperationName' },
        { text: '詳細データ', value: 'Detail' },
    ];

    // NOTE: 店舗情報を表示してもいいかもしれない

    // -------------------------

    protected get isLoading(): boolean {
        // if (super.isLoading) return true; // master data
        if (this.isMasterLoading) return true; // master data
        if (this.isJournalDataLoading) return true;
        return false;
    }

    protected get items(): TerminalOperationHistory[] {
        if (!this.journalItems) return [];
        const condition = this.createCondition(); // NOTE: ここの limit は使わない
        return this.journalItems(condition).filter((x) => {
            if (this.terminal && this.terminal.length > 0) {
                if (x.TerminalId != this.terminal) return false;
            }
            return true;
        }).sort(function (x, y) { // 日付の降順でソート
            return y.TimestampMs - x.TimestampMs;
        });
    }
  
    protected get hasUnloadedItems(): boolean {
        if (!this.hasJournalDataNextToken) return false;
        const condition = this.createCondition(); // NOTE: ここの limit は使わない
        return this.hasJournalDataNextToken(condition);
    }

    // -------------------------
    
    protected createCondition(limit = -1): FetchCondition | undefined { 
        let condition: FetchCondition | undefined;
        if (this.terminal && this.terminal.length > 0) {
          condition = { terminalId: this.terminal, limit };
        }
        return condition;
    }

    /** アイテム読込処理 */
    protected async loadItems(limit: number) { 
        if (!this.fetchJournalData) return;
        const condition = this.createCondition(limit);
        await this.fetchJournalData(condition);
    }

    // -------------------------
}
