







import { Component, Vue } from "vue-property-decorator";
import TerminalGroupList from "@/components/groups/TerminalGroupList.vue";

/** グループ/ターミナル */
@Component({
  components: {
    TerminalGroupList,
  },
})
export default class UniqueTerminalGroup extends Vue {

  groupId?: string;

  async created() {
    this.groupId = this.$route.params.groupId;
  }
}
