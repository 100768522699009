














































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import Logo from "@/components/Logo.vue";
import { AuthUser } from "@/models/internal/user.model";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
import { FetchCondition } from '@/store/modules/masters/type';
import * as masters from '@/store/modules/masters';

const Masters = namespace(masters.name);

@Component({
  components: {
    Logo,
  },
})
export default class Login extends Vue {
  email = "";
  password = "";
  passwordShow = false;

  loginFormValid = false;
  loginError = false;
  loading = false;

  @Action("auth/login")
  login!: ({ email, password }: { email: string; password: string }) => void;

  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;

  @Getter("auth/user") user!: AuthUser;

  @Masters.Action('fetch')
  fetchMasters!: (condition?: FetchCondition) => Promise<void>;


  // created() {
  //   // 環境変数確認の為に追加
  //   console.log("process.env", process.env);
  // }

  async logUser() {
    this.loginError = false;
    try {
      this.loading = true;

      // ログイン実行(Cognito)
      const { email, password } = this;
      await this.login({ email, password });

      // 操作履歴
      await this.loggingOperationHistory({
        user: this.user,
        viewName: "ログイン画面",
        viewId: "Login",
        operationName: "ログイン 実行",
        operationId: "login",
        detail: {
          email: email,
          userId: this.user.id,
        },
      });
      // await this.$store.dispatch("company/getCompany", true);
      // const companyId = this.$route.query.company
      //   ? this.$route.query.company.toString()
      //   : undefined;
      // const store = this.$route.query.store
      //   ? this.$route.query.store.toString()
      //   : undefined;
      // let payload: any;
      // if (companyId && store) {
      //   payload = {
      //     companyId: companyId,
      //     shopId: store,
      //     force: true,
      //   };
      // } else {
      //   payload = {
      //     force: true,
      //   };
      // }
      // await this.$store.dispatch("terminals/getTerminals", payload);

      // this.$store.commit(
      //   "terminalMalfunctionHistories/setTerminalMalfunctionHistories",
      //   null
      // );

      // マスタデータの再読込
      await this.fetchMasters({ force: true});
      this.loading = false;

      this.$router.push("/");
    } catch (error) {
      this.loginError = true;
      console.error(error);
      if (error.code === "UserNotFoundException") {
        error.message = "Incorrect username or password";
      }
    }
  }

  validationEmail(value: string | null) {
    if (!value || value.length == 0) {
      return false;
    }
    return true;
  }

  validationPassword(value: string | null) {
    // console.log("validationPassword", value);
    if (!value || value.length == 0) {
      return false;
    }
    return true;
  }
}
