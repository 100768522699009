/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTransactions = /* GraphQL */ `
  mutation CreateTransactions($input: CreateTopupTransactionsInput!) {
    createTransactions(input: $input) {
      TerminalId
      TimestampMs
      Id
      SerialNo
      TotalBalance {
        Balances {
          Kind
          Name
          Total
          Unit
        }
        Validates {
          ExpireDate
          Name
          Value
        }
      }
      Previous {
        Balances {
          Kind
          Name
          Total
          Unit
        }
        Validates {
          ExpireDate
          Name
          Value
        }
      }
      TerminalKind
      CompanyId
      ShopId
      Status
      Card {
        Kind
        Id
        PhysicalId
      }
      NoteInputHistories {
        TimestampMs
        NoteClass
      }
      NoteClass {
        Jpy1000
        Jpy2000
        Jpy5000
        Jpy10000
      }
      TotalAmount
      RefundCreated
      CreatedAt
    }
  }
`;
export const updateTransactions = /* GraphQL */ `
  mutation UpdateTransactions($input: UpdateTopupTransactionsInput!) {
    updateTransactions(input: $input) {
      TerminalId
      TimestampMs
      Id
      SerialNo
      TotalBalance {
        Balances {
          Kind
          Name
          Total
          Unit
        }
        Validates {
          ExpireDate
          Name
          Value
        }
      }
      Previous {
        Balances {
          Kind
          Name
          Total
          Unit
        }
        Validates {
          ExpireDate
          Name
          Value
        }
      }
      TerminalKind
      CompanyId
      ShopId
      Status
      Card {
        Kind
        Id
        PhysicalId
      }
      NoteInputHistories {
        TimestampMs
        NoteClass
      }
      NoteClass {
        Jpy1000
        Jpy2000
        Jpy5000
        Jpy10000
      }
      TotalAmount
      RefundCreated
      CreatedAt
    }
  }
`;
export const createRefunds = /* GraphQL */ `
  mutation CreateRefunds($input: CreateTopupRefundsInput!) {
    createRefunds(input: $input) {
      TerminalId
      TimestampMs
      TransactionId
      SerialNo
      CompanyId
      ShopId
      Status
      HasCertificate
      CertificatePinNo
      CreatedAt
    }
  }
`;
export const updateRefunds = /* GraphQL */ `
  mutation UpdateRefunds($input: UpdateTopupRefundsInput!) {
    updateRefunds(input: $input) {
      TerminalId
      TimestampMs
      TransactionId
      SerialNo
      CompanyId
      ShopId
      Status
      HasCertificate
      CertificatePinNo
      CreatedAt
    }
  }
`;
export const createShopProperties = /* GraphQL */ `
  mutation CreateShopProperties($input: CreateShopPropertiesInput!) {
    createShopProperties(input: $input) {
      Id
      Name
      PhoneNo
      InquiryInfo
      TaxOffice
      CreatedAt
    }
  }
`;
export const createTerminalProperties = /* GraphQL */ `
  mutation CreateTerminalProperties($input: CreateTerminalPropertiesinput!) {
    createTerminalProperties(input: $input) {
      Id
      Kind
      ShopId
      CompanyId
      DisplayName
      SerialNo
      SoftwareVersion
      TerminalVersion
      TerminalBoardVersion
      TerminalFirmwareVersion
      Configs {
        Location
        InquiryInfo
        Volumes {
          Attention
          Information
          Branding
        }
        BrandingLogo {
          Top {
            Position
            Arn
          }
          Bottom {
            Position
            Arn
          }
          ReceiptImage
        }
        Background {
          Image {
            Arn
          }
          Color
        }
        Dashboard {
          Logo {
            Arn
          }
          Background {
            Image {
              Arn
            }
            Color
          }
          TopUpButton {
            BackgroundColor
            BorderColor
            ShadowColor
            FontColor
          }
          BalanceButton {
            BackgroundColor
            BorderColor
            ShadowColor
            FontColor
          }
        }
        Timeout {
          DefaultTimeout
        }
        WorkSchedule {
          All
          StartTime
          EndTime
          EndMode
          NotInService {
            Arn
          }
        }
        Point {
          Use
        }
        UpperLimitAmount
        Standby {
          Mode
          Signage {
            Slide {
              Arns
              Interval
            }
          }
        }
        CreatedAt
        HasSupportStaff
      }
      Shiagel {
        Registered
        Installed
        AuthCode
        ServiceNumber
        OperationalConfigs {
          Version
          Code
          Name
          BeginDate
          Card {
            Name
            UsePoint
            Use2nd
            UseMoney
            UseFamily
            FigCardNo
            FmtCard
            LogoUrl
            UseMifare
            UseFelica
            UseBarcode
            UseQR
            UseMykey
            MykeyCode
          }
          Point {
            Name
            Unit
            Tax
            RoundTax
            TaxType
          }
          Full
          SecondPoint {
            Name
            Unit
          }
          Money {
            Name
            Unit
            UpperLimitAmount
          }
          Security
          Term {
            Version
            ShopNo
            TermNo
            ShopName
            ShopNameReceipt
            TelNoReceipt
            TermName
            ServiceName
            ZipCode
            Address
            ContactName
            EMail
          }
          Exchange
        }
      }
      TroubleShootingUrl
      CreatedAt
    }
  }
`;
export const updateTerminalProperties = /* GraphQL */ `
  mutation UpdateTerminalProperties($input: UpdateTerminalPropertiesinput!) {
    updateTerminalProperties(input: $input) {
      Id
      Kind
      ShopId
      CompanyId
      DisplayName
      SerialNo
      SoftwareVersion
      TerminalVersion
      TerminalBoardVersion
      TerminalFirmwareVersion
      Configs {
        Location
        InquiryInfo
        Volumes {
          Attention
          Information
          Branding
        }
        BrandingLogo {
          Top {
            Position
            Arn
          }
          Bottom {
            Position
            Arn
          }
          ReceiptImage
        }
        Background {
          Image {
            Arn
          }
          Color
        }
        Dashboard {
          Logo {
            Arn
          }
          Background {
            Image {
              Arn
            }
            Color
          }
          TopUpButton {
            BackgroundColor
            BorderColor
            ShadowColor
            FontColor
          }
          BalanceButton {
            BackgroundColor
            BorderColor
            ShadowColor
            FontColor
          }
        }
        Timeout {
          DefaultTimeout
        }
        WorkSchedule {
          All
          StartTime
          EndTime
          EndMode
          NotInService {
            Arn
          }
        }
        Point {
          Use
        }
        UpperLimitAmount
        Standby {
          Mode
          Signage {
            Slide {
              Arns
              Interval
            }
          }
        }
        CreatedAt
        HasSupportStaff
      }
      Shiagel {
        Registered
        Installed
        AuthCode
        ServiceNumber
        OperationalConfigs {
          Version
          Code
          Name
          BeginDate
          Card {
            Name
            UsePoint
            Use2nd
            UseMoney
            UseFamily
            FigCardNo
            FmtCard
            LogoUrl
            UseMifare
            UseFelica
            UseBarcode
            UseQR
            UseMykey
            MykeyCode
          }
          Point {
            Name
            Unit
            Tax
            RoundTax
            TaxType
          }
          Full
          SecondPoint {
            Name
            Unit
          }
          Money {
            Name
            Unit
            UpperLimitAmount
          }
          Security
          Term {
            Version
            ShopNo
            TermNo
            ShopName
            ShopNameReceipt
            TelNoReceipt
            TermName
            ServiceName
            ZipCode
            Address
            ContactName
            EMail
          }
          Exchange
        }
      }
      TroubleShootingUrl
      CreatedAt
    }
  }
`;
export const updateTerminalPropertieVersion = /* GraphQL */ `
  mutation UpdateTerminalPropertieVersion(
    $input: UpdateTerminalPropertiesVersionInput!
  ) {
    updateTerminalPropertieVersion(input: $input) {
      Id
      Kind
      ShopId
      CompanyId
      DisplayName
      SerialNo
      SoftwareVersion
      TerminalVersion
      TerminalBoardVersion
      TerminalFirmwareVersion
      Configs {
        Location
        InquiryInfo
        Volumes {
          Attention
          Information
          Branding
        }
        BrandingLogo {
          Top {
            Position
            Arn
          }
          Bottom {
            Position
            Arn
          }
          ReceiptImage
        }
        Background {
          Image {
            Arn
          }
          Color
        }
        Dashboard {
          Logo {
            Arn
          }
          Background {
            Image {
              Arn
            }
            Color
          }
          TopUpButton {
            BackgroundColor
            BorderColor
            ShadowColor
            FontColor
          }
          BalanceButton {
            BackgroundColor
            BorderColor
            ShadowColor
            FontColor
          }
        }
        Timeout {
          DefaultTimeout
        }
        WorkSchedule {
          All
          StartTime
          EndTime
          EndMode
          NotInService {
            Arn
          }
        }
        Point {
          Use
        }
        UpperLimitAmount
        Standby {
          Mode
          Signage {
            Slide {
              Arns
              Interval
            }
          }
        }
        CreatedAt
        HasSupportStaff
      }
      Shiagel {
        Registered
        Installed
        AuthCode
        ServiceNumber
        OperationalConfigs {
          Version
          Code
          Name
          BeginDate
          Card {
            Name
            UsePoint
            Use2nd
            UseMoney
            UseFamily
            FigCardNo
            FmtCard
            LogoUrl
            UseMifare
            UseFelica
            UseBarcode
            UseQR
            UseMykey
            MykeyCode
          }
          Point {
            Name
            Unit
            Tax
            RoundTax
            TaxType
          }
          Full
          SecondPoint {
            Name
            Unit
          }
          Money {
            Name
            Unit
            UpperLimitAmount
          }
          Security
          Term {
            Version
            ShopNo
            TermNo
            ShopName
            ShopNameReceipt
            TelNoReceipt
            TermName
            ServiceName
            ZipCode
            Address
            ContactName
            EMail
          }
          Exchange
        }
      }
      TroubleShootingUrl
      CreatedAt
    }
  }
`;
export const createSystemMalfunctionHistories = /* GraphQL */ `
  mutation CreateSystemMalfunctionHistories(
    $input: CreateSystemMalfunctionHistoriesInput!
  ) {
    createSystemMalfunctionHistories(input: $input) {
      Id
      TimestampMs
      Kind
      Malfunction {
        Id
        Message
        Payload
      }
      Status
      CreatedAt
      UpdatedAt
    }
  }
`;
export const createTerminalMaintenanceLogs = /* GraphQL */ `
  mutation CreateTerminalMaintenanceLogs(
    $input: CreateTerminalMaintenanceLogsInput!
  ) {
    createTerminalMaintenanceLogs(input: $input) {
      TerminalId
      TimestampMs
      Maintenance {
        IoBoard {
          Port {
            Door
            Button
            PIR
            IoBoardAwaked
          }
          DIP
          Version
        }
      }
      Result {
        IoBoard
        Printer
      }
      CreatedAt
    }
  }
`;
export const createTerminalMalfunctionHistories = /* GraphQL */ `
  mutation CreateTerminalMalfunctionHistories(
    $input: CreateTerminalMalfunctionHistoriesInput!
  ) {
    createTerminalMalfunctionHistories(input: $input) {
      TerminalId
      TimestampMs
      Id
      Kind
      KindLabel
      Malfunction {
        Id
        Message
        Payload
      }
      Status
      CreatedAt
      UpdatedAt
    }
  }
`;
export const updateTerminalMalfunctionHistories = /* GraphQL */ `
  mutation UpdateTerminalMalfunctionHistories(
    $input: UpdateTerminalMalfunctionHistoriesInput!
  ) {
    updateTerminalMalfunctionHistories(input: $input) {
      TerminalId
      TimestampMs
      Id
      Kind
      KindLabel
      Malfunction {
        Id
        Message
        Payload
      }
      Status
      CreatedAt
      UpdatedAt
    }
  }
`;
export const createTerminalPlannedMaintenanceSchedules = /* GraphQL */ `
  mutation CreateTerminalPlannedMaintenanceSchedules(
    $input: CreateTopupTerminalPlannedMaintenanceSchedules!
  ) {
    createTerminalPlannedMaintenanceSchedules(input: $input) {
      TerminalId
      StartTimestampMs
      EndTimestampMs
      ScheduleId
      SerialNo
      TerminalKind
      CompanyId
      ShopId
      UserId
      UserName
      CreatedAt
    }
  }
`;
export const updateTerminalPlannedMaintenanceSchedules = /* GraphQL */ `
  mutation UpdateTerminalPlannedMaintenanceSchedules(
    $input: UpdateTopupTerminalPlannedMaintenanceSchedules!
  ) {
    updateTerminalPlannedMaintenanceSchedules(input: $input) {
      TerminalId
      StartTimestampMs
      EndTimestampMs
      ScheduleId
      SerialNo
      TerminalKind
      CompanyId
      ShopId
      UserId
      UserName
      CreatedAt
    }
  }
`;
export const createTerminalErrorLogs = /* GraphQL */ `
  mutation CreateTerminalErrorLogs($input: CreateTerminalErrorLogsInput!) {
    createTerminalErrorLogs(input: $input) {
      Id
      TerminalId
      ErrorCode
      ErrorMessage
      ErrorTimeStamp
      CreatedAt
    }
  }
`;
export const createTerminalMaintenanceUsers = /* GraphQL */ `
  mutation CreateTerminalMaintenanceUsers(
    $input: CreateTerminalMaintenanceUsersInput!
  ) {
    createTerminalMaintenanceUsers(input: $input) {
      TerminalId
      CardNo
      CompanyId
      ShopId
      UserId
      UpdatedAt
    }
  }
`;
export const updateTerminalMaintenanceUsers = /* GraphQL */ `
  mutation UpdateTerminalMaintenanceUsers(
    $input: UpdateTerminalMaintenanceUsersInput!
  ) {
    updateTerminalMaintenanceUsers(input: $input) {
      TerminalId
      CardNo
      CompanyId
      ShopId
      UserId
      UpdatedAt
    }
  }
`;
export const createTerminalAdminUsers = /* GraphQL */ `
  mutation CreateTerminalAdminUsers($input: CreateTerminalAdminUsersInput!) {
    createTerminalAdminUsers(input: $input) {
      TerminalId
      CardNo
      CompanyId
      ShopId
      UserId
      UpdatedAt
    }
  }
`;
export const updateTerminalAdminUsers = /* GraphQL */ `
  mutation UpdateTerminalAdminUsers($input: UpdateTerminalAdminUsersInput!) {
    updateTerminalAdminUsers(input: $input) {
      TerminalId
      CardNo
      CompanyId
      ShopId
      UserId
      UpdatedAt
    }
  }
`;
export const createTerminalNoteCollectHistories = /* GraphQL */ `
  mutation CreateTerminalNoteCollectHistories(
    $input: CreateTerminalNoteCollectHistoriesInput!
  ) {
    createTerminalNoteCollectHistories(input: $input) {
      TerminalId
      TimestampMs
      NoteClass {
        Jpy1000
        Jpy2000
        Jpy5000
        Jpy10000
      }
      TotalAmount
      DailyTotalAmount
      CreatedAt
    }
  }
`;
export const createTerminalLocalizationResources = /* GraphQL */ `
  mutation CreateTerminalLocalizationResources(
    $input: CreateTerminalLocalizationResourcesInput!
  ) {
    createTerminalLocalizationResources(input: $input) {
      LocaleId
      Strings {
        Id
        Value
      }
      Voices {
        Id
        Arn
      }
      CreatedAt
    }
  }
`;
export const createTerminalOperationHistories = /* GraphQL */ `
  mutation CreateTerminalOperationHistories(
    $input: CreateTerminalOperationHistoriesInput!
  ) {
    createTerminalOperationHistories(input: $input) {
      TerminalId
      TimestampMs
      ViewName
      ViewId
      OperationName
      OperationId
      Detail
      CreatedAt
    }
  }
`;
export const createSystemPlannedMaintenanceSchedules = /* GraphQL */ `
  mutation CreateSystemPlannedMaintenanceSchedules(
    $input: CreateSystemPlannedMaintenanceSchedules!
  ) {
    createSystemPlannedMaintenanceSchedules(input: $input) {
      ScheduleId
      StartTimestampMs
      EndTimestampMs
      Message
      UserId
      UserName
      CreatedAt
    }
  }
`;
export const updateSystemPlannedMaintenanceSchedules = /* GraphQL */ `
  mutation UpdateSystemPlannedMaintenanceSchedules(
    $input: UpdateSystemPlannedMaintenanceSchedules!
  ) {
    updateSystemPlannedMaintenanceSchedules(input: $input) {
      ScheduleId
      StartTimestampMs
      EndTimestampMs
      Message
      UserId
      UserName
      CreatedAt
    }
  }
`;
export const createSystemOperationHistories = /* GraphQL */ `
  mutation CreateSystemOperationHistories(
    $input: CreateSystemOperationHistoriesInput!
  ) {
    createSystemOperationHistories(input: $input) {
      UserId
      UserMail
      TimestampMs
      ViewName
      ViewId
      OperationName
      OperationId
      Detail
      CreatedAt
    }
  }
`;
