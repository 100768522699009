









































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace, Action, Getter } from "vuex-class";
import { v4 as uuidV4 } from "uuid";
import { SystemPlannedMaintenanceSchedules } from "@/models/topup/systemPlannedMaintenanceSchedules.model";
import { AuthUser } from "@/models/internal/user.model";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
import * as auth from '@/store/modules/common/auth';

const Auth = namespace(auth.name);
const Schedules = namespace('systemPlannedMaintenanceSchedules');
const OperationHistories = namespace('systemOperationHistories');

@Component
export default class FunctionRestriction extends Vue {
  @Getter('isRestrictionMode')
  isRestrictionMode!: boolean;
  @Action('checkRestrictionMode')
  checkRestrictionMode!: () => Promise<void>;
  @Auth.Getter("user") user!: AuthUser;
  @Auth.Getter('isSystemAdmin') isSystemAdmin!: boolean;
  @Schedules.Getter("items")
  items!: SystemPlannedMaintenanceSchedules[];
  @Schedules.Action( "create")
  create!: (payload: SystemPlannedMaintenanceSchedules) => Promise<void>;
  @Schedules.Action("update")
  update!: (payload: SystemPlannedMaintenanceSchedules) => Promise<void>;
  @Schedules.Action("fetch")
  fetch!: (payload?: any) => Promise<void>;
  @OperationHistories.Action("loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;

  restriction = {
    status: "false",
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endTime:
      new Date().toTimeString().split(" ")[0].split(":")[0] +
      ":" +
      new Date().toTimeString().split(" ")[0].split(":")[1],
  };

  modal = false;

  modal2 = false;

  minDate: string = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 10)
    .toString();

  showError = false;

  scheduleId = "";

  mainteSchedules = [];

  loading = false;

  disableDateTimeField = true;

  @Watch("user", { immediate: true, deep: true })
  async onUserChange() {
    await this.checkRestrictionMode();
    // this.restriction.text = this.systemPlannedMaintenanceSchedules
    //   ? this.systemPlannedMaintenanceSchedules[0].Message
    //   : "表示中の文言";
  }

  @Watch("items", { immediate: true, deep: true })
  async onItemsChanged() {
    // TODO: checkRestrictionModeと共通化
    // if (this.items[0]?.EndTimestampMs) {
    //   if (
    //     this.items[0]?.StartTimestampMs <
    //       Date.now() &&
    //     this.items[0]?.EndTimestampMs > Date.now()
    //   ) {
    //     this.restriction.isInRestriction = true;
    //   } else {
    //     this.restriction.isInRestriction = false;
    //   }
    // } else {
    //   this.restriction.isInRestriction = false;
    // }

    if (this.isRestrictionMode) {
      // TODO: tools/datetime の処理との共通化
      this.restriction.endDate = new Date(
        this.items[0].EndTimestampMs
      )
        .toLocaleString("ja-JP")
        .split(" ")[0];
      this.restriction.endTime = new Date(
        this.items[0].EndTimestampMs
      )
        .toLocaleString("ja-JP")
        .split(" ")[1]
        .substring(0, 5);
    }

    if (this.isRestrictionMode) {
      this.disableDateTimeField = true;
    } else {
      if (this.isSystemAdmin) {
        this.disableDateTimeField = false;
      } else {
        this.disableDateTimeField = true;
      }
    }
  }

  get restrictionMessage() {
    if (this.items.length == 0) return '';
    return this.items[0].Message;
  }

  async created() {
    // 情報取得 & 判定
    await this.checkRestrictionMode();
  }

  async clickRestriction(status: boolean) {
    this.restriction.status = status ? "true" : "false";
    this.showError = false;
    this.loading = true;

    if (!status) {
      // for (const s of this.systemPlannedMaintenanceSchedules) {
      const payload = {
        ScheduleId: this.items[0].ScheduleId,
        StartTimestampMs:this.items[0].StartTimestampMs,
        EndTimestampMs: 0,
        UserId: this.user.id,
        Message: this.restrictionMessage,
        UserName: this.user.email,
        CreatedAt: this.items[0].CreatedAt,
      };
      await this.update(payload);
      // 操作履歴
      await this.loggingOperationHistory({
        user: this.user,
        viewName: "利用制限",
        viewId: "FunctionRestriction",
        operationName: "利用制限 終了",
        operationId: "endFunctionRestriction",
        detail: {
          message: this.restrictionMessage,
        },
      });
      // }
    } else {
      const endDateTime = new Date(
        this.restriction.endDate + ", " + this.restriction.endTime
      ).getTime();

      const payload = {
        ScheduleId: uuidV4(),
        StartTimestampMs: Date.now(),
        EndTimestampMs: endDateTime,
        UserId: this.user.id,
        Message: this.restrictionMessage,
        UserName: this.user.email,
        CreatedAt: Date.now(),
      };
      await this.create(payload);
      // 操作履歴
      await this.loggingOperationHistory({
        user: this.user,
        viewName: "利用制限",
        viewId: "FunctionRestriction",
        operationName: "利用制限 開始",
        operationId: "startFunctionRestriction",
        detail: {
          message: this.restrictionMessage,
        },
      });
    }
    await this.fetch();
    this.loading = false;
  }
}
