import { MutationTree } from 'vuex';
import { GroupState } from './type';
import {
    UserGroupEntity,
    TerminalGroupEntity,
} from '@/models/internal/groupEntity.model';

const mutations: MutationTree<GroupState> = {
    clearGroups(state: GroupState) {
        state.userGroups = [];
        state.terminalGroups = [];
    },
    addUserGroup(state: GroupState, payload: UserGroupEntity) {
        state.userGroups.push(payload);
    },
    addTerminalGroup(state: GroupState, payload: TerminalGroupEntity) {
        state.terminalGroups.push(payload);
    },
};

export default mutations;
