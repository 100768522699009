

// export type terminalStatusKind = 'not-installed' | 'running' | 'stopped';

export const statusItems = [
    {
        label: "未設置",
        value: 'not-installed',
        installed: false,
        status: false,
    },
    {
        label: "稼働中",
        value: 'running',
        installed: true,
        status: true,
    },
    {
        label: "非稼働",
        value: 'stopped',
        installed: true,
        status: false,
    },
];

export const disabilityItems = [
    {
        label: "正常",
        status: 'none',
    },
    {
        label: "障害中",
        status: 'has-issue',
    },
];