







// import { AuthUser } from "@/models/internal/user.model";
import { Component, Vue, Watch } from "vue-property-decorator";
// import { Action, Getter } from "vuex-class";
import TerminalMalfunctionWidget from '@/components/terminalMalfunctions/TerminalMalfunctionWidget.vue';

@Component({
  components: {
    TerminalMalfunctionWidget,
  },
})
export default class Dashboard extends Vue {
  // @Action("terminalMalfunctionHistories/getTerminalMalfunctionHistories")
  // getTerminalMalfunctionHistories!: (terminalId?: string[]) => void;
  // @Getter("terminalMalfunctionHistories/terminalMalfunctionHistories")
  // terminalMalfunctionHistories!: any;
  // @Getter("auth/user")
  // user!: AuthUser | null;
  // loading = true;
  // loadingTitle = "障害情報";
  // async mounted() {
  //   this.loading = true;
  //   this.loadingTitle = "障害情報データを読み込んでいます...";
  //   await this.getTerminalMalfunctionHistories();
  //   this.loading = false;
  //   this.loadingTitle = "障害情報";
  // }

  // dataChangedOnce = false;

  // @Watch("user", { immediate: true, deep: true })
  // async onUserChange() {
  //   if (!this.dataChangedOnce) {
  //     this.dataChangedOnce = true;
  //     this.$store.commit(
  //       "terminalMalfunctionHistories/setTerminalMalfunctionHistories",
  //       null
  //     );

  //     //jwtTokenにcompanyIdがない間に、直接にIdを入れる。Productionに移動する前に、消さなければならない。
  //     const companyId = this.$route.query.company
  //       ? this.$route.query.company.toString()
  //       : undefined;
  //     const store = this.$route.query.store
  //       ? this.$route.query.store.toString()
  //       : undefined;
  //     let payload: any;
  //     if (companyId && store) {
  //       payload = {
  //         companyId: companyId,
  //         shopId: store,
  //         force: true,
  //       };
  //     } else {
  //       payload = {
  //         force: true,
  //       };
  //     }
  //     // await this.$store.dispatch("terminals/getTerminals", payload);
  //     await this.$store.dispatch(
  //       "terminalMalfunctionHistories/getTerminalMalfunctionHistories",
  //       true,
  //       { root: true }
  //     );
  //     this.loading = false;
  //     this.loadingTitle = "障害情報";
  //   }
  // }
}
