import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store';
import { AuthAction, AuthState, UserAuth} from './auth.type';
import { Auth } from 'aws-amplify';
import { AuthUser } from '@/models/internal/user.model';
import { makeUser } from './auth.utils';


// -------------------------

export const namespaced = true;

export const name = 'auth';

// -------------------------

export const state = (): AuthState => ({
    isAuthenticated: false,
    token: null,
    user: null,
});

// export const getters: GetterTree<TerminalMalfunctionHistoryState, RootState> = {
export const getters: GetterTree<AuthState, RootState> = {
    isAuthenticated: (state) => async () => {
        // return state.isAuthenticated;
        const userInfo = await Auth.currentUserInfo();
        return (userInfo != null);
    },
    user: (state) => state.user,
    jwt: async (state) => state.token,
    isSystemAdmin: (state) => {
        if (!state.user) return false;
        return (state.user.role.indexOf('SystemAdmin') != -1);
    },
    /** システム管理者またはシステムユーザ */
    hasSystemPermission: (state) => {
        if (!state.user) return false;
        return ['SystemAdmin', 'SystemUser'].includes(state.user.role);
    }
}

export const actions: ActionTree<AuthState, RootState> = {
    async login({ commit }: AuthAction, { email, password }: UserAuth) {
        try {
            await Auth.signIn(email, password);
            const userInfo = await Auth.currentAuthenticatedUser();
            console.log('auth OK', email, userInfo);
            const { user, jwt } = await makeUser();
            // console.log(user, jwt);
            commit('setUser', user);
            commit('setToken', jwt);

            return Promise.resolve('Success');
        } catch (error) {
            console.error('auth NG', error);
            return Promise.reject(error);
        }
    },
    async logout({ commit }: AuthAction) {
        await commit('setLogout');
        return await Auth.signOut();
    },
    async authAction({ commit }: AuthAction) {
        const userInfo = await Auth.currentUserInfo();
        if (userInfo) {
            const { user, jwt } = await makeUser();
            commit('setUser', user);
            commit('setToken', jwt);
        } else {
            // ログアウトしている場合
            await commit('setLogout');
        }
        // let userInfo = await Auth.currentUserInfo();
        // await Auth.currentSession().then((res) => {
        //   const accessToken = res.getIdToken();
        //   //console.log('auth action', accessToken);
        //   const jwt = accessToken.getJwtToken();
        //   userInfo.jwtToken = jwt;
        //   userInfo.userCompany = accessToken.payload.CompanyId;
        // });
        // userInfo
        //   ? (userInfo.isAuthenticated = true)
        //   : (userInfo = { isAuthenticated: false });
    },
}

export const mutations: MutationTree<AuthState> = {
    setLogout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },
    setUser(state, payload: AuthUser) {
      state.isAuthenticated = true;
      state.user = payload;
    },
    setToken(state, payload: string) {
      state.token = payload;
    },
}