
































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Group extends Vue {
  search = '';
  customerDetail = false;
  headers = [
    { text: '店舗', value: 'store' },
    { text: '企業', value: 'company' },
    { text: '導入数', value: 'count' },
  ];
  data: Array<any> = [];
  editedIndex = -1;
  editedItem = {
    store: '',
    company: '',
    count: '',
  };

  created() {
    this.initialize();
  }

  /*
  @Watch('shopEdit', { deep: true })
  async onshopEdit(val: any) {
    val || this.close();
  }
  @Watch('shopDelete', { deep: true })
  async onshopDelete(val: any) {
    val || this.closeDelete();
  }
  */

  initialize() {
    this.data = [
      {
        store: 'A店',
        company: 'ABC1株式会社',
        count: 10,
      },
      {
        store: 'B店',
        company: 'ABC2株式会社',
        count: 10,
      },
      {
        store: 'C店',
        company: 'ABC3株式会社',
        count: 10,
      },
      {
        store: 'D店',
        company: 'ABC4株式会社',
        count: 10,
      },
      {
        store: 'E店',
        company: 'ABC5株式会社',
        count: 10,
      },
      {
        store: 'F店',
        company: 'ABC6株式会社',
        count: 10,
      },
      {
        store: 'G店',
        company: 'ABC7株式会社',
        count: 10,
      },
      {
        store: 'H店',
        company: 'ABC8株式会社',
        count: 10,
      },
      {
        store: 'I店',
        company: 'ABC9株式会社',
        count: 10,
      },
      {
        store: 'J店',
        company: 'ABC10株式会社',
        count: 10,
      },
    ];
  }

  save() {
    if (this.editedIndex > -1) {
      Object.assign(this.data[this.editedIndex], this.editedItem);
    } else {
      this.data.push(this.editedItem);
    }
    //this.close();
  }

  openDetail(item: any) {
    this.editedIndex = this.data.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.customerDetail = true;
  }
}
