import { RouteConfig } from 'vue-router';
//-------
import Group from '../views/group/Group.vue';
import GroupUser from '../views/group/user/GroupUser.vue';
import GroupDevice from '../views/group/device/GroupDevice.vue';
//-------
import UniqueUserGroup from '@/views/group/UniqueUserGroup.vue';
import UniqueTerminalGroup from '@/views/group/UniqueTerminalGroup.vue';

// FIXME: children で書き直したい。

export const groups: RouteConfig[] = [
    {
      path: '/group',
      name: 'Group',
      component: Group,
      meta: { requiresAuth: true },
    },
    {
      path: '/group/users',
      name: 'Group-User',
      component: GroupUser,
      meta: { requiresAuth: true },
    },
    {
      path: '/group/terminals',
      name: 'Group-Device',
      component: GroupDevice,
      meta: { requiresAuth: true },
    },
    // ユーザグループ
    {
        path: '/group/users/:groupId',
        name: 'UniqueUserGroup',
        component: UniqueUserGroup,
        meta: { requiresAuth: true },
    },
    // 端末グループ
    {
        path: '/group/terminals/:groupId',
        name: 'UniqueTerminalGroup',
        component: UniqueTerminalGroup,
        meta: { requiresAuth: true },
    },
];
