import { RootState } from '@/store';
import { 
    FetchCondition,
    State,
    Actions,
    Getters,
    Mutations,
} from './type';
import * as masters from '@/store/modules/masters';
import { FetchedConditonHistoryUtil } from './utils';
import { TerminalOperationHistory } from '@/models/topup/terminalOperationHistory.model';
import { getTerminalOperationHistories } from '@/interfaces/graphql/terminalOperationHistories';
import { TerminalOperationHistoriesFilterInput } from '@/API';

// -------------------------

export const namespaced = true;

export const name = 'terminalOperationHistories';

export type TerminalOperationHistoryState = State<TerminalOperationHistory>;

// -------------------------

const conditionUtil = new FetchedConditonHistoryUtil<TerminalOperationHistory, TerminalOperationHistoryState>();

// -------------------------

export const state = (): TerminalOperationHistoryState => ({
  items: [],
  conditions: [],
  isLoading: false,
});

export const getters: Getters<TerminalOperationHistoryState, RootState, TerminalOperationHistory> = {
    items: (state) => (condition?: FetchCondition) => { 
        if (!condition) { // 指定無し。全件
            return state.items;
        }
        return state.items.filter((item) => { 
            // 端末ID指定あり
            if (condition.terminalId && condition.terminalId.length > 0) { 
                if (item.TerminalId != condition.terminalId) return false;
            }
            return true;
        });
    },
    isLoading: (state) => { 
        return state.isLoading;
    },
    hasNext: (state) => (condition?: FetchCondition) => { 
        return conditionUtil.checkHasNextToken(state, condition);
    },
}

export const actions: Actions<TerminalOperationHistoryState, RootState> = {
  async fetch({ state, commit, rootGetters }, condition?) {
      if (state.isLoading) { 
          console.warn('loading operation is conflicted');
      }
      commit('setIsLoading', true);

      let limit = 1000;
      const terminalIds: string[] = [];
      // terminalIds.push('test');
      if (condition) {
          if (condition.terminalId && condition.terminalId.length > 0) {
              terminalIds.push(condition.terminalId)
          }
          limit = condition.limit;
      }
      if (terminalIds.length == 0) { 
          // 全ての端末を対象にする
          const ids = rootGetters[`${masters.name}/terminalIds`];
          terminalIds.push(...ids);
      }
      for (const terminalId of terminalIds) {
          // 前回実行時の nextToken を準備する
          const previousNextToken  = conditionUtil.getNextToken(state, { terminalId, limit });
          // query
          const filter: TerminalOperationHistoriesFilterInput = {
              TerminalId: {
                  eq: terminalId,
              },
          }; // TODO: これで合っているのか？
          try {
              const { result, nextToken } = await getTerminalOperationHistories(filter, limit, previousNextToken);
              // const sortedObj = _.orderBy(res, (a) => new Date(a.TimestampMs), ['desc']);
              if (result.length > 0) { 
                  commit('addItems', result);
              }
              // 読込履歴を記録
              commit('addFetchedConditon', conditionUtil.create(
                  {
                      terminalId,
                      limit,
                  },
                  result.length,
                  nextToken,
              ));

          } catch (error) {
              console.error('Get Terminal Malfunction Histories error', error);
          }
      }

      commit('setIsLoading', false);
  },
  async clearItems({ commit }) { 
      commit('setItems', []);
      commit('setFetchedConditions', []);
  }
}

export const mutations: Mutations<TerminalOperationHistoryState, TerminalOperationHistory> = {
  setItems(state, items) { 
      state.items = items;
  },
  addItems(state, items) {
      console.log('addItems', state.items.length, items.length);
      for (const item of items) { 
          // 重複チェック
          const exits = state.items.filter(x => x.TerminalId == item.TerminalId && x.TimestampMs == item.TimestampMs);
          if (exits.length > 0) {
              continue;
          }
          state.items.push(item);
      }
  },
  setFetchedConditions(state, conditions) { 
      state.conditions = conditions;
  },
  addFetchedConditon(state, value) {
      state.conditions.push(value);
  },
  setIsLoading(state, value) {
      state.isLoading = value;
  },
}
