
import GraphQL from '@/interfaces/graphql';
import { getShopPropertiesById } from '@/graphql/queries';
import { ShopProperty } from '@/models/topup/shopProperty.model';



export const getShopProperties = async (id: string) => {
    const params = { Id: id };
    const result = await GraphQL.query(getShopPropertiesById, params);
    const property = result.data.getShopPropertiesById
        ? result.data.getShopPropertiesById as ShopProperty
        : { Id: id, Name: '', CreatedAt: 0 } as ShopProperty;
    // NOTE: なぜかデータによりフィールドがあったり無かったりするので、対策する
    if (!property.PhoneNo) property.PhoneNo = '';
    if (!property.InquiryInfo) property.InquiryInfo = '';
    if (!property.TaxOffice) property.TaxOffice = '';
    return property;
} 