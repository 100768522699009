import { 
    FetchCondition,
    FetchedConditonHistory,
    State
} from './type';

export class FetchedConditonHistoryUtil<T, S extends State<T>> { 
    create(
        condition: FetchCondition,
        fetchedItems: number,
        nextToken: string
    ) { 
        const fetchedAt = new Date().getTime();
        return {
            ...condition,
            fetchedAt,
            fetchedItems,
            nextToken,
        } as FetchedConditonHistory
    }

    /** 過去の読込履歴を取得 */
    getPrevious(state: S, condition?: FetchCondition) {
        if (!condition) {
            return state.conditions; // TODO;
        }
        return state.conditions.filter(x => {
            if (condition.companyId && condition.companyId.length > 0) {
                if (x.companyId != condition.companyId) return false;
            }
            if (condition.shopId && condition.shopId.length > 0) {
                if (x.shopId != condition.shopId) return false;
            }
            if (condition.terminalId && condition.terminalId.length > 0) {
                if (x.terminalId != condition.terminalId) return false;
            }
            return true;
        }).sort((a, b) => b.fetchedAt - a.fetchedAt); // sort DESC
    }

    /** 過去の読込履歴のうち、コンディションごとに最新のものを取得 */
    getLatest(state: S, condition?: FetchCondition) { 
        const previous = this.getPrevious(state, condition);
        const existKeys: string[] = [];
        const results: FetchedConditonHistory[] = [];
        if (!condition) { 
            return previous; // TODO
        }
        // 最新のもののみにする(重複を除外)
        for (const x of previous) { 
            let companyId = '*'; 
            let shopId = '*'; 
            let terminalId = '*';
            if (!condition) {
                if (x.companyId) {
                    companyId = x.companyId;
                }
                if (x.shopId) {
                    shopId = x.shopId;
                }
                if (x.terminalId) {
                    terminalId = x.terminalId;
                }
            } else { 
                if (condition.companyId && condition.companyId.length > 0 && x.companyId) {
                    companyId = x.companyId;
                }
                if (condition.shopId && condition.shopId.length > 0 && x.shopId) {
                    shopId = x.shopId;
                }
                if (condition.terminalId && condition.terminalId.length > 0 && x.terminalId) {
                    terminalId = x.terminalId;
                }
            }
            const key = `${companyId}/${shopId}/${terminalId}`;
            if (existKeys.includes(key)) { 
                continue;
            }
            existKeys.push(key);
            results.push(x);
            // NOTE: fetchedAtの降順になっていることが前提
        }
        return results;
    }

    getNextToken(state: S, condition?: FetchCondition): string | null { 
        let result: string | null = null;
        const previous = this.getLatest(state, condition);
        const filterd = previous.filter(x => x.nextToken != null);
        if (filterd.length > 0) {
            result = filterd[0].nextToken; // 最新かつ値あり
        }
        return result;
    }

    checkHasNextToken(state: S, condition?: FetchCondition) { 
        const previous = this.getPrevious(state, condition);
        const filterd = previous.filter(x => x.nextToken != null);
        if (previous.length != 0, filterd.length == 0) { 
            // 読込済み、かつ、もう読み込むものが無い
            return false;
        }
        return true;
    }
}

