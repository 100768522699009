import { getAllSystemMalfunctionHistories } from '@/interfaces/graphql/systemMalFunctionHistories'

export const systemMalfunctionHistories = {
  namespaced: true,
  state: { error: null, sysMalfuncHistories: [] },
  mutations: {
    setSysMalfuncHistories(state: any, payload: any) {
      state.sysMalfuncHistories = payload;
    },
  },
  actions: {
    async getSysMalfuncHistories({ commit }: any) {
      const result = await getAllSystemMalfunctionHistories(null)
      commit('setSysMalfuncHistories', result);
    },
  },
  getters: {
    sysMalfunctionHistories: (state: any) => state.sysMalfuncHistories,
  },
};
