





























import { Component, Prop } from "vue-property-decorator";
import { namespace } from 'vuex-class'
import { FetchCondition} from '@/store/modules/journals/type';
import * as terminalMalfunctionHistories from '@/store/modules/journals/terminalMalfunctionHistories';
import JournalDataList from '@/components/abstracts/JournalDataList';
import TerminalMalfunctionDetail from '@/components/terminalMalfunctions/TerminalMalfunctionDetail.vue';
import { TerminalMalfunctionHistory } from "@/models/topup/terminalMalfunctionHistory.model";

const TerminalMalfunctionHistories = namespace(terminalMalfunctionHistories.name);

interface Status {
  open: string;
  closed: string;
  inProgress: string;
}

const statusItems: Status = {
    open: "故障",
    inProgress: "故障", //対応ワードが分からないため、一旦故障。
    closed: "解決済み",
};

@Component({
  components: {
    TerminalMalfunctionDetail,
  },
})
export default class TerminalMalfunctionList extends JournalDataList<TerminalMalfunctionHistory> {
    @Prop({ type: String, required: false, default: null })
    terminal?: string;
    @Prop({ type: String, required: false, default: null })
    id?: string;
    @Prop({ type: Number, required: false, default: null })
    ts?: number;
    @Prop({ type: String, required: false, default: null })
    status?: string;
    @Prop({ type: String, required: false, default: null })
    kind?: string;

    // -------------------------

    @TerminalMalfunctionHistories.Getter('items')
    journalItems?: (condition?: FetchCondition) => TerminalMalfunctionHistory[]; 
    @TerminalMalfunctionHistories.Getter('hasNext')
    hasJournalDataNextToken?: (condition?: FetchCondition) => boolean; 
    @TerminalMalfunctionHistories.Getter('isLoading')
    isJournalDataLoading?: boolean; 
    @TerminalMalfunctionHistories.Action('fetch')
    fetchJournalData?: (condition?: FetchCondition) => Promise<void>; 

    // -------------------------

    headers = [
        { text: "障害状況", value: "Status" },
        { text: "発生日時", value: "TimestampMs" },
        { text: "種別", value: "KindLabel" },
        { text: "端末ID", value: "TerminalId" },
        { text: "作成時間", value: "CreatedAt" },
        // { text: "更新時間", value: "UpdatedAt" },
    ];

    // NOTE: 店舗情報を表示してもいいかもしれない

    // -------------------------

    protected get isLoading(): boolean {
        // if (super.isLoading) return true; // master data
        if (this.isMasterLoading) return true; // master data
        if (this.isJournalDataLoading) return true;
        return false;
    }

    protected get items(): TerminalMalfunctionHistory[] {
        if (!this.journalItems) return [];
        const condition = this.createCondition(); // NOTE: ここの limit は使わない
        return this.journalItems(condition).filter((x) => {
            // if (this.terminal && this.terminal.length > 0) {
            //     if (x.TerminalId != this.terminal) return false;
            // }
            if (this.id && this.id.length > 0) {
                if (x.Id != this.id) return false;
            }
            if (this.ts && this.ts > 0) {
                if (x.TimestampMs != this.ts) return false;
            }
            if (this.kind && this.kind.length > 0) {
                if (x.Kind != this.kind) return false;
            }
            if (this.status && this.status.length > 0) {
                if (x.Status != this.status) return false;
            }
            return true;
        }).sort(function (x, y) { // 日付の降順でソート
            return y.TimestampMs - x.TimestampMs;
        });
    }
  
    protected get hasUnloadedItems(): boolean {
        if (!this.hasJournalDataNextToken) return false;
        const condition = this.createCondition(); // NOTE: ここの limit は使わない
        return this.hasJournalDataNextToken(condition);
    }

    // -------------------------
    
    protected createCondition(limit = -1): FetchCondition | undefined { 
        let condition: FetchCondition | undefined;
        if (this.terminal && this.terminal.length > 0) {
          condition = { terminalId: this.terminal, limit };
        }
        return condition;
    }

    /** アイテム読込処理 */
    protected async loadItems(limit: number) { 
        if (!this.fetchJournalData) return;
        const condition = this.createCondition(limit);
        await this.fetchJournalData(condition);
    }

    // -------------------------

    getStatusLabel(value: keyof Status): string {
        if (Object.keys(statusItems).includes(value)) {
            return statusItems[value];
        }
        return "unknown"; //もし知らないステータスが出てきたら、未知にする。
    }
}
