import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as lodash from 'lodash';

const TAG = '[GraphQl]';

const GraphQL = {
    query: async (query: string, filter: any = {}, retry = 3): Promise<GraphQLResult<any>> => {
        // console.log(TAG, 'query', query.split('\n').join(' ').slice(0, 45), filter);
        try {
            const result = await API.graphql(
                graphqlOperation(query, lodash.cloneDeep(filter))
            ) as GraphQLResult<any>;
            return result;
        } catch (e) {
            // console.log(TAG, 'query','retry', retry);
            if (retry <= 0) {
                // console.error(TAG, e, query, filter);
                throw e;
            }
            return await GraphQL.query(query, filter, retry - 1);
        }
    },
    mutation: async (query: string, payload: any = {}, retry = 3) => {
        // console.log(TAG, 'mutation', query.split('\n').join(' ').slice(0, 45), payload);
        try {
            const result = await API.graphql(
                graphqlOperation(query, lodash.cloneDeep(payload))
            ) as GraphQLResult<any>;
            return result;
        } catch (e) {
            // console.error(TAG, 'mutation','retry', retry);
            if (retry <= 0) {
                // console.error(TAG, e, query, payload);
                throw e;
            }
            return await GraphQL.query(query, payload, retry - 1);
        }
    },
};

export default GraphQL;
