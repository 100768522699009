
export const roleItems = [
    {
      label: '店舗スタッフ',
      value: 'ShopUser',
    },
    {
      label: '店舗管理者',
      value: 'ShopManager',
    },
    {
      label: 'スーパーバイザー',
      value: 'CorpSuperVisor',
    },
    {
      label: '企業管理者',
      value: 'CorpAdmin',
    },
    {
      label: 'システムユーザ',
      value: 'SystemUser',
    },
    {
      label: 'システム管理者',
      value: 'SystemAdmin',
    },
];

export function getRoleLabel(role: string) {
    for (const item of roleItems) {
        if (item.value == role) {
            return item.label
        }
    }
    return '';
}