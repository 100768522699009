
































import { Component, Prop, Vue } from "vue-property-decorator";
import { TerminalMalfunctionHistory } from "@/models/topup/terminalMalfunctionHistory.model";
import { formatEpochMilliSec } from "@/tools/datetime";

@Component
export default class TerminalMalfunctionDetail extends Vue {
  @Prop({ type: Object, required: true, default: null })
  malfunction?: TerminalMalfunctionHistory;

  // -------------------------

  //   async mounted() {
  //   }

  // --------------------

  formatEpochMilliSec(time: number) {
    return formatEpochMilliSec(time, true); // NOTE: ここは秒まで必要かも。
  }
}
