














































































import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ShopEntity } from '@/models/internal/shopEntity.model';
import { statusItems, kindItems } from '@/models/view/transaction';
import * as masters from "@/store/modules/masters";

const Masters = namespace(masters.name);

@Component
export default class TransactionListHeader extends Vue {
    @Prop({ type: String, required: false, default: null })
    company?: string; // 企業ID
    @Prop({ type: String, required: false, default: null })
    shop?: string; // 店舗ID
    @Prop({ type: Boolean, required: false, default: true })
    shopEditable?: boolean; // 店舗が指定可能か
    @Prop({ type: String, required: false, default: null })
    query?: string; // 検索ワード
    @Prop({ type: String, required: false, default: null })
    status?: string; // 取引ステータス
    @Prop({ type: String, required: false, default: null })
    kind?: string; // カード種別
    @Prop({ type: Array, required: false, default: null })
    range?: string[]; // 期間

    @Masters.Getter('shops') shops!: ShopEntity[];

    shopDisabled = false;

    showDatapicker = false;
    workRangeValue: string[] = [];

    // 店舗ID
    get shopId() {
        return this.shop || '';
    }
    set shopId(value: string) {
        this.$emit('on-shop-changed', value);
    }
    // 検索ワード
    get search() {
        return this.query || '';
    }
    set search(value: string) {
        this.$emit('on-query-changed', value);
    }
    // 取引ステータス
    get statusValue() {
        return this.status || '';
    }
    set statusValue(value: string) {
        this.$emit('on-status-changed', value);
    }
    // カード種別
    get kindValue() {
        return this.kind || '';
    }
    set kindValue(value: string) {
        this.$emit('on-kind-changed', value);
    }
    // 期間
    get dates() {
        return this.range || [];
    }
    set dates(value: string[]) {
        const sorted = this.sortDates(value);
        this.$emit('on-range-changed', sorted);
    }

    // 店舗リスト(select用)
    get shopItems() {
        // 企業IDが指定されている場合は、店舗を絞る
        if (this.company && this.company.length > 0) {
            return this.transform(this.shops.filter((v) => {
                return v.base.CompanyId == this.company;
            }));
        }
        return this.transform(this.shops);
    }

    get statusItems() {
      return statusItems;
    }
    get kindItems() {
      return kindItems;
    }
    get rangeLavel() {
        if (this.workRangeValue.length == 0) {
            return '';
        }
        // console.log(this.workRangeValue);
        const dates = this.sortDates(this.workRangeValue);
        if (dates.length == 2) {
            return `${dates[0]}～${dates[1]}`
        }
        return dates.join(', ');
    }

    mounted() {
        // console.log(this.company, this.shop, this.query);
        this.workRangeValue = this.dates; // NOTE: vuetifyのdata pickerのmodelがprop渡しだと動かないため、物理的にフィールドが必要
    }

    transform(values: ShopEntity[]) {
        return values.map(v => {
            // NOTE: 企業名と店舗名を同時に選ばせる場合など、表示名を弄る可能性があるので 
            //      item-text/item-value プロパティは使わずにこうしておく
            return {
                text: v.base.DisplayName,
                value: v.base.Id
            };
        })
    }

    onDatapickerClose(cancel = false) {
        if (!cancel) {
            this.dates = this.workRangeValue;
        }
        this.showDatapicker = false;
    }
    onDataPickerCloseAndClear() {
        this.dates = [];
        this.workRangeValue = [];
        this.showDatapicker = false;
    }

    sortDates(dates: string[]) {
        if (dates.length < 2) {
            return dates;
        }
        return dates.sort((a, b) => {
            const ad = new Date(a).getTime();
            const bd = new Date(b).getTime();
            return ad - bd;
        })
    }
}

