import { render, staticRenderFns } from "./UniqueUserGroup.vue?vue&type=template&id=eb0f21ba&scoped=true&lang=pug&"
import script from "./UniqueUserGroup.vue?vue&type=script&lang=ts&"
export * from "./UniqueUserGroup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb0f21ba",
  null
  
)

export default component.exports