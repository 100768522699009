








import { Component, Vue } from "vue-property-decorator";
import UserGroupList from "@/components/groups/UserGroupList.vue";

/** グループ/ユーザ */
@Component({
  components: {
    UserGroupList,
  },
})
export default class Group extends Vue {

  companyId: string | null = null;
  storeId: string | null = null;

  created() {
    if (this.$route.query.company) this.companyId = this.$route.query.company as string;
    if (this.$route.query.store) this.storeId = this.$route.query.store as string;
  }
}
