










import { Component, Vue } from "vue-property-decorator";
import TerminalMalfunctionList from "@/components/terminalMalfunctions/TerminalMalfunctionList.vue";
// import ListHeader from '@/components/functionalities/ListHeader.vue';

@Component({
  components: {
    TerminalMalfunctionList,
  },
})
export default class DeviceTrouble extends Vue {

  terminalId?: string = "";
  timestampMs?: number = 0;

  async created() {
    this.terminalId = this.$route.query.terminal
      ? this.$route.query.terminal as string
      : undefined;
    this.timestampMs = this.$route.query.time
      ? parseInt(this.$route.query.time as string)
      : undefined;
  }
}
