import GraphQL from '@/interfaces/graphql';
import { listTerminalPlannedMaintenanceSchedules } from '../../CustomQueries/terminalPlannedMaintSchedules.queries';
import { Auth } from 'aws-amplify';

interface TerminalPlannedSchedulesPayload {
  CompanyId: {
    eq: string;
  };
  ShopId?: {
    eq: string;
  };
  TerminalId?: {
    eq: string;
  };
}

export const terminalPlannedMaintSchedules = {
  namespaced: true,
  state: { error: null, terminalPlannedMaintSchedules: [] },
  mutations: {
    setTerminalPlannedMaintSchedules(state: any, payload: any) {
      state.terminalPlannedMaintSchedules = payload;
    },
  },
  actions: {
    async getTerminalPlannedMaintSchedules(
      { commit }: any,
      payload: { companyId: string; shopId?: string; terminalId?: string }
    ) {
      //console.log('payload:', payload);

      if (!payload.companyId) {
        const authSession = await Auth.currentSession();
        const accessToken = authSession.getIdToken();
        payload.companyId = accessToken.payload.CompanyId;
      }

      const limit = 1000;
      const filter: TerminalPlannedSchedulesPayload = {
        CompanyId: { eq: payload.companyId },
      };

      if (payload.shopId) filter.ShopId = { eq: payload.shopId };

      if (payload.terminalId) filter.TerminalId = { eq: payload.terminalId };
      const termSchedules = await GraphQL.query(listTerminalPlannedMaintenanceSchedules, { filter, limit });
      //convert from timestamp to readable date
      // TODO: tools/datetime の処理との共通化 と viewへの移動
      termSchedules.data.listTerminalPlannedMaintenanceSchedules.items.map(
        (malfunction: any) => {
          malfunction.UpdatedAt = new Date(
            malfunction.UpdatedAt * 1000
          ).toLocaleString('ja-JP');
        }
      );
      commit(
        'setTerminalPlannedMaintSchedules',
        termSchedules.data.listTerminalPlannedMaintenanceSchedules.items
      );
    },
  },
  getters: {
    terminalPlannedMaintSchedules: (state: any) =>
      state.terminalPlannedMaintSchedules,
  },
};
