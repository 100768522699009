// import CommAdminApi from '@/interfaces/commAdminApi'
import TopupAdminApi from '@/interfaces/topupAdminApi'
import { TerminalEntity } from '@/models/internal/terminalEntity.model'
// import { AuthUser } from '@/models/internal/user.model';
import GraphQL from '@/interfaces/graphql';
// import { getTerminalPropertiesById } from '@/graphql/queries';
import {
  updateTerminalProperties,
} from '@/graphql/mutations';
// import { CompanyEntity } from '@/models/internal/companyEntity.model';
// import { ShopEntity } from '@/models/internal/shopEntity.model';
// import { Terminal } from '@/models/commadmin/terminals.model';
// import { TerminalProperty } from '@/models/topup/terminalProperty.model';
import * as lodash from 'lodash';

interface TerminalsState {
  terminals: TerminalEntity[];
  subTerminals: TerminalEntity[];
}

const state: TerminalsState = {
  terminals: [],
  subTerminals: []
};

export type SetupTerminalPayload = { terminalId: string; authCode: string }

// const getPropertiesById = async (terminalId: string, kind: string) => {
//   const filter = { Id: terminalId, Kind: kind };
//   const result = await GraphQL.query(getTerminalPropertiesById, filter);
//   const data = result.data.getTerminalPropertiesById
//     ? result.data.getTerminalPropertiesById
//     : {};
//   return data as TerminalProperty;
// }

// const process = async (context: { rootGetters: any }, terminal: Terminal, companyId: string, shopId: string) => {
//   // TerminalProperty を DynamoDB から取得
//   const kind = terminal.Kind ? terminal.Kind : 'TopUp';
//   const property = await getPropertiesById(terminal.Id, kind);
//   const entity = {
//     base: terminal,
//     property,
//     status: true,
//   } as TerminalEntity;
//   { // 企業情報の引き当て
//     const companies: CompanyEntity[] = context.rootGetters['company/companies'];
//     const res = companies.filter((v: CompanyEntity) => {
//       return v.base.Id == companyId
//     });
//     if (res.length > 0) {
//       entity.company = {
//         Id: companyId,
//         Name: res[0].base.Name,
//         DisplayName: res[0].base.DisplayName,
//       }
//     }
//   }
//   { // 店舗情報の引き当て
//     const shops: ShopEntity[] = context.rootGetters['shops/shops'];
//     const res = shops.filter((v) => {
//       return v.base.Id == shopId;
//     });
//     if (res.length > 0) {
//       entity.shop = {
//         Id: shopId,
//         Name: res[0].base.Name,
//         DisplayName: res[0].base.DisplayName,
//       }
//     }
//   }
//   return entity;
// }

export const terminals = {
  namespaced: true,
  state,
  mutations: {
    // setTerminals(state: TerminalsState, payload: TerminalEntity[]) {
    //   state.terminals = payload;
    // },
    setSubTerminals(state: TerminalsState, payload: TerminalEntity[]) {
      state.subTerminals = payload;
    },
    // appendTerminals(state: TerminalsState, payload: TerminalEntity[]) {
    //   for (const entity of payload) {
    //     state.terminals.push(entity);
    //   }
    // },
    // addTerminal(state: TerminalsState, payload: TerminalEntity) {
    //   state.terminals.push(payload);
    // }
  },
  actions: {
    // clearTerminals({ commit }: any) {
    //   commit('setTerminals', []);
    // },
    // /** 端末情報のロード */
    // async getTerminals(
    //   { commit, dispatch, rootGetters }: any,
    //   payload?: {
    //     companyId?: string;
    //     shopId?: string;
    //     append?: boolean;
    //     force?: boolean;
    //   }
    // ) {
    //   const force = payload?.force || false;
    //   if (!force && state.terminals.length > 0) {
    //     // console.log('getTerminals', 'skip loading', payload);
    //     return; // すでに読み込み済みの場合
    //   }

    //   const appendMode = (payload && payload.append && payload.append == true);
    //   if (!appendMode) {
    //     dispatch('clearTerminals');
    //   }

    //   // console.log('getTerminals', payload);

    //   // 必要な企業、店舗データのロード
    //   await Promise.all([
    //     dispatch('company/getCompany', null, { root: true }),
    //     dispatch('shops/getShops', null, { root: true }),
    //     dispatch('auth/authAction', null, { root: true })
    //   ])
    //   const user: AuthUser = await rootGetters['auth/user'];
    //   // console.log("User", user)

    //   // 対象の企業ID
    //   const companyIds: string[] = [];
    //   if (payload && payload.companyId) {
    //     companyIds.push(payload.companyId);
    //   } else {
    //     // 指定が無い場合は今のユーザが読み込める全ての企業を取得
    //     const companies: CompanyEntity[] = rootGetters['company/companies'];
    //     for (const company of companies) {
    //       if (companyIds.includes(company.base.Id)) {
    //         continue; // 重複対策
    //       }
    //       companyIds.push(company.base.Id);
    //     }
    //   }
    //   // console.log('getTerminals', 'target companies', companyIds);

    //   const authKey = await rootGetters['auth/jwt'];
    //   const api = new CommAdminApi(authKey);
    //   const acl = await api.getUserAcl(user.id);

    //   const entities: TerminalEntity[] = [];

    //   for (const companyId of companyIds) {
    //     //FXIME: storeから取得する
    //     let shops
    //     try {
    //       shops = await api.getStores(companyId);
    //     } catch (error) {
    //       console.log(error)
    //       continue;
    //     }
    //     // console.log('getTerminals', 'target shops', companyId, shops);
    //     for (const shop of shops) {
    //       let terminals
    //       try {
    //         terminals = await api.getTerminals(companyId, shop.Id);
    //         console.log(terminals);
            
    //       } catch (error) {
    //         console.log(error)
    //         continue;
    //       }
    //       // console.log('getTerminals', 'target terminals', companyId, shop.Id, terminals);
    //       for (const terminal of terminals) {
    //         if (entities.filter(x => x.base.Id == terminal.Id).length > 0) {
    //           continue; // 重複があればスキップ
    //         }
    //         if (!acl.Terminals.includes(terminal.Id)) continue;
    //         if (terminal.Kind != 'TopUp') continue;
    //         const entity = await process({ rootGetters }, terminal, companyId, shop.Id);
    //         entities.push(entity)
    //         commit('addTerminal', entity);
    //       }
    //       // console.log('getTerminals', entities.length, entities);

    //       // // 一覧表示のために表示用キー値, 店舗情報を 追加する
    //       // tempResult.data.map((store: any) => {
    //       //   store.itemKey = shop.id + "_" + store.id;
    //       //   store.shop = shop;
    //       // });
    //     }
    //   }

    //   // TODO: shopId指定がなくてもいいことを確認する。
    //   // } else {
    //   //   const terminals = await api.getTerminals(companyId, payload.shopId);
    //   //   for (const terminal of terminals) {
    //   //     if (terminal.Kind != 'TopUp') continue;
    //   //     const entity = await process(terminal);
    //   //     entities.push(entity)
    //   //   }
    //   // }
    // },
    
    async updateTerminalProperties({ commit }: any, payload: any) {
      // const input = { input: payload };
      const input = { input: lodash.cloneDeep(payload) };
      console.log(input);

      const result = await GraphQL.mutation(updateTerminalProperties, input);
      // const result = await GraphQL.mutation(updateTerminalProperties, payload);
    },
    async setupTerminal({ commit }: any, payload: SetupTerminalPayload) {
      const api = new TopupAdminApi();
      try {
        await api.setupTerminal(payload.terminalId, payload.authCode)
      } catch (error) {
        console.error(error)
        throw new Error('端末設置に失敗しました。');
      }
    },
  },
  getters: {
    terminals: (state: TerminalsState) => state.terminals,
    subTerminals: (state: TerminalsState) => state.subTerminals,
    terminalIds: (state: TerminalsState) => state.terminals.map(x => x.base.Id),
  },
};


