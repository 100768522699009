import { Auth } from 'aws-amplify';
import { AuthUser } from '@/models/internal/user.model';

export const makeUser = async () => {
    // const userInfo = await Auth.currentUserInfo();
    const authSession = await Auth.currentSession()
    const accessToken = authSession.getIdToken();
    const payload = accessToken.payload;
    const jwt = accessToken.getJwtToken();
    // TODO: [fkmy] refresh token考慮は必要？
  
    const user = {
        id: payload.sub as string || '',
        email: payload.email as string || '',
        groupId: payload.GroupId || '',
        companyId: payload.CompanyId || '',
        shopId: payload.ShopId || '',
        role: payload['cognito:groups'][0] || [],
    } as AuthUser;
    return { user, jwt };
};
  
export const getJwt = async () => {
    const authSession = await Auth.currentSession()
    const accessToken = authSession.getIdToken();
    const jwt = accessToken.getJwtToken();
    return jwt;
};
  