/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompanyPropertiesById = /* GraphQL */ `
  query GetCompanyPropertiesById($Id: ID!) {
    getCompanyPropertiesById(Id: $Id) {
      Id
      MaxTopupAmount
      CreatedAt
    }
  }
`;
export const getShopPropertiesById = /* GraphQL */ `
  query GetShopPropertiesById($Id: ID!) {
    getShopPropertiesById(Id: $Id) {
      Id
      Name
      PhoneNo
      InquiryInfo
      TaxOffice
      CreatedAt
    }
  }
`;
export const getTransactionsById = /* GraphQL */ `
  query GetTransactionsById($TerminalId: ID!, $TimestampMs: Float!) {
    getTransactionsById(TerminalId: $TerminalId, TimestampMs: $TimestampMs) {
      TerminalId
      TimestampMs
      Id
      SerialNo
      TotalBalance {
        Balances {
          Kind
          Name
          Total
          Unit
        }
        Validates {
          ExpireDate
          Name
          Value
        }
      }
      Previous {
        Balances {
          Kind
          Name
          Total
          Unit
        }
        Validates {
          ExpireDate
          Name
          Value
        }
      }
      TerminalKind
      CompanyId
      ShopId
      Status
      Card {
        Kind
        Id
        PhysicalId
      }
      NoteInputHistories {
        TimestampMs
        NoteClass
      }
      NoteClass {
        Jpy1000
        Jpy2000
        Jpy5000
        Jpy10000
      }
      TotalAmount
      RefundCreated
      CreatedAt
    }
  }
`;
export const getRefundsById = /* GraphQL */ `
  query GetRefundsById($TerminalId: ID!, $TimestampMs: Float!) {
    getRefundsById(TerminalId: $TerminalId, TimestampMs: $TimestampMs) {
      TerminalId
      TimestampMs
      TransactionId
      SerialNo
      CompanyId
      ShopId
      Status
      HasCertificate
      CertificatePinNo
      CreatedAt
    }
  }
`;
export const getTerminalPropertiesById = /* GraphQL */ `
  query GetTerminalPropertiesById($Id: ID!, $Kind: String!) {
    getTerminalPropertiesById(Id: $Id, Kind: $Kind) {
      Id
      Kind
      ShopId
      CompanyId
      DisplayName
      SerialNo
      SoftwareVersion
      TerminalVersion
      TerminalBoardVersion
      TerminalFirmwareVersion
      Configs {
        Location
        InquiryInfo
        Volumes {
          Attention
          Information
          Branding
        }
        BrandingLogo {
          Top {
            Position
            Arn
          }
          Bottom {
            Position
            Arn
          }
          ReceiptImage
        }
        Background {
          Image {
            Arn
          }
          Color
        }
        Dashboard {
          Logo {
            Arn
          }
          Background {
            Image {
              Arn
            }
            Color
          }
          TopUpButton {
            BackgroundColor
            BorderColor
            ShadowColor
            FontColor
          }
          BalanceButton {
            BackgroundColor
            BorderColor
            ShadowColor
            FontColor
          }
        }
        Timeout {
          DefaultTimeout
        }
        WorkSchedule {
          All
          StartTime
          EndTime
          EndMode
          NotInService {
            Arn
          }
        }
        Point {
          Use
        }
        UpperLimitAmount
        Standby {
          Mode
          Signage {
            Slide {
              Arns
              Interval
            }
          }
        }
        CreatedAt
        HasSupportStaff
      }
      Shiagel {
        Registered
        Installed
        AuthCode
        ServiceNumber
        OperationalConfigs {
          Version
          Code
          Name
          BeginDate
          Card {
            Name
            UsePoint
            Use2nd
            UseMoney
            UseFamily
            FigCardNo
            FmtCard
            LogoUrl
            UseMifare
            UseFelica
            UseBarcode
            UseQR
            UseMykey
            MykeyCode
          }
          Point {
            Name
            Unit
            Tax
            RoundTax
            TaxType
          }
          Full
          SecondPoint {
            Name
            Unit
          }
          Money {
            Name
            Unit
            UpperLimitAmount
          }
          Security
          Term {
            Version
            ShopNo
            TermNo
            ShopName
            ShopNameReceipt
            TelNoReceipt
            TermName
            ServiceName
            ZipCode
            Address
            ContactName
            EMail
          }
          Exchange
        }
      }
      TroubleShootingUrl
      CreatedAt
    }
  }
`;
export const getTerminalMalfunctionHistoriesById = /* GraphQL */ `
  query GetTerminalMalfunctionHistoriesById(
    $TerminalId: ID!
    $TimestampMs: Float!
  ) {
    getTerminalMalfunctionHistoriesById(
      TerminalId: $TerminalId
      TimestampMs: $TimestampMs
    ) {
      TerminalId
      TimestampMs
      Id
      Kind
      KindLabel
      Malfunction {
        Id
        Message
        Payload
      }
      Status
      CreatedAt
      UpdatedAt
    }
  }
`;
export const getTerminalPlannedMaintenanceSchedulesById = /* GraphQL */ `
  query GetTerminalPlannedMaintenanceSchedulesById(
    $TerminalId: ID!
    $StartTimestampMs: Float!
  ) {
    getTerminalPlannedMaintenanceSchedulesById(
      TerminalId: $TerminalId
      StartTimestampMs: $StartTimestampMs
    ) {
      TerminalId
      StartTimestampMs
      EndTimestampMs
      ScheduleId
      SerialNo
      TerminalKind
      CompanyId
      ShopId
      UserId
      UserName
      CreatedAt
    }
  }
`;
export const getTerminalMaintenanceUsers = /* GraphQL */ `
  query GetTerminalMaintenanceUsers($TerminalId: ID!, $CardNo: String!) {
    getTerminalMaintenanceUsers(TerminalId: $TerminalId, CardNo: $CardNo) {
      TerminalId
      CardNo
      CompanyId
      ShopId
      UserId
      UpdatedAt
    }
  }
`;
export const getTerminalAdminUsers = /* GraphQL */ `
  query GetTerminalAdminUsers($TerminalId: ID!, $CardNo: String!) {
    getTerminalAdminUsers(TerminalId: $TerminalId, CardNo: $CardNo) {
      TerminalId
      CardNo
      CompanyId
      ShopId
      UserId
      UpdatedAt
    }
  }
`;
export const getTerminalNoteCollectHistoriesById = /* GraphQL */ `
  query GetTerminalNoteCollectHistoriesById(
    $TerminalId: ID!
    $TimestampMs: Float!
  ) {
    getTerminalNoteCollectHistoriesById(
      TerminalId: $TerminalId
      TimestampMs: $TimestampMs
    ) {
      TerminalId
      TimestampMs
      NoteClass {
        Jpy1000
        Jpy2000
        Jpy5000
        Jpy10000
      }
      TotalAmount
      DailyTotalAmount
      CreatedAt
    }
  }
`;
export const getTerminalLocalizationResourcesById = /* GraphQL */ `
  query GetTerminalLocalizationResourcesById($LocaleId: ID!) {
    getTerminalLocalizationResourcesById(LocaleId: $LocaleId) {
      LocaleId
      Strings {
        Id
        Value
      }
      Voices {
        Id
        Arn
      }
      CreatedAt
    }
  }
`;
export const getSystemPlannedMaintenanceSchedulesById = /* GraphQL */ `
  query GetSystemPlannedMaintenanceSchedulesById($ScheduleId: ID!) {
    getSystemPlannedMaintenanceSchedulesById(ScheduleId: $ScheduleId) {
      ScheduleId
      StartTimestampMs
      EndTimestampMs
      Message
      UserId
      UserName
      CreatedAt
    }
  }
`;
export const listCompanyProperties = /* GraphQL */ `
  query ListCompanyProperties(
    $filter: CompanyPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        MaxTopupAmount
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listShopProperties = /* GraphQL */ `
  query ListShopProperties(
    $filter: ShopPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        Name
        PhoneNo
        InquiryInfo
        TaxOffice
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: TransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        TerminalId
        TimestampMs
        Id
        SerialNo
        TotalBalance {
          Balances {
            Kind
            Name
            Total
            Unit
          }
          Validates {
            ExpireDate
            Name
            Value
          }
        }
        Previous {
          Balances {
            Kind
            Name
            Total
            Unit
          }
          Validates {
            ExpireDate
            Name
            Value
          }
        }
        TerminalKind
        CompanyId
        ShopId
        Status
        Card {
          Kind
          Id
          PhysicalId
        }
        NoteInputHistories {
          TimestampMs
          NoteClass
        }
        NoteClass {
          Jpy1000
          Jpy2000
          Jpy5000
          Jpy10000
        }
        TotalAmount
        RefundCreated
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listRefunds = /* GraphQL */ `
  query ListRefunds(
    $filter: RefundsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRefunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        TerminalId
        TimestampMs
        TransactionId
        SerialNo
        CompanyId
        ShopId
        Status
        HasCertificate
        CertificatePinNo
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalProperties = /* GraphQL */ `
  query ListTerminalProperties(
    $filter: TerminalPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        Kind
        ShopId
        CompanyId
        DisplayName
        SerialNo
        SoftwareVersion
        TerminalVersion
        TerminalBoardVersion
        TerminalFirmwareVersion
        Configs {
          Location
          InquiryInfo
          Volumes {
            Attention
            Information
            Branding
          }
          BrandingLogo {
            Top {
              Position
              Arn
            }
            Bottom {
              Position
              Arn
            }
            ReceiptImage
          }
          Background {
            Image {
              Arn
            }
            Color
          }
          Dashboard {
            Logo {
              Arn
            }
            Background {
              Image {
                Arn
              }
              Color
            }
            TopUpButton {
              BackgroundColor
              BorderColor
              ShadowColor
              FontColor
            }
            BalanceButton {
              BackgroundColor
              BorderColor
              ShadowColor
              FontColor
            }
          }
          Timeout {
            DefaultTimeout
          }
          WorkSchedule {
            All
            StartTime
            EndTime
            EndMode
            NotInService {
              Arn
            }
          }
          Point {
            Use
          }
          UpperLimitAmount
          Standby {
            Mode
            Signage {
              Slide {
                Arns
                Interval
              }
            }
          }
          CreatedAt
          HasSupportStaff
        }
        Shiagel {
          Registered
          Installed
          AuthCode
          ServiceNumber
          OperationalConfigs {
            Version
            Code
            Name
            BeginDate
            Card {
              Name
              UsePoint
              Use2nd
              UseMoney
              UseFamily
              FigCardNo
              FmtCard
              LogoUrl
              UseMifare
              UseFelica
              UseBarcode
              UseQR
              UseMykey
              MykeyCode
            }
            Point {
              Name
              Unit
              Tax
              RoundTax
              TaxType
            }
            Full
            SecondPoint {
              Name
              Unit
            }
            Money {
              Name
              Unit
              UpperLimitAmount
            }
            Security
            Term {
              Version
              ShopNo
              TermNo
              ShopName
              ShopNameReceipt
              TelNoReceipt
              TermName
              ServiceName
              ZipCode
              Address
              ContactName
              EMail
            }
            Exchange
          }
        }
        TroubleShootingUrl
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalMaintenanceLogs = /* GraphQL */ `
  query ListTerminalMaintenanceLogs(
    $filter: TerminalMaintenanceLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalMaintenanceLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TerminalId
        TimestampMs
        Maintenance {
          IoBoard {
            Port {
              Door
              Button
              PIR
              IoBoardAwaked
            }
            DIP
            Version
          }
        }
        Result {
          IoBoard
          Printer
        }
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalMalfunctionHistories = /* GraphQL */ `
  query ListTerminalMalfunctionHistories(
    $filter: TerminalMalfunctionHistoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalMalfunctionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TerminalId
        TimestampMs
        Id
        Kind
        KindLabel
        Malfunction {
          Id
          Message
          Payload
        }
        Status
        CreatedAt
        UpdatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalMalfunctionHistoriesBySorting = /* GraphQL */ `
  query ListTerminalMalfunctionHistoriesBySorting(
    $filter: TerminalMalfunctionHistoriesFilterInput
    $Status: String
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listTerminalMalfunctionHistoriesBySorting(
      filter: $filter
      Status: $Status
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TerminalId
        TimestampMs
        Id
        Kind
        KindLabel
        Malfunction {
          Id
          Message
          Payload
        }
        Status
        CreatedAt
        UpdatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalPlannedMaintenanceSchedules = /* GraphQL */ `
  query ListTerminalPlannedMaintenanceSchedules(
    $filter: TerminalPlannedMaintenanceSchedulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalPlannedMaintenanceSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TerminalId
        StartTimestampMs
        EndTimestampMs
        ScheduleId
        SerialNo
        TerminalKind
        CompanyId
        ShopId
        UserId
        UserName
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalMaintenanceUsers = /* GraphQL */ `
  query ListTerminalMaintenanceUsers(
    $filter: TerminalMaintenanceUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalMaintenanceUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TerminalId
        CardNo
        CompanyId
        ShopId
        UserId
        UpdatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalAdminUsers = /* GraphQL */ `
  query ListTerminalAdminUsers(
    $filter: TerminalAdminUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalAdminUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TerminalId
        CardNo
        CompanyId
        ShopId
        UserId
        UpdatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalNoteCollectHistories = /* GraphQL */ `
  query ListTerminalNoteCollectHistories(
    $filter: TerminalNoteCollectHistoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalNoteCollectHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TerminalId
        TimestampMs
        NoteClass {
          Jpy1000
          Jpy2000
          Jpy5000
          Jpy10000
        }
        TotalAmount
        DailyTotalAmount
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalErrorLogs = /* GraphQL */ `
  query ListTerminalErrorLogs(
    $filter: TerminalErrorLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalErrorLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        TerminalId
        ErrorCode
        ErrorMessage
        ErrorTimeStamp
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalLocalizationResources = /* GraphQL */ `
  query ListTerminalLocalizationResources(
    $filter: TerminalLocalizationResourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalLocalizationResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        LocaleId
        Strings {
          Id
          Value
        }
        Voices {
          Id
          Arn
        }
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listTerminalOperationHistories = /* GraphQL */ `
  query ListTerminalOperationHistories(
    $filter: TerminalOperationHistoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalOperationHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TerminalId
        TimestampMs
        ViewName
        ViewId
        OperationName
        OperationId
        Detail
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listSystemMalfunctionHistories = /* GraphQL */ `
  query ListSystemMalfunctionHistories(
    $filter: SystemMalfunctionHistoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemMalfunctionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        TimestampMs
        Kind
        Malfunction {
          Id
          Message
          Payload
        }
        Status
        CreatedAt
        UpdatedAt
      }
      nextToken
    }
  }
`;
export const listSystemPlannedMaintenanceSchedules = /* GraphQL */ `
  query ListSystemPlannedMaintenanceSchedules(
    $filter: SystemPlannedMaintenanceSchedulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemPlannedMaintenanceSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ScheduleId
        StartTimestampMs
        EndTimestampMs
        Message
        UserId
        UserName
        CreatedAt
      }
      nextToken
    }
  }
`;
export const listSystemOperationHistories = /* GraphQL */ `
  query ListSystemOperationHistories(
    $filter: SystemOperationHistoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemOperationHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        UserId
        UserMail
        TimestampMs
        ViewName
        ViewId
        OperationName
        OperationId
        Detail
        CreatedAt
      }
      nextToken
    }
  }
`;
