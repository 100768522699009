














import { Component, Vue } from 'vue-property-decorator';
import Navbar from '@/components/layouts/Navbar.vue';
import Appbar from '@/components/layouts/Appbar.vue';
import { namespace } from "vuex-class";

import * as masters from '@/store/modules/masters';

const Auth = namespace('auth');
const Masters = namespace(masters.name);

@Component({
  components: {
    Navbar,
    Appbar,
  },
})
export default class App extends Vue {
  @Auth.Action('authAction')
  authAction!: () => void;

  @Masters.Getter('isLoading')
  isMasterLoading!: boolean;

  @Masters.Getter('loadingStatus')
  loadingStatus!: string;

  loadingMessage = 'データを読み込んでいます'

  get shopNavbar() {
    return (
      this.$route.name != 'Login' &&
      this.$route.name != 'Login-ForgotPassword' &&
      this.$route.name != 'Login-PasswordReset'
    )
  }

  created() {
    this.authAction(); // FIXME
  }
}
