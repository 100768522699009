import GraphQL from '@/interfaces/graphql';
import { listTerminalOperationHistories } from '@/graphql/queries';
import { 
  ListTerminalOperationHistoriesQuery,
  ListTerminalOperationHistoriesQueryVariables,
  TerminalOperationHistoriesFilterInput
} from '@/API'
import { TerminalOperationHistory } from '@/models/topup/terminalOperationHistory.model';
import QueryResult from './queryResult';

// const limit = 1000;
// type NullableTerminalOperationHistories = NonNullable<NonNullable<ListTerminalOperationHistoriesQuery["listTerminalOperationHistories"]>["items"]>
// type ListTerminalOperationHistories = NonNullable<NullableTerminalOperationHistories[number]>[]

// export async function* getAllTerminalOperationHistories(
//   filter: TerminalOperationHistoriesFilterInput | null,
//   nextToken: string | null = null
// ): AsyncGenerator<ListTerminalOperationHistories> {

//   let flg = true;
//   while (flg) { 
//     const queryVariable = {
//       filter, limit, nextToken,
//     } as ListTerminalOperationHistoriesQueryVariables;
//     const queryResult: {data: ListTerminalOperationHistoriesQuery} = await GraphQL.query(
//       listTerminalOperationHistories,
//       queryVariable,
//       0
//     ) as {data: ListTerminalOperationHistoriesQuery}
//     const items = queryResult.data.listTerminalOperationHistories?.items
//     const queryNextToken = queryResult.data.listTerminalOperationHistories?.nextToken
//     if (!items || items.length == 0) { 
//       break; // エラー or 空返却
//     }

//     // エラークエリの場合はnullが混じるのでフィルタリングする。
//     const results = (items.filter((v) => v != null) as ListTerminalOperationHistories);
//     yield results; // 上位に結果を返却

//     // まだあるか？
//     if (queryNextToken && queryNextToken.length > 0) {
//       nextToken = queryNextToken;
//     } else { 
//       flg = false;
//     }
//   }
// }

export const getTerminalOperationHistories = async (
  filter: TerminalOperationHistoriesFilterInput | null,
  limit: number,
  nextToken: string | null = null
): Promise<QueryResult<TerminalOperationHistory>> => {
  let queryResult: {data: ListTerminalOperationHistoriesQuery}
  const queryVariable = {
    filter, limit, nextToken,
  } as ListTerminalOperationHistoriesQueryVariables
  try {
    queryResult = await GraphQL.query(
      listTerminalOperationHistories,
      queryVariable,
      0
    ) as {data: ListTerminalOperationHistoriesQuery}
  }catch (err) {
    // 本来はデータの不備はエラーにする。
    // 表示のみの影響なので、一旦処理を継続。
    queryResult = err as {data: ListTerminalOperationHistoriesQuery}
  }
  // console.log(filter, limit, nextToken, queryResult);
  const items = queryResult.data.listTerminalOperationHistories?.items;
  const newNextToken = queryResult.data.listTerminalOperationHistories?.nextToken;
  if (!items) {
    return {
      result: [],
      nextToken: null,
    };
  }

  // エラークエリの場合はnullが混じるのでフィルタリングする。
  const result = items.filter((v) => v != null) as TerminalOperationHistory[];

  // console.log(filter, limit, nextToken, result);
  return {
    result,
    nextToken: newNextToken,
  };
}