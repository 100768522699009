






















import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class DeviceMaintenanceHistory extends Vue {
  data = [
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
    { target: 'target', log: 'log', day: '2020年 9月10日 00:00:00' },
  ];
}
