



































































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Maintenance extends Vue {
  search = '';
  maintenanceCardEdit = false;
  maintenanceCardDelete = false;
  maintenanceCardDetail = false;
  headers = [
    { text: 'ID', value: 'id' },
    { text: '所属', value: 'belongs' },
    { text: '最終ログイン', value: 'lastLogin' },
    { text: '', value: 'actions', align: 'right', sortable: false },
  ];
  data: Array<any> = [];
  editedIndex = -1;
  editedItem: Record<string, any> = {
    id: '',
    belongs: '',
    roll: '',
  };
  defaultItem: Record<string, any> = {
    id: '',
    belongs: '',
    roll: '',
  };

  formTitle =
    this.editedIndex === -1
      ? '新規メンテナンスカード'
      : 'メンテナンスカード編集';

  created() {
    this.initialize();
  }

  @Watch('maintenanceCardEdit', { deep: true })
  async onMaintenanceCardEdit(val: any) {
    val || this.close();
  }
  @Watch('maintenanceCardDelete', { deep: true })
  async onMaintenanceCardDelete(val: any) {
    val || this.closeDelete();
  }

  get formtitle() {
    return this.formTitle;
  }
  set formtitle(newFormTitle) {
    this.formTitle = newFormTitle;
  }

  initialize() {
    this.data = [
      {
        id: '0000000000000001',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000002',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000003',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000004',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000005',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000006',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000007',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000008',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000009',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000010',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000011',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000012',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000013',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000014',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
      {
        id: '0000000000000015',
        belongs: 'ABC株式会社',
        lastLogin: '2020年 9月10日',
      },
    ];
  }

  editItem(item: any) {
    this.editedIndex = this.data.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.maintenanceCardEdit = true;
  }

  deleteItem(item: any) {
    this.editedIndex = this.data.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.maintenanceCardDelete = true;
  }

  deleteItemConfirm() {
    this.data.splice(this.editedIndex, 1);
    this.closeDelete();
  }

  close() {
    this.maintenanceCardEdit = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  closeDelete() {
    this.maintenanceCardDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  save() {
    if (this.editedIndex > -1) {
      Object.assign(this.data[this.editedIndex], this.editedItem);
    } else {
      this.data.push(this.editedItem);
    }
    this.close();
  }

  openDetail(item: any) {
    this.editedIndex = this.data.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.maintenanceCardDetail = true;
  }
}
