import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { systemMalfunctionHistories as systemMalfunctions } from './SystemMalfunctionHistories/systemMalFunctionHistories';
import { terminalErrorLogs } from './TerminalErrorLogs/terminalErrorLogs';
import { systemOperationHistories } from './SystemOperationHistories/systemOperationHistories';
import { systemPlannedMaintenanceSchedules } from './modules/systemPlannedMaintenanceSchedules';
import { terminalPlannedMaintSchedules } from './TerminalPlannedMaintSchedules/terminalPlannedMaintSchedules';
import { group } from './group';
import { terminals } from './modules/terminals';
// ------
import * as root from './root';
import { State } from './root/type';
import * as auth from './modules/common/auth';
import * as masters from './modules/masters';
import * as terminalMalfunctionHistories from './modules/journals/terminalMalfunctionHistories';
import * as terminalOperationHistories from './modules/journals/terminalOperationHistories';
import * as transactions from './modules/journals/transactions';

export type RootState = State;

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: root.state(),
  modules: {
    systemMalfunctions,
    systemPlannedMaintenanceSchedules,
    terminalErrorLogs,
    systemOperationHistories,
    terminalPlannedMaintSchedules,
    group,
    terminals,
    // ------
    [auth.name]: auth,
    [masters.name]: masters,
    [terminalMalfunctionHistories.name]: terminalMalfunctionHistories,
    [terminalOperationHistories.name]: terminalOperationHistories,
    [transactions.name]: transactions,
  },
  mutations: root.mutations,
  getters: root.getters,
  actions: root.actions,
};

export default new Vuex.Store<RootState>(store);
