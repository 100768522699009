













































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { TerminalEntity } from "../../models/internal/terminalEntity.model";

@Component
export default class TerminalDetail extends Vue {
  @Prop({ type: Object, required: true })
  terminal?: TerminalEntity;

  // @Watch("terminal", { immediate: false, deep: true })
  // onTerminalChange() {
  //   if (this.terminal?.property?.Shiagel?.AuthCode) {
  //     this.authCode = this.terminal.property.Shiagel.AuthCode;
  //   } else {
  //     this.authCode = "";
  //   }
  //   this.error.status = false;
  //   this.error.message = "";
  // }

  /** 端末の情報から所属グループ(Secondary)のIDを取得する */
  get secondaryGroup(): string | null {
    if (!this.terminal || !this.terminal.property) {
      console.error('invalid operation. terminal is null');
      return null;
    }
    if (
      this.terminal.base.AffiliatedGroups != null
      && this.terminal.base.AffiliatedGroups.Secondary != null
      && this.terminal.base.AffiliatedGroups.Secondary.length > 0
    ) {
      return this.terminal.base.AffiliatedGroups.Secondary;
    }
    return '';
  }

  get hasSecondaryGroup(): boolean {
    return this.secondaryGroup != null && this.secondaryGroup.length > 0;
  }

  mounted() {
    // if (this.terminal?.property?.Shiagel?.AuthCode) {
    //   this.authCode = this.terminal.property.Shiagel.AuthCode;
    // } else {
    //   this.authCode = "";
    // }
    // this.error.status = false;
    // this.error.message = "";
  }

  /** 企業別 / 店舗一覧(単一表示) */
  moveToStoresView() {
    if (!this.terminal || !this.terminal.property) {
      console.error('invalid operation. terminal is null');
      return;
    }
    const companyId = this.terminal.base.CompanyId;
    const shopId = this.terminal.base.ShopId;
    
    const target = `/companies/${companyId}/stores/${shopId}`;
    this.$router.push({ path: target });
  }

  /** 端末 取引履歴 */
  movetToTransactionsView() {
    if (!this.terminal || !this.terminal.property) {
      console.error('invalid operation. terminal is null');
      return;
    }
    const companyId = this.terminal.base.CompanyId;
    const shopId = this.terminal.base.ShopId;
    const terminalId = this.terminal.base.Id;
    
    const target = `/companies/${companyId}/stores/${shopId}/terminals/${terminalId}/transactions`;
    this.$router.push({ path: target });
  }

  /** 端末 障害履歴 */
  movetToMalfunctionsView() {
    if (!this.terminal || !this.terminal.property) {
      console.error('invalid operation. terminal is null');
      return;
    }
    const companyId = this.terminal.base.CompanyId;
    const shopId = this.terminal.base.ShopId;
    const terminalId = this.terminal.base.Id;
    
    const target = `/companies/${companyId}/stores/${shopId}/terminals/${terminalId}/malfunctions`;
    this.$router.push({ path: target });
  }

  moveToTerminalGroupView() {
    if (!this.terminal || !this.terminal.property) {
      console.error('invalid operation. terminal is null');
      return;
    }
    // const companyId = this.terminal.base.CompanyId;
    // const shopId = this.terminal.base.ShopId;
    const groupId = this.secondaryGroup;

    const target = `/group/terminals/${groupId}`;
    this.$router.push({ path: target });
  }

}
