








































































import Logo from "../Logo.vue";
import { namespace, Getter, Action } from "vuex-class";
import { Component, Vue, Watch } from "vue-property-decorator";
import { AuthUser } from "@/models/internal/user.model";
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import * as auth from '@/store/modules/common/auth';
import * as masters from '@/store/modules/masters';

const Auth = namespace(auth.name);
const Masters = namespace(masters.name);

@Component({
  components: {
    Logo,
  },
})
export default class Navbar extends Vue {
  @Getter('isRestrictionMode')
  isRestrictionMode!: boolean;
  @Getter('isDrawerOpend')
  isDrawerOpend!: boolean;
  @Getter('isMobile')
  isMobile!: boolean;
  @Action('setIsDrawerOpend')
  setIsDrawerOpend!: (value: boolean) => void;
  @Action('checkMobile')
  checkMobile!: () => void;
  @Auth.Getter('user')
  user!: AuthUser | null;
  @Auth.Getter('isSystemAdmin')
  isSystemAdmin!: boolean;
  @Auth.Action('logout')
  logout!: () => void;
  @Masters.Getter('myCompany')
  myCompany!: CompanyEntity | null;

  items = [
    {
      icon: "mdi-chart-pie",
      title: "ダッシュボード",
      to: "/",
    },
    {
      icon: "mdi-account",
      title: "アカウント",
      to: "/account",
    },
    {
      icon: "mdi-office-building-outline",
      title: "企業",
      to: "/company",
    },
    {
      icon: "mdi-store",
      title: "店舗",
      to: "/store",
    },
    {
      icon: "mdi-account-group",
      title: "グループ",
      active: false,
      childPages: [
        {
          title: "ユーザー",
          to: "/group/users",
        },
        {
          title: "ターミナル",
          to: "/group/terminals",
        },
      ],
    },
    {
      icon: "mdi-cellphone-cog",
      title: "チャージ機",
      childPages: [
        {
          title: "一覧",
          to: "/device",
        },
        // {
        //   title: '障害管理',
        //   to: '/device/trouble',
        // },
      ],
    },
    {
      icon: "mdi-history",
      title: "取引履歴",
      to: "/logs/payment",
    },
    {
      icon: "mdi-cog-outline",
      title: "管理",
      active: false,
      childPages: [
        {
          title: "システム操作履歴",
          to: "/logs/operation",
        },
        // {
        //   title: '端末ログ',
        //   to: '/logs/device',
        // },
        {
          title: "端末ログ",
          to: "/logs/device-operation",
        },
        {
          title: "障害管理",
          // to: '/management/trouble',
          to: "/device/trouble",
        },
        {
          title: "使用制限",
          to: "/management/functionRestriction",
        },
      ],
    },
  ];

  get companyName() {
    if (!this.myCompany) return "Loading...";
    return this.myCompany.base.Name;
  }

  get userInitial() {
    if (!this.user) return "  ";
    return "UN"; // FIXME: [fkmy] ユーザ名があるならイニシャルを生成
  }

  get userEmail() {
    if (!this.user) return "...";
    return this.user.email;
  }

  get isPcNavBarEnabled() {
    if (this.isRestrictionMode && !this.isSystemAdmin) return false;
    if (this.isMobile) return false;
    return true;
  }

  get isMobileNavBarEnabled() {
    if (this.isRestrictionMode && !this.isSystemAdmin) return false;
    if (!this.isMobile) return false;
    return true;
  }

  get drawer() {
    return this.isDrawerOpend;
  }
  set drawer(value: boolean) {
    this.setIsDrawerOpend(value);
  }

  mounted() {
    this.checkMobile();
    window.addEventListener("resize", () => {
      this.checkMobile();
    });
    if (this.isMobile) {
      this.setIsDrawerOpend(false);
    }
  }


  // @Watch("user", { immediate: true, deep: true })
  // async onUserChange() {
  // checkRestrictionMode()
  // }

  async logoutUser() {
    try {
      await this.logout();
      this.$router.push("/login");
    } catch (error) {
      console.error("logout error", error);
    }
  }
}
