

























import { Component } from "vue-property-decorator";
import { namespace } from 'vuex-class'
import { FetchCondition} from '@/store/modules/journals/type';
import * as terminalMalfunctionHistories from '@/store/modules/journals/terminalMalfunctionHistories';
import JournalDataList from '@/components/abstracts/JournalDataList';
import TerminalMalfunctionDetail from '@/components/terminalMalfunctions/TerminalMalfunctionDetail.vue';
import { TerminalMalfunctionHistory } from "@/models/topup/terminalMalfunctionHistory.model";

const TerminalMalfunctionHistories = namespace(terminalMalfunctionHistories.name);

interface Status {
  open: string;
  closed: string;
  inProgress: string;
}

const statusItems: Status = {
    open: "故障",
    inProgress: "故障", //対応ワードが分からないため、一旦故障。
    closed: "解決済み",
};

const LIMIT = 100;

@Component({
  components: {
    TerminalMalfunctionDetail,
  },
})
export default class TerminalMalfunctionWidget extends JournalDataList<TerminalMalfunctionHistory> {

    @TerminalMalfunctionHistories.Getter('items')
    journalItems?: (condition?: FetchCondition) => TerminalMalfunctionHistory[]; 
    @TerminalMalfunctionHistories.Getter('hasNext')
    hasJournalDataNextToken?: (condition?: FetchCondition) => boolean; 
    @TerminalMalfunctionHistories.Getter('isLoading')
    isJournalDataLoading?: boolean; 
    @TerminalMalfunctionHistories.Action('fetch')
    fetchJournalData?: (condition?: FetchCondition) => Promise<void>; 

    // -------------------------

    // headers = [
    //     { text: "障害状況", value: "Status" },
    //     { text: "発生日時", value: "TimestampMs" },
    //     { text: "種別", value: "KindLabel" },
    //     { text: "端末ID", value: "TerminalId" },
    //     { text: "作成時間", value: "CreatedAt" },
    //     // { text: "更新時間", value: "UpdatedAt" },
    // ];

    // limit = {
    //     first: LIMIT,
    //     next: LIMIT,
    // }

    // -------------------------

    protected get isLoading(): boolean {
        // if (super.isLoading) return true; // master data
        if (this.isMasterLoading) return true; // master data
        if (this.isJournalDataLoading) return true;
        return false;
    }

    protected get items(): TerminalMalfunctionHistory[] {
        if (!this.journalItems) return [];
        const condition = this.createCondition(); // NOTE: ここの limit は使わない
        // console.log('items', condition, this.journalItems(condition));
        return this.journalItems(condition).sort(function (x, y) { // 日付の降順でソート
            return y.TimestampMs - x.TimestampMs;
        }).slice(0, LIMIT);
    }
  
    protected get hasUnloadedItems(): boolean {
        if (!this.hasJournalDataNextToken) return false;
        const condition = this.createCondition(); // NOTE: ここの limit は使わない
        return this.hasJournalDataNextToken(condition);
    }

    get widgetTitle(): string {
        if (this.isJournalDataLoading) {
            return '障害情報データを読み込んでいます...';
        } else {
            return '障害情報';
        }
    }

    // -------------------------
    
    protected createCondition(limit = -1): FetchCondition | undefined { 
        return { limit: LIMIT } as FetchCondition;
    }

    /** アイテム読込処理 */
    protected async loadItems(limit: number) { 
        if (!this.fetchJournalData) return;
        const condition = this.createCondition(limit);
        await this.fetchJournalData(condition);
    }

    async reload() {
        console.log('reload');
        await this.loadItems(LIMIT);
    }

    // TODO: 読み込んだ件数が LIMIT に満たない場合の処理

    // -------------------------

    getStatusLabel(value: keyof Status): string {
        if (Object.keys(statusItems).includes(value)) {
            return statusItems[value];
        }
        return "unknown"; //もし知らないステータスが出てきたら、未知にする。
    }

    // -------------------------

    moveToListView(item: TerminalMalfunctionHistory) {
        const terminalId = item.TerminalId;
        const itemId = item.Id;
        const target = `/terminals/${terminalId}/malfunctions/${itemId}`;
        this.$router.push({ path: target });
    }
}
