import GraphQL from '@/interfaces/graphql';
import { listSystemMalfunctionHistories } from '@/graphql/queries';
import { 
  ListSystemMalfunctionHistoriesQuery,
  ListSystemMalfunctionHistoriesQueryVariables,
  SystemMalfunctionHistoriesFilterInput
} from '@/API'

const limit = 1000;
type NullableSystemMalfunctionHistories = NonNullable<NonNullable<ListSystemMalfunctionHistoriesQuery["listSystemMalfunctionHistories"]>["items"]>
type ListSystemMalfunctionHistories = NonNullable<NullableSystemMalfunctionHistories[number]>[]

export const getAllSystemMalfunctionHistories = async (filter: SystemMalfunctionHistoriesFilterInput | null, nextToken: string| null = null): Promise<ListSystemMalfunctionHistories> => {
  const queryVariable = {
    filter, limit, nextToken,
  } as ListSystemMalfunctionHistoriesQueryVariables
  const queryResult: {data: ListSystemMalfunctionHistoriesQuery} = await GraphQL.query(
    listSystemMalfunctionHistories,
    queryVariable,
    0
  ) as {data: ListSystemMalfunctionHistoriesQuery}
  const items = queryResult.data.listSystemMalfunctionHistories?.items
  const queryNextToken = queryResult.data.listSystemMalfunctionHistories?.nextToken
  if (!items) return []
  // エラークエリの場合はnullが混じるのでフィルタリングする。
  let result = items.filter((v) => v != null) as ListSystemMalfunctionHistories;
  if (queryNextToken){
    const nextResult = await getAllSystemMalfunctionHistories(filter, queryNextToken);
    result = result.concat(nextResult)
  }
  // console.log('[SystemMalfunctionHistories]', result);
  return result;
}
