































import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import MasterDataList from '@/components/abstracts/MasterDataList';
import AccountDetail from '@/components/accounts/AccountDetail.vue';
// import { AuthUser } from "@/models/internal/user.model";
import { User } from '@/models/commadmin/users.model';
import { UserGroupEntity } from "@/models/internal/groupEntity.model";
import { getRoleLabel } from '@/models/view/role';


@Component({
    components: {
        AccountDetail,
    }
})
export default class AccountList extends MasterDataList<User> {
  @Prop({ type: String, required: false, default: null })
  company?: string;
  @Prop({ type: String, required: false, default: null })
  store?: string;
  @Prop({ type: String, required: false, default: null })
  search?: string;

  // -------------------------
  
  // @Getter("auth/user") user!: AuthUser;
  // @Getter("group/userGroups") userGroups!: UserGroupEntity[];


  // -------------------------

  headers = [
    {
      text: "ID",
      value: "Email",
    },
    {
      text: "所属",
      value: "Affiliation",
    },
    {
      text: "ロール",
      value: "CognitoGroup",
    },
    {
      text: "最終ログイン",
      value: "LastLoginDate",
    },
    {
      text: "メンテナンスカード",
      value: "MaintenanceCardId",
    },
    {
      text: "",
      value: "actions",
      align: "right",
      sortable: false,
    },
  ];

  // -------------------------

  protected get items() {
    return this.users.filter(x => {
      if (this.company && this.company.length > 0) {
        if (x.CompanyId != this.company) return false;
      }
      if (this.store && this.store.length > 0) {
        if (x.ShopId != this.store) return false;
      }
      return true;
    })
  }

  // -------------------------

  getRoleLabel(roleValue: string) {
    return getRoleLabel(roleValue);
  }

}
