import { render, staticRenderFns } from "./DeviceMaintenance.vue?vue&type=template&id=45c08812&scoped=true&lang=pug&"
import script from "./DeviceMaintenance.vue?vue&type=script&lang=ts&"
export * from "./DeviceMaintenance.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c08812",
  null
  
)

export default component.exports