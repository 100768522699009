import { RootState } from '@/store';
import { 
    FetchCondition,
    State,
    Actions,
    Getters,
    Mutations,
} from './type';
import * as masters from '@/store/modules/masters';
import { FetchedConditonHistoryUtil } from './utils';
import { Transaction } from '@/models/topup/transaction.model';
import { TransactionsFilterInput } from '@/API';
import { getTransactions } from '@/interfaces/graphql/transactions';

const conditionUtil = new FetchedConditonHistoryUtil<Transaction, TransactionState>();

// -------------------------

export const namespaced = true;

export const name = 'transactions';

export type TransactionState = State<Transaction>;

// -------------------------

export const state = (): TransactionState => ({
  items: [],
  conditions: [],
  isLoading: false,
});

export const getters: Getters<TransactionState, RootState, Transaction> = {
    items: (state) => (condition?: FetchCondition) => { 
        if (!condition) { // 指定無し。全件
            return state.items;
        }
        return state.items.filter((item) => { 
            // 端末ID指定あり
            if (condition.terminalId && condition.terminalId.length > 0) { 
                if (item.TerminalId != condition.terminalId) return false;
            }
            // 店舗指定あり
            if (condition.shopId && condition.shopId.length > 0) { 
                if (item.ShopId != condition.shopId) return false;
            }
            return true;
        });
    },
    isLoading: (state) => { 
        return state.isLoading;
    },
    hasNext: (state) => (condition?: FetchCondition) => { 
        return conditionUtil.checkHasNextToken(state, condition);
    },
}

export const actions: Actions<TransactionState, RootState> = {
    async fetch({ state, commit, rootGetters }, condition?) {
        if (state.isLoading) { 
            console.warn('loading operation is conflicted');
        }
        commit('setIsLoading', true);

        let limit = 1000;
        const terminalIds: string[] = [];
        const shopIds: string[] = [];
        if (condition) {
            if (condition.terminalId && condition.terminalId.length > 0) {
                terminalIds.push(condition.terminalId)
            }
            if (condition.shopId && condition.shopId.length > 0) { 
                shopIds.push(condition.shopId);
            }
            limit = condition.limit;
        }
        if (terminalIds.length == 0 && shopIds.length == 0) { 
            // 全ての端末を対象にする
            const ids = rootGetters[`${masters.name}/terminalIds`];
            terminalIds.push(...ids);
        }
        if (shopIds.length == 0) {
            for (const terminalId of terminalIds) {
                // 前回実行時の nextToken を準備する
                const previousNextToken = conditionUtil.getNextToken(state, { terminalId, limit });
                // query
                const filter: TransactionsFilterInput = {
                    TerminalId: {
                        eq: terminalId,
                    }
                };
                try {
                    const { result, nextToken } = await getTransactions(filter, limit, previousNextToken);
                    // const sortedObj = _.orderBy(res, (a) => new Date(a.TimestampMs), ['desc']);
                    if (result.length > 0) {
                        commit('addItems', result);
                    }
                    // 読込履歴を記録
                    commit('addFetchedConditon', conditionUtil.create(
                        {
                            terminalId,
                            limit,
                        },
                        result.length,
                        nextToken,
                    ));
    
                } catch (error) {
                    console.error('Get Terminal Malfunction Histories error', error);
                }
            }
        } else { 
            for (const shopId of shopIds) {
                // 前回実行時の nextToken を準備する
                const previousNextToken = conditionUtil.getNextToken(state, { shopId, limit });
                // query
                const filter: TransactionsFilterInput = {
                    ShopId: {
                        eq: shopId,
                    }
                };
                try {
                    const { result, nextToken } = await getTransactions(filter, limit, previousNextToken);
                    // const sortedObj = _.orderBy(res, (a) => new Date(a.TimestampMs), ['desc']);
                    if (result.length > 0) {
                        commit('addItems', result);
                    }
                    // 読込履歴を記録
                    commit('addFetchedConditon', conditionUtil.create(
                        {
                            shopId,
                            limit,
                        },
                        result.length,
                        nextToken,
                    ));
    
                } catch (error) {
                    console.error('Get Terminal Malfunction Histories error', error);
                }
            }
        }

        commit('setIsLoading', false);
    },
    async clearItems({ commit }) { 
        commit('setItems', []);
        commit('setFetchedConditions', []);
    }
}

export const mutations: Mutations<TransactionState, Transaction> = {
    setItems(state, items) { 
        state.items = items;
    },
    addItems(state, items) {
        console.log('addItems', state.items.length, items.length);
        for (const item of items) { 
            // 重複チェック
            const exits = state.items.filter(x => x.Id == item.Id);
            if (exits.length > 0) {
                continue;
            }
            state.items.push(item);
        }
    },
    setFetchedConditions(state, conditions) { 
        state.conditions = conditions;
    },
    addFetchedConditon(state, value) {
        state.conditions.push(value);
    },
    setIsLoading(state, value) {
        state.isLoading = value;
    },
}


