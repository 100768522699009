var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"loading-text":_vm.messages.loading,"items":_vm.items,"item-key":"itemKey","sort-by":"id","search":_vm.search},on:{"click:row":_vm.openDetail},scopedSlots:_vm._u([{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.base.CompanyId || item.property.CompanyId},[_vm._v(_vm._s(_vm.getCompanyName(item.base.CompanyId || item.property.CompanyId)))])]}},{key:"item.shopName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.base.ShopId || item.property.ShopId},[_vm._v(_vm._s(_vm.getShopName(item.base.ShopId || item.property.ShopId)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('v-col',{staticClass:"vchip_col"},[(_vm.getItemStatus(item) == 'not-installed')?[_c('v-chip',{staticClass:"vchip_style",attrs:{"color":"red","label":"","outlined":"","x-small":_vm.isMobile,"small":!_vm.isMobile}},[_vm._v("未設置")])]:(_vm.getItemStatus(item) == 'running')?[_c('v-chip',{staticClass:"vchip_style",attrs:{"color":"green","label":"","outlined":"","x-small":_vm.isMobile,"small":!_vm.isMobile}},[_vm._v("稼働中")])]:[_c('v-chip',{staticClass:"vchip_style",attrs:{"color":"gray","label":"","outlined":"","x-small":_vm.isMobile,"small":!_vm.isMobile}},[_vm._v("非稼働")])]],2),_c('v-col',[(_vm.getItemStatus(item) == 'not-installed')?[_c('v-spacer')]:[(!item.disability)?[_c('v-icon',{attrs:{"color":"green","dark":""}},[_vm._v("mdi-check-circle-outline")])]:[_c('v-icon',{attrs:{"color":"red","dark":""}},[_vm._v("mdi-alert-circle-outline")])]]],2)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","x-small":_vm.isMobile,"small":!_vm.isMobile},on:{"click":function($event){$event.stopPropagation();return _vm.openInstall(item)}}},[_vm._v("設置")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openEdit(item)}}},[_vm._v("mdi-cog")])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("データなし")])]},proxy:true},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.isEditOpened),callback:function ($$v) {_vm.isEditOpened=$$v},expression:"isEditOpened"}},[_c('terminal-edit',{attrs:{"terminal":_vm.selectedItem},on:{"terminal-edit-close-event":_vm.onEditClose}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.isDetailOpened),callback:function ($$v) {_vm.isDetailOpened=$$v},expression:"isDetailOpened"}},[_c('terminal-detail',{attrs:{"terminal":_vm.selectedItem}})],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.installDialog),callback:function ($$v) {_vm.installDialog=$$v},expression:"installDialog"}},[_c('setup-terminal',{attrs:{"terminal":_vm.selectedItem},on:{"done":function () { _vm.installDialog = false; _vm.loadTerminals(); },"cancel":function($event){_vm.installDialog = false}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }