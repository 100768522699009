export const listTerminalPlannedMaintenanceSchedules = /* GraphQL */ `
  query listTerminalPlannedMaintenanceSchedules(
    $filter: TerminalPlannedMaintenanceSchedulesFilterInput
    $limit: Int
  ) {
    listTerminalPlannedMaintenanceSchedules(filter: $filter, limit: $limit) {
      items {
        CompanyId
        CreatedAt
        EndTimestampMs
        TerminalId
        ScheduleId
        SerialNo
        ShopId
        StartTimestampMs
        TerminalKind
        UserId
        UserName
      }
    }
  }
`;
