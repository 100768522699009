
















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AuthUser } from '@/models/internal/user.model';
import ShopList from "@/components/shops/ShopList.vue";
import ListHeader from '@/components/functionalities/ListHeader.vue';


@Component({
  components: {
    ListHeader,
    ShopList,
  },
})
export default class Store extends Vue {
  @Getter("auth/hasSystemPermission") hasSystemPermission!: boolean;
  @Getter("auth/user") user!: AuthUser;

  // -------------------------------

  companyId: string | undefined;
  storeId = "";
  search = "";


  // -------------------------------

  async created() {
    console.log('created', this.companyId, this.storeId)
    //jwtTokenにcompanyIdがない間に、直接にIdを入れる。Productionに移動する前に、消さなければならない。
    if (this.hasSystemPermission) {
      this.companyId = ""; // 全て対象
    } else {
      this.companyId = this.user.companyId;
    }
    // 念のためquery版の実装を残す
    if (this.$route.query.companyId) this.companyId = (this.$route.query.companyId as string);
    if (this.$route.query.store) this.storeId = (this.$route.query.store as string);

    // storeSearch: any = this.$route.query.store ? this.$route.query.store : '';
    console.log('created', this.companyId, this.storeId)
  }

}
