

















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AuthUser } from '@/models/internal/user.model';
import ShopList from "@/components/shops/ShopList.vue";
import ListHeader from '@/components/functionalities/ListHeader.vue';


@Component({
  components: {
    ListHeader,
    ShopList,
  },
})
export default class CompanyStores extends Vue {
  @Getter("auth/hasSystemPermission") hasSystemPermission!: boolean;
  @Getter("auth/user") user!: AuthUser;

  // -------------------------------

  companyId: string | undefined;
  storeId: string | undefined;
  search = "";


  // -------------------------------

  async created() {
    this.companyId = this.$route.params.companyId;
    this.storeId = this.$route.params.storeId;
  }

}
