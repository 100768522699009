import { Module } from 'vuex';
import { RootState } from '@/store';
import { GroupState } from './type';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state: GroupState = {
    userGroups: [],
    terminalGroups: [],
}

export const group: Module<GroupState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
