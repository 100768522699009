import GraphQL from '@/interfaces/graphql';
import { listTerminalErrorLogs } from '@/graphql/queries';
import { 
  ListTerminalErrorLogsQuery,
  ListTerminalErrorLogsQueryVariables,
  TerminalErrorLogsFilterInput
} from '@/API'

const limit = 1000;
type NullableTerminalErrorLogsQuery = NonNullable<NonNullable<ListTerminalErrorLogsQuery["listTerminalErrorLogs"]>["items"]>
type ListTerminalErrorLogs = NonNullable<NullableTerminalErrorLogsQuery[number]>[]

export const getAllTerminalErrorLogs = async (filter: TerminalErrorLogsFilterInput | null, nextToken: string| null = null): Promise<ListTerminalErrorLogs> => {
  const queryVariable = {
    filter, limit, nextToken,
  } as ListTerminalErrorLogsQueryVariables
  const queryResult: {data: ListTerminalErrorLogsQuery} = await GraphQL.query(
    listTerminalErrorLogs,
    queryVariable,
    0
  ) as {data: ListTerminalErrorLogsQuery}
  const items = queryResult.data.listTerminalErrorLogs?.items
  const queryNextToken = queryResult.data.listTerminalErrorLogs?.nextToken
  if (!items) return []
  // エラークエリの場合はnullが混じるのでフィルタリングする。
  let result = items.filter((v) => v != null) as ListTerminalErrorLogs;
  if (queryNextToken){
    const nextResult = await getAllTerminalErrorLogs(filter, queryNextToken);
    result = result.concat(nextResult)
  }
  // console.log('[TerminalErrorLogs]', result);
  return result;
}
