
















import { Component, Vue } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';

@Component
export default class ForgotPassword extends Vue {
  email = '';

  async submit() {
    try {
      await Auth.forgotPassword(this.email);
      this.$router.push(`/login/passwordReset?email=${this.email}`);
    } catch (error) {
      console.error('Forgot password', error);
    }
  }
}
