






















































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Group extends Vue {
  groupHeaders = [
    { text: 'グループ', value: 'name', sortable: false },
    { text: '', value: 'actions', align: 'right', sortable: false },
  ];
  childGroupHeaders = [
    { text: 'ID', value: 'id', sortable: false },
    { text: 'グループ名', value: 'name', sortable: false },
  ];
  groupData: Array<any> = [];
  childGroupData = [];
  currentGroup: null | Record<string, any> = null;
  selected: Array<any> = [];
  search = this.$route.query.group ? this.$route.query.group : '';
  groupEdit = false;
  groupDelete = false;
  groupDetail = false;
  editedItem: Record<string, any> = {
    name: '',
    id: '',
    groupList: [],
  };
  defaultItem = {
    name: '',
    id: '',
    groupList: [],
  };
  
  async created() {
    this.initialize();   
  }

  @Watch('groupEdit', { deep: true })
  async ongroupEdit(val: any) {
    val || this.close();
  }
  @Watch('groupDelete', { deep: true })
  async ongroupDelete(val: any) {
    val || this.closeDelete();
  }

  initialize() {
    this.groupData = [
      {
        name: 'グループ01',
        id: '0000000000000001',
        groupList: [
          { name: 'ユーザーグループ1', id: 'ug0000000000000001' },
          { name: 'ターミナルグループ1', id: 'tg0000000000000001' },
        ],
      },
      {
        name: 'グループ02',
        id: '0000000000000002',
        groupList: [
          { name: 'ユーザーグループ2', id: 'ug0000000000000002' },
          { name: 'ターミナルグループ2', id: 'tg0000000000000002' },
        ],
      },
      {
        name: 'グループ03',
        id: '0000000000000003',
        groupList: [
          { name: 'ユーザーグループ3', id: 'ug0000000000000003' },
          { name: 'ターミナルグループ3', id: 'tg0000000000000003' },
        ],
      },
      {
        name: 'グループ04',
        id: '0000000000000004',
        groupList: [
          { name: 'ユーザーグループ4', id: 'ug0000000000000004' },
          { name: 'ターミナルグループ4', id: 'tg0000000000000004' },
        ],
      },
      {
        name: 'グループ05',
        id: 'ug0000000000000005',
        groupList: [
          { name: 'ユーザーグループ5', id: 'ug0000000000000005' },
          { name: 'ターミナルグループ5', id: 'tg0000000000000005' },
        ],
      },
      {
        name: 'グループ06',
        id: '0000000000000006',
        groupList: [
          { name: 'ユーザーグループ6', id: 'ug0000000000000006' },
          { name: 'ターミナルグループ6', id: 'tg0000000000000006' },
        ],
      },
      {
        name: 'グループ07',
        id: '0000000000000007',
        groupList: [
          { name: 'ユーザーグループ7', id: 'ug0000000000000007' },
          { name: 'ターミナルグループ7', id: 'tg0000000000000007' },
        ],
      },
      {
        name: 'グループ08',
        id: '0000000000000008',
        groupList: [
          { name: 'ユーザーグループ8', id: 'ug0000000000000008' },
          { name: 'ターミナルグループ8', id: 'tg0000000000000008' },
        ],
      },
      {
        name: 'グループ09',
        id: '0000000000000009',
        groupList: [
          { name: 'ユーザーグループ9', id: 'ug0000000000000009' },
          { name: 'ターミナルグループ9', id: 'tg0000000000000009' },
        ],
      },
      {
        name: 'グループ10',
        id: '0000000000000010',
        groupList: [
          { name: 'ユーザーグループ10', id: 'ug0000000000000010' },
          { name: 'ターミナルグループ10', id: 'tg0000000000000010' },
        ],
      },
    ];
  }

  groupRowClasses(item: any) {
    if (this.currentGroup && item.id === this.currentGroup.id) {
      return 'active';
    }
  }

  toggleSelected(item: any) {
    if (this.selected.includes(item)) {
      this.selected = this.selected.filter((v) => v !== item);
    } else {
      this.selected.push(item);
    }
  }

  editItem() {
    this.editedItem = Object.assign({}, this.currentGroup);
    this.groupEdit = true;
  }

  close() {
    this.groupEdit = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
    });
  }

  closeDelete() {
    this.groupDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
    });
  }

  save() {
    this.close();
  }

  openDetail(item: any) {
    this.currentGroup = item;
    this.selected = this.currentGroup!.groupList;
  }
}
