import moment from 'moment';

/** UTCエポック秒を表示用の文字列にフォーマットする */
export function formatEpochSec(time: number, withTime = false): string { 
    if (!time) return '';
    const t = moment.unix(time).utc().local();
    if (withTime) {
        return t.format('YYYY年MM月DD日 HH:mm');
    } else { 
        // return t.format('LL');
        return t.format('YYYY年MM月DD日'); // NOTE: 他の箇所の他言語対応をしていないため、Llは使わない
    }
}

/** UTCエポックミリ秒を表示用の文字列にフォーマットする */
export function formatEpochMilliSec(time: number, withTime = false): string { 
    if (!time) return '';
    const t = moment(time).utc().local();
    if (withTime) {
        return t.format('YYYY年MM月DD日 HH:mm');
    } else { 
        // return t.format('LL');
        return t.format('YYYY年MM月DD日'); // NOTE: 他の箇所の他言語対応をしていないため、Llは使わない
    }
}