/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:2b1d1388-aa17-4328-b204-852201159bcf",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_L8QqIAXY2",
    "aws_user_pools_web_client_id": "1bfu7lhtkeeo807k7kcjlkdr78",
    "oauth": {},
    "aws_content_delivery_bucket": "topupweb-20210904143855-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://dx6yo5hd1ydyt.cloudfront.net",
    "aws_user_files_s3_bucket": "topupwebda2dd5667b104c6ab189445c8f11d49d142838-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://tqxx77vrvbb2tfikjxwl5htg54.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2eke5ljptbetbld5soc2gqrsxu"
};


export default awsmobile;
