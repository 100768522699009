import axios from 'axios';
// import awsmobile from '@/aws-exports';

const TAG = '[TopupAdminApi]';
export default class TopupAdminApi {
    private baseUrl: string;
    private apiKey: string;

    constructor() {
        this.baseUrl = process.env.VUE_APP_TOPUP_API_ENDPOINT as string;
        this.apiKey = process.env.VUE_APP_TOPUP_API_KEY as string
    }

    private createConfig() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                // "Access-Control-Allow-Origin": "*",
                // Authorization: this.authKey,
                'x-api-key': this.apiKey,
            },
        };
        return config;
    }

    private async getRequest(path: string) {
        const result = await axios.get(path, this.createConfig());
        if (result.data.message) {
            throw new Error(result.data.message)
        }
        return result.data;
    }

    private async postRequest(path: string, body: any) {
        const result = await axios.post(path, body, this.createConfig())
        if (result.data.message) {
            throw new Error(result.data.message)
        }
        return result.data;
    }

    async setupTerminal(terminalId: string, authCode: string) {
        console.log(TAG, 'setupTerminal', terminalId, authCode)
        const path = `${this.baseUrl}/terminals/${terminalId}/setup`
        const body = {
            shiagelAuthCode: authCode,
        }
        const result = await this.postRequest(path, body)
        console.log(TAG, 'setupTerminal', result)
    }

    async uploadImage(companyId: string, terminalId: string, kind: string, contentType: string, content: string) {
        console.log(TAG, 'uploadImage', companyId, terminalId, kind, contentType, content);
        const path = `${this.baseUrl}/companies/${companyId}/terminals/${terminalId}/assets/${kind}`;
        const body = {
            content,
            contentType,
        }
        const result = await this.postRequest(path, body)
        console.log(TAG, 'uploadImage', result);
        return result;
    }
}