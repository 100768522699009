var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log-operation"},[_c('v-row',{staticClass:"mb-4 d-flex justify-space-between"},[_c('v-col',{attrs:{"cols":"1"}},[_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sysOperationHistories,"sort-by":['TimestampMs'],"search":_vm.search,"sortDesc":""},scopedSlots:_vm._u([{key:"item.TimestampMs",fn:function(ref){
var item = ref.item;
return [_c('p',{class:item.TimestampMs},[_vm._v(_vm._s(_vm.formatEpochMilliSec(item.TimestampMs)))])]}},{key:"item.UserMail",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.UserMail))]}},{key:"item.ViewName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ViewName))]}},{key:"item.OperationName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.OperationName))]}},{key:"item.Detail",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.paraseDetails(item)),function(drow){return _c('div',{staticClass:"body-1"},[_vm._v(_vm._s(drow))])})}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("データなし")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }