

















import { Component, Vue } from "vue-property-decorator";
import AccountList from "@/components/accounts/AccountList.vue";
import ListHeader from '@/components/functionalities/ListHeader.vue';

@Component({
  components: {
    ListHeader,
    AccountList,
  },
})
export default class StoreAccounts extends Vue {
  companyId: string | undefined;
  storeId: string | undefined;
  search = "";

  async created() {
    this.companyId = this.$route.params.companyId;
    this.storeId = this.$route.params.storeId;
  }
}
