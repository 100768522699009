




















import { Component, Vue } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';
import { Action } from 'vuex-class';

@Component
export default class PasswordReset extends Vue {
  confirmationCode = '';
  password = '';
  @Action('auth/login')
  login!: ({ email, password }: { email: string; password: string }) => void;

  async submit() {
    try {
      const email: string = this.$route.query.email.toString();
      const { confirmationCode, password } = this;

      await Auth.forgotPasswordSubmit(email, confirmationCode, password);
      await this.login({ email, password });
      this.$router.push('/');
    } catch (error) {
      console.error('passowrd reset', error);
    }
    //this.$router.push('/');
  }
}
