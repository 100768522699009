



























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Sales extends Vue {
  dates = ['2021-01-01', '2021-01-31'];
  menu = false;
  headers = [
    { text: '期間', value: 'term' },
    { text: '金額', value: 'sum' },
  ];
  data: Array<any> = [];

  created() {
    this.initialize();
  }

  initialize() {
    this.data = [
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
      {
        term: '2020/10/10 ~ 2020/10/10',
        sum: '111,111',
      },
    ];
  }
  /*
  editItem(item) {
    this.editedIndex = this.data.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.groupEdit = true;
  }

  close() {
    this.groupEdit = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  save() {
    if (this.editedIndex > -1) {
      Object.assign(this.data[this.editedIndex], this.editedItem);
    } else {
      this.data.push(this.editedItem);
    }
    this.close();
  }

  openDetail(item) {
    this.editedIndex = this.data.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.groupDetail = true;
  }
  */
}
