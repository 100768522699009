import { RouteConfig } from 'vue-router';
import Logs from '../views/logs/Logs.vue';
import LogsDevice from '../views/logs/LogsDevice.vue';
import LogsOperation from '../views/logs/LogsOperation.vue';
import LogsDeviceOperation from '../views/logs/LogsDeviceOperation.vue';
import LogsPayment from '../views/logs/LogsPayment.vue';

export const logs: RouteConfig[] = [

    {
        path: '/logs',
        name: 'Logs',
        component: Logs,
        meta: { requiresAuth: true },
    },
    {
        path: '/logs/device',
        name: 'Logs-Device',
        component: LogsDevice,
        meta: { requiresAuth: true },
    },
    {
        path: '/logs/operation',
        name: 'Logs-Operation',
        component: LogsOperation,
        meta: { requiresAuth: true },
    },
    {
        path: '/logs/payment',
        name: 'Logs-Payment',
        component: LogsPayment,
        meta: { requiresAuth: true },
    },
    {
        path: '/logs/device-operation',
        name: 'Logs-Device-Operation',
        component: LogsDeviceOperation,
        meta: { requiresAuth: true },
    },
];