import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import { Auth } from 'aws-amplify';
// import { auth } from '@/store/auth';
// import { company as companyStore } from '@/store/Company/company';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Account from '../views/account/Account.vue';
import Company from '../views/company/Company.vue';
import Store from '../views/store/Store.vue';
import Customer from '../views/customer/Customer.vue';
import Management from '../views/management/Management.vue';
import FunctionRestriction from '../views/management/FunctionRestriction.vue';
import Notification from '../views/management/Notification.vue';
import Trouble from '../views/management/Trouble.vue';
import Login from '../views/login/Login.vue';
import ForgotPassword from '../views/login/ForgotPassword.vue';
import PasswordReset from '../views/login/PasswordReset.vue';
import Maintenance from '../views/maintenance/Maintenance.vue';
import UserSetting from '../views/user/UserSetting.vue';
import Sales from '../views/sales/Sales.vue';
import { device } from './device';
import { companies } from './companies';
import { terminals } from './terminals';
import { groups } from './groups';
import { logs } from './logs';
// ----
import store from '@/store';
import * as masters from '@/store/modules/masters';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { requiresAuth: true },
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: { requiresAuth: true },
  },
  {
    path: '/store',
    name: 'Store',
    component: Store,
    meta: { requiresAuth: true },
  },
  {
    path: '/customer',
    name: 'Customer',
    component: Customer,
    meta: { requiresAuth: true },
  },
  {
    path: '/management',
    name: 'Management',
    component: Management,
    meta: { requiresAuth: true },
  },
  {
    path: '/management/functionRestriction',
    name: 'Function-Restriction',
    component: FunctionRestriction,
    meta: { requiresAuth: true },
  },
  {
    path: '/management/notification',
    name: 'Notification',
    component: Notification,
    meta: { requiresAuth: true },
  },
  {
    path: '/management/trouble',
    name: 'Trouble',
    component: Trouble,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/forgotPassword',
    name: 'Login-ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/login/passwordReset',
    name: 'Login-PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/setting',
    name: 'User-Setting',
    component: UserSetting,
    meta: { requiresAuth: true },
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
    meta: { requiresAuth: true },
  },
  /*
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/terminals',
    name: 'Terminals',
    component: Terminals,
    meta: { requiresAuth: true },
  },
  */
  ...companies,
  ...terminals,
  ...logs,
  ...device,
  ...groups,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const TAG = 'ROUTER';

//checks auth whenever going from one page to another
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  //checks on server everytime the app changes route
  // const isAuthenticated = (await Auth.currentUserInfo()) != null;
  // const isAuthenticated = auth.state.isAuthenticated;
  console.log(store);
  const isAuthenticated = await store.getters[`auth/isAuthenticated`]();
  const isSystemAdmin = store.getters[`auth/isSystemAdmin`];

  console.log(TAG, to, requiresAuth, isAuthenticated, isSystemAdmin);

  // ログイン済みなのにログイン画面に飛ばされた場合はダッシュボードに戻す
  if (isAuthenticated && to.name == 'Login') { 
    next('/');
    return;
  }

  if (!requiresAuth) { // ログイン不要な画面
    next();
  } else {  // ログインが必要な画面
    if (!isAuthenticated) { // ログイン前
      next('/login');
    } else { // ログイン済み
      try {
        // マスタデータの読込
        await router.app.$store.dispatch(`${masters.name}/fetch`, null);
        await router.app.$store.dispatch(`group/fetchGroups`, null); // グループもココで読み込む
        // TODO: グループの読込をよしなにする

        // 使用制限のチェック
        await router.app.$store.dispatch('checkRestrictionMode', null);
        const isInRestriction =  router.app.$store.getters['isRestrictionMode'];
    
        // 機能制限が発動中の場合はシステム管理者以外は制限中画面へ遷移する
        if (isInRestriction && !isSystemAdmin && to.name !== 'Function-Restriction') {
          next('/management/functionRestriction');
          return;
        }
        if (to.name == 'Function-Restriction' && !isInRestriction && !isSystemAdmin) {
          next('/'); // 使用制限の解除後はトップに戻す
          return;
        }

        // 問題無ければ当該画面へ遷移
        next();
      } catch (e) {
        console.error(e);
        next('/login'); // 問題がある場合はログイン画面へ遷移させる
      }

    }

  }
});

export default router;
