import GraphQL from '@/interfaces/graphql';
import { listTerminalMalfunctionHistories } from '@/graphql/queries';
import {
  ListTerminalMalfunctionHistoriesQuery,
  ListTerminalMalfunctionHistoriesQueryVariables,
  TerminalMalfunctionHistoriesFilterInput
} from '@/API';
import { TerminalMalfunctionHistory } from "@/models/topup/terminalMalfunctionHistory.model";
import QueryResult from './queryResult';

type NullableTerminalMalfunctionHistories = NonNullable<NonNullable<ListTerminalMalfunctionHistoriesQuery["listTerminalMalfunctionHistories"]>["items"]>
type ListTerminalMalfunctionHistories = NonNullable<NullableTerminalMalfunctionHistories[number]>[]

// export const getAllTerminalMalfunctionHistories = async (filter: TerminalMalfunctionHistoriesFilterInput | null, nextToken: string| null = null): Promise<ListTerminalMalfunctionHistories> => {
//   let queryResult: {data: ListTerminalMalfunctionHistoriesQuery}
//   const queryVariable = {
//     filter, limit: 1000, nextToken,
//   } as ListTerminalMalfunctionHistoriesQueryVariables
//   try {
//     queryResult = await GraphQL.query(
//       listTerminalMalfunctionHistories,
//       queryVariable,
//       0
//     ) as {data: ListTerminalMalfunctionHistoriesQuery}
//   }catch (err) {
//     // 本来はデータの不備はエラーにする。
//     // 表示のみの影響なので、一旦処理を継続。
//     queryResult = err as {data: ListTerminalMalfunctionHistoriesQuery}
//   }
//   const items = queryResult.data.listTerminalMalfunctionHistories?.items
//   const queryNextToken = queryResult.data.listTerminalMalfunctionHistories?.nextToken
//   if (!items) return []
//   // エラークエリの場合はnullが混じるのでフィルタリングする。
//   let result = items.filter((v) => v != null) as ListTerminalMalfunctionHistories;
//   if (queryNextToken){
//     const nextResult = await getAllTerminalMalfunctionHistories(filter, queryNextToken);
//     result = result.concat(nextResult)
//   }
//   console.log('[TerminalMalfunctionHistories]', result);
//   return result;
// }


export const getTerminalMalfunctionHistories = async (
  filter: TerminalMalfunctionHistoriesFilterInput | null,
  limit: number,
  nextToken: string | null = null
): Promise<QueryResult<TerminalMalfunctionHistory>> => {
  let queryResult: {data: ListTerminalMalfunctionHistoriesQuery}
  const queryVariable = {
    filter, limit, nextToken,
  } as ListTerminalMalfunctionHistoriesQueryVariables
  try {
    queryResult = await GraphQL.query(
      listTerminalMalfunctionHistories,
      queryVariable,
      0
    ) as {data: ListTerminalMalfunctionHistoriesQuery}
  }catch (err) {
    // 本来はデータの不備はエラーにする。
    // 表示のみの影響なので、一旦処理を継続。
    queryResult = err as {data: ListTerminalMalfunctionHistoriesQuery}
  }
  // console.log(filter, limit, nextToken, queryResult);
  const items = queryResult.data.listTerminalMalfunctionHistories?.items;
  const newNextToken = queryResult.data.listTerminalMalfunctionHistories?.nextToken;
  if (!items) {
    return {
      result: [],
      nextToken: null,
    };
  }

  // エラークエリの場合はnullが混じるのでフィルタリングする。
  const result = items.filter((v) => v != null) as TerminalMalfunctionHistory[];

  // console.log(filter, limit, nextToken, result);
  return {
    result,
    nextToken: newNextToken,
  };
}