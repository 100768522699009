import { getAllTerminalErrorLogs } from "@/interfaces/graphql/terminalErrorLogs" 

export const terminalErrorLogs = {
  namespaced: true,
  state: { error: null, terminalErrorLogs: [] },
  mutations: {
    setTerminalErrorLogs(state: any, payload: any) {
      state.terminalErrorLogs = payload;
    },
  },
  actions: {
    async getTerminalErrorLogs({ commit, dispatch, rootState ,rootGetters }: any) {
      if (rootState.terminals.terminals.length == 0) {
        await dispatch('terminals/getTerminals', { force: true}, { root: true });
      }
      const result = await getAllTerminalErrorLogs(null);
      const terminalIds = await rootGetters['terminals/terminalIds'];
      commit('setTerminalErrorLogs', result.filter(x => terminalIds.includes(x.TerminalId)));
    },
  },
  getters: {
    terminalErrorLogs: (state: any) => state.terminalErrorLogs,
  },
};
