

























import { Component, Vue } from "vue-property-decorator";
import TransactionList from '@/components/transactions/TransactionList.vue';
import TransactionListHeader from '@/components/transactions/TransactionListHeader.vue';
import { statusItems, kindItems } from '@/models/view/transaction';


@Component({
  components: {
    TransactionListHeader,
    TransactionList,
  },
})
export default class LogsPayment extends Vue {

    companyId? = "";
    storeId? = "";
    terminalId? = "";
    kind = "";
    status = "";
    search = "";
    range: Array<string> = [];

    get statusItems() {
      return statusItems;
    }
    get kindItems() {
      return kindItems;
    }

    async created() {
        this.companyId = this.$route.query.company
          ? this.$route.query.company as string
          : undefined;

        this.storeId = this.$route.query.store
          ? this.$route.query.store as string
          : undefined;

        this.terminalId = this.$route.query.terminal
          ? this.$route.query.terminal as string
          : undefined;
    }
}
