export const listTransactions = /* GraphQL */ `
  query ListTransactions($filter: TransactionsFilterInput, $limit: Int) {
    listTransactions(filter: $filter, limit: $limit) {
      items {
        TerminalId
        TimestampMs
        Id
        SerialNo
        TerminalKind
        CompanyId
        ShopId
        Status
        Card {
            Kind
            Id
        }
        NoteInputHistories {
            TimestampMs
            NoteClass
        }
        NoteClass {
            Jpy1000
            Jpy2000
            Jpy5000
            Jpy10000
        }
        TotalAmount
        CreatedAt
      }
    }
  }
`;

export const getTransactions = /* GraphQL */ `
  query GetTransactions {
    getTransactions {
      TerminalId
      TimestampMs
      Id
      SerialNo
      TerminalKind
      CompanyId
      ShopId
      Status
      Card {
        Kind
        Id
      }
      NoteInputHistories {
        TimestampMs
        NoteClass
      }
      NoteClass {
        Jpy1000
        Jpy2000
        Jpy5000
        Jpy10000
      }
      TotalAmount
      CreatedAt
    }
  }
`;
