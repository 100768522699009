import { render, staticRenderFns } from "./UniqueStore.vue?vue&type=template&id=4b65b109&scoped=true&lang=pug&"
import script from "./UniqueStore.vue?vue&type=script&lang=ts&"
export * from "./UniqueStore.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b65b109",
  null
  
)

export default component.exports