
















































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "@/models/commadmin/users.model";
import { CompanyEntity } from "@/models/internal/companyEntity.model";
import { ShopEntity } from "@/models/internal/shopEntity.model";
import { UserGroupEntity } from "@/models/internal/groupEntity.model";
import { getRoleLabel } from "@/models/view/role";
import { formatEpochSec } from "@/tools/datetime";
import * as masters from "@/store/modules/masters";

const Masters = namespace(masters.name);
const Group = namespace("group");

@Component
export default class AccountDetail extends Vue {
  @Prop({ type: Object, required: true, default: null })
  user?: User;

  @Masters.Getter("companies")
  companies!: CompanyEntity[];
  @Masters.Getter("shops")
  shops!: ShopEntity[];
  @Group.Getter("userGroups")
  userGroups!: UserGroupEntity[];

  // -------------------------

  //   async mounted() {
  //   }

  getCompanyName(companyId: string) {
    const result = this.companies.filter(
      (company) => company.base.Id === companyId
    );
    if (result.length == 0) return;
    return result[0].base.Name;
    // return result[0].base.DisplayName;
  }

  getShopName(shopId: string) {
    const result = this.shops.filter((shop) => shop.base.Id === shopId);
    if (result.length == 0) return;
    return result[0].base.Name;
    // return result[0].base.DisplayName;
  }

  getGroupName(groupId: string) {
    const result = this.userGroups.filter((x) => x.base.Id === groupId);
    if (result.length == 0) return;
    return result[0].base.Name;
  }

  getRoleLabel(roleValue: string) {
    return getRoleLabel(roleValue);
  }

  formatEpochSec(time: number) {
    return formatEpochSec(time);
  }

  // --------------------

  /** ユーザグループ */
  moveToUserGroupView() {
    if (!this.user) {
      console.error("invalid operation. user is null");
      return;
    }
    // const companyId = this.user.CompanyId;
    // const storeId = this.user.ShopId;
    const groupId = this.user.GroupId;

    const target = `/group/users/${groupId}`;
    this.$router.push({ path: target });
  }
}
