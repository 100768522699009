import { API, graphqlOperation } from "aws-amplify";
import {
    updateSystemPlannedMaintenanceSchedules,
    createSystemPlannedMaintenanceSchedules
} from "@/graphql/mutations";
import { listSystemPlannedMaintenanceSchedules } from "@/graphql/queries";
import { SystemPlannedMaintenanceSchedules } from "@/models/topup/systemPlannedMaintenanceSchedules.model";
import * as _ from 'lodash';

interface SystemPlannedMaintenanceSchedulesState {
    items: SystemPlannedMaintenanceSchedules[];
}

const state: SystemPlannedMaintenanceSchedulesState = {
    items: []
};
export const systemPlannedMaintenanceSchedules = {
    namespaced: true,
    state,
    mutations: {
        setItems(state: any, payload: any) {
            state.items = payload;
        }
    },
    actions: {
        async fetch(
            { commit }: any,
            payload?: any
        ) {
            try {
                const filter: any = {
                    limit: 1000
                };
                if (payload) {
                    filter.filter = {
                        UserId: { eq: payload.id },
                        UserName: { eq: payload.email }
                    };
                }
                const result: any = await API.graphql(
                    graphqlOperation(
                        listSystemPlannedMaintenanceSchedules,
                        filter
                    )
                );
                const items = _.orderBy(
                    result.data.listSystemPlannedMaintenanceSchedules.items,
                    (a) => new Date(a.CreatedAt),
                    ['desc'])
                commit(
                    "setItems",
                    items
                );
            } catch (error) {
                console.error(error);
                throw new Error(`Error when getting System Planned Maintenance ${error}`);
            }
        },
        async create(
            { commit }: any,
            payload: SystemPlannedMaintenanceSchedules
        ) {
            try {
                console.log(JSON.stringify(payload));
                const schedules = await API.graphql(
                    graphqlOperation(createSystemPlannedMaintenanceSchedules, {
                        input: payload
                    })
                );
                console.log(schedules);
            } catch (error) {
                console.error(error);
                throw new Error(`Error when create System Planned Maintenance ${error}`);
            }
        },

        async update(
            { commit }: any,
            payload: SystemPlannedMaintenanceSchedules
        ) {
            try {
                console.log(JSON.stringify(payload));
                const schedules = await API.graphql(
                    graphqlOperation(updateSystemPlannedMaintenanceSchedules, {
                        input: payload
                    })
                );
                console.log(schedules);
            } catch (error) {
                console.error(error);
                throw new Error(`Error when updating System Planned Maintenance ${error}`);
            }
        }
    }, getters: {
        items: (
            state: SystemPlannedMaintenanceSchedulesState
        ) => state.items
    }
};
