import { render, staticRenderFns } from "./UserSetting.vue?vue&type=template&id=17b4915f&scoped=true&lang=pug&"
import script from "./UserSetting.vue?vue&type=script&lang=ts&"
export * from "./UserSetting.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b4915f",
  null
  
)

export default component.exports