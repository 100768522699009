














import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class LogsDevice extends Vue {
  search = '';
  searchId = '';
  headers = [
    { text: '日時', value: 'ErrorTimeStamp' },
    { text: '端末ID', value: 'TerminalId' },
    { text: '内容', value: 'ErrorMessage' },
    { text: 'コード', value: 'ErrorCode' },
  ];

  @Action('terminalErrorLogs/getTerminalErrorLogs')
  getTerminalErrorLogs!: () => any;

  @Getter('terminalErrorLogs/terminalErrorLogs')
  terminalErrorLogs!: any;

  created() {
    this.getTerminalErrorLogs();
  }
}
