




























import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
// import { auth } from '@/store/auth';
import { AuthUser } from "@/models/internal/user.model";
import { ShopEntity } from "@/models/internal/shopEntity.model";
import { CompanyEntity } from "@/models/internal/companyEntity.model";

interface RoleEntry {
  label: string;
  value: string;
}

@Component
export default class UserSetting extends Vue {
  @Getter("auth/user") user!: AuthUser;
  @Getter("shops/shops") shops!: ShopEntity[];
  @Getter("company/myCompany") myCompany!: CompanyEntity | null;
  @Getter("company/companies") companies!: CompanyEntity[];

  roleItems: RoleEntry[] = [
    {
      label: "店舗スタッフ",
      value: "ShopUser",
    },
    {
      label: "店舗管理者",
      value: "ShopManager",
    },
    {
      label: "スーパーバイザー",
      value: "CorpSuperVisor",
    },
    {
      label: "企業管理者",
      value: "CorpAdmin",
    },
    {
      label: "システムユーザ",
      value: "SystemUser",
    },
    {
      label: "システム管理者",
      value: "SystemAdmin",
    },
  ];

  getCompanyName(companyId: string) {
    // return companyId;
    console.log(this.myCompany);
    if (this.myCompany) {
      return this.myCompany.base.Name;
    }
    if (!this.companies) return companyId;
    const result = this.companies.filter(v => v.base.Id === companyId);
    if (result.length == 0) return companyId;
    return result[0].base.Name;
  }

  getShopName(shopId: string) {
    if (shopId == '_' || shopId == 'NA') return '所属無し';
    const result = this.shops.filter((shop) => shop.base.Id === shopId);
    if (result.length == 0) return;
    return result[0].base.Name;
  }

  getRoleLabel(role: string) {
    if (!role) return;
    const result = this.roleItems.filter((v) => v.value === role);
    return result[0].label;
  }

}
