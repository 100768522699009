


































import { Component, Prop, Vue } from "vue-property-decorator";
import { ShopEntity } from "@/models/internal/shopEntity.model";

@Component
export default class ShopEdit extends Vue {
  @Prop({ type: Object, required: true, default: null })
  shop?: ShopEntity;


    // エラー発生時
    validation = {
        status: true,
        error: {
            message: '',
        }
    }
    
  // -------------------------

  //   async mounted() {
  //   }

  // --------------------

    // deleteItem(item: any) {
    //     this.editedIndex = this.shops.indexOf(item);
    //     this.editedItem = Object.assign({}, item);
    //     this.shopDelete = true;
    // }

    // deleteItemConfirm() {
    //     this.shops.splice(this.editedIndex, 1);
    //     this.closeDelete();
    // }

    // /** 編集ダイアログを閉じる */
    // closeEditDialog() {
    //     this.isEditDialogOpened = false;
    //     this.$nextTick(() => {
    //         // this.editedItem = Object.assign({}, this.defaultItem);
    //         this.editedItem = null;
    //         this.editedIndex = -1;
    //     });
    // }

    // closeDelete() {
    //     this.shopDelete = false;
    //     this.$nextTick(() => {
    //         // this.editedItem = Object.assign({}, this.defaultItem);
    //         this.editedItem = null;
    //         this.editedIndex = -1;
    //     });
    // }

    // // 文字数カウント
    // stringCount(str: string) {
    //     let len = 0;
    //     for (let i = 0; i < str.length; i++) {
    //         (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
    //     }
    //     return len;
    // }

    // // 入力値チェックをする
    // shopEditValidation() {
    //     this.validation.status = true;
    //     console.log("shopEditValidation TaxOffice", this.editedItem);
    //     if (this.editedItem && this.editedItem.property && this.editedItem.property.TaxOffice) {
    //         const len = this.stringCount(this.editedItem.property.TaxOffice);
    //         console.log("shopEditValidation TaxOffice len", len);
    //     }
    //     /*
    //     // TODO 税務署名(全角5文字まで) this.editedItem.TaxOffice
    //     if (len > 5) {
    //         this.validation.error.message = "税務署名(全角5文字まで) です。";
    //         this.validation.status = false;
    //     }
    //     */
    //     // 営業時間設定に誤りがあります。有効な時間を入力してください。

    //     return this.validation.status;
    // }

    // async save() {
    //     // 入力値チェックをする
    //     if(this.shopEditValidation() == false) {
    //         // 入力値に誤りがあった
    //         return;
    //     }

    //     // if (this.editedIndex > -1) {
    //     //   Object.assign(this.shops[this.editedIndex], this.editedItem);
    //     // } else {
    //     //   this.shops.push(this.editedItem);
    //     // }
    //     // DB更新
    //     await this.updateShopProperties(this.editedItem?.property);
    //     // データ再取得
    //     await this.loadShops();

    //     this.closeEditDialog();
    // }
}
