import GraphQL from '@/interfaces/graphql';
// import { listTerminalMalfunctionHistories } from '@/graphql/queries';
import { TransactionsFilterInput } from '@/API';
import {
    // getTransactions,
    listTransactions,
} from '@/CustomQueries/transactions.queries';
import { Transaction } from '@/models/topup/transaction.model';
import QueryResult from './queryResult';


export const getTransactions = async (
  filter: TransactionsFilterInput | null,
  limit: number,
  nextToken: string | null = null
): Promise<QueryResult<Transaction>> => {
  let queryResult: any;
  const queryVariable = {
    filter, limit, nextToken,
  };
  try {
    queryResult = await GraphQL.query(
        listTransactions,
      queryVariable,
      0
    );
  }catch (err) {
    // 本来はデータの不備はエラーにする。
    // 表示のみの影響なので、一旦処理を継続。
    queryResult = err;
  }
  // console.log(filter, limit, nextToken, queryResult);
  const items = queryResult.data.listTransactions?.items;
  const newNextToken = queryResult.data.listTransactions?.nextToken;
  if (!items) {
    return {
      result: [],
      nextToken: null,
    };
  }

  // エラークエリの場合はnullが混じるのでフィルタリングする。
  const result = items.filter((v: any) => v != null) as Transaction[];

  // console.log(filter, limit, nextToken, result);
  return {
    result,
    nextToken: newNextToken,
  };
}