
































































import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MasterDataList from "@/components/abstracts/MasterDataList";
import { UserGroupEntity } from "@/models/internal/groupEntity.model";
import { getRoleLabel } from '@/models/view/role';

const Group = namespace("group");

interface TerminalGroupItem {
  name: string;
  id: string;
  companyId: string;
  shopId: string | null;
}
interface GroupItem extends UserGroupEntity {
  deviceGroupList: TerminalGroupItem[];
}

@Component
export default class UserGroupList extends MasterDataList<GroupItem> {
  @Prop({ type: String, required: false, default: null })
  company?: string;
  @Prop({ type: String, required: false, default: null })
  store?: string;
  @Prop({ type: String, required: false, default: null })
  id?: string;

  // -------------------------

  // @Group.Action("fetchGroups") fetchGroups!: () => Promise<void>;
  @Group.Getter("userGroups") userGroups!: UserGroupEntity[];

  // -------------------------

  groupHeaders = [
    { text: 'ユーザーグループ', value: 'name', sortable: false },
    { text: '', value: 'actions', align: 'right', sortable: false },
  ];
  accountHeaders = [
    { text: 'ID', value: 'id', sortable: false },
    { text: 'ロール', value: 'roleTitle', sortable: false },
    { text: '所属', value: 'shopName', sortable: false },
  ];
  terminalHeaders = [
    { text: 'ターミナルグループ', value: 'name', sortable: false },
  ];

  // -------------------------

  search: string | null = "";
  // loading = true;
  currentGroup: GroupItem | null = null;

  // -------------------------

  // protected get isLoading(): boolean {
  //   return this.isMasterLoading || this.loading;
  // }

  protected get items() {
    return this.userGroups
      .filter((x) => {
        if (this.company && this.company.length > 0) {
          if (x.base.CompanyId != this.company) return false;
        }
        if (this.store && this.store.length > 0) {
          if (x.base.ShopId != this.store) return false;
        }
        if (this.hasId) {
          if (x.base.Id != this.id) return false;
        }
        return true;
      })
      .map((x) => {
        const group: GroupItem = {
          ...x,
          deviceGroupList: [],
        };
        if (x.relGroups) {
          group.deviceGroupList = x.relGroups
            .filter((g) => g != undefined)
            .map((g) => {
              return {
                name: g.name,
                id: g.id,
                companyId: g.base.CompanyId,
                shopId: g.base.ShopId,
              } as TerminalGroupItem;
            });
        }
        return group;
      });
  }

  get hasId(): boolean {
    if (!this.id) return false;
    if (this.id.length == 0) return false;
    return true;
  }

  // -------------------------

  async mounted() {
    // // TODO: groupをmastersに統合できるまではここでロードする
    // if (this.items.length == 0) {
    //   this.loading = true;
    //   try {
    //     await this.fetchGroups();
    //   } catch (ex) {
    //     console.error(ex);
    //   }
    //   this.loading = false;
    // }

    // ID直指定の場合は詳細を開いた状態にする
    if (this.hasId) {
      this.openDetail(this.items[0]);
    }
  }

  groupRowClasses(item: GroupItem) {
    if (this.currentGroup && item.id === this.currentGroup.id) {
      return "active";
    }
  }

  // -------------------------

  getRoleLabel(roleValue: string) {
    return getRoleLabel(roleValue);
  }

  // -------------------------

  /** ターミナルグループ */
  moveToTerminalGroupView(group: TerminalGroupItem) {
    if (!group) {
      console.error("invalid operation. user is null");
      return;
    }
    // const companyId = group.companyId;
    const groupId = group.id;

    const target = `/group/terminals/${groupId}`;
    this.$router.push({ path: target });
  }
}
