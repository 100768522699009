
























import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import JournalDataList from '@/components/abstracts/JournalDataList';
import { FetchCondition} from '@/store/modules/journals/type';
import * as transactions from '@/store/modules/journals/transactions';
import { Transaction } from '@/models/topup/transaction.model';
import { statusItems, kindItems } from '@/models/view/transaction';

const Transactions = namespace(transactions.name);

@Component
export default class TransactionList extends JournalDataList<Transaction> {
    @Prop({ type: String, required: false, default: null })
    company?: string;
    @Prop({ type: String, required: false, default: null })
    store?: string;
    @Prop({ type: String, required: false, default: null })
    terminal?: string;
    @Prop({ type: String, required: false, default: null })
    search?: string;
    @Prop({ type: String, required: false, default: null })
    status?: string;
    @Prop({ type: String, required: false, default: null })
    kind?: string;
    @Prop({ type: Array, required: false, default: null })
    range?: string[];

    // -------------------------

    @Transactions.Getter('items')
    journalItems?: (condition?: FetchCondition) => Transaction[]; 
    @Transactions.Getter('hasNext')
    hasJournalDataNextToken?: (condition?: FetchCondition) => boolean; 
    @Transactions.Getter('isLoading')
    isJournalDataLoading?: boolean; 
    @Transactions.Action('fetch')
    fetchJournalData?: (condition?: FetchCondition) => Promise<void>; 

    // -------------------------

    headers = [
        { text: "ステータス", value: "Status" },
        { text: "日時", value: "TimestampMs" },
        { text: "店舗", value: "ShopId" },
        { text: "チャージ機ID", value: "TerminalId" },
        { text: "取引ID", value: "Id" },
        { text: "種別", value: "Card.Kind" },
        { text: "会員No.", value: "Card.Id" },
        { text: "万", value: "NoteClass.Jpy10000" },
        { text: "五", value: "NoteClass.Jpy5000" },
        { text: "二千", value: "NoteClass.Jpy2000" },
        { text: "千", value: "NoteClass.Jpy1000" },
        { text: "金額", value: "TotalAmount" },
    ];

    // -------------------------

    protected get isLoading(): boolean {
        // if (super.isLoading) return true; // master data
        if (this.isMasterLoading) return true; // master data
        if (this.isJournalDataLoading) return true;
        return false;
    }

    protected get items(): Transaction[] {
        if (!this.journalItems) return [];
        const condition = this.createCondition(); // NOTE: ここの limit は使わない
        return this.journalItems(condition).filter((x) => {
            if (this.company && this.company.length > 0) {
                if (x.CompanyId != this.company) return false;
            }
            if (this.store && this.store.length > 0) {
                if (x.ShopId != this.store) return false;
            }
            if (this.status && this.status.length > 0) {
                if (x.Status != this.status) return false;
            }
            if (this.kind && this.kind.length > 0) {
                if (x.Card.Kind != this.kind) return false;
            }
            if (this.range && this.range.length > 0) { // 日付範囲が指定された
                if (!this.isDateExist(x.TimestampMs, this.range)) return false;
            }
            return true;
        }).sort(function (x, y) { // 日付の降順でソート
            return y.TimestampMs - x.TimestampMs;
        });
    }
  
    protected get hasUnloadedItems(): boolean {
        if (!this.hasJournalDataNextToken) return false;
        const condition = this.createCondition(); // NOTE: ここの limit は使わない
        return this.hasJournalDataNextToken(condition);
    }

    // -------------------------
    
    protected createCondition(limit = -1): FetchCondition | undefined { 
        let condition: FetchCondition | undefined;
        if (this.terminal && this.terminal.length > 0) {
          condition = { terminalId: this.terminal, limit };
        }
        return condition;
    }

    /** アイテム読込処理 */
    protected async loadItems(limit: number) { 
        if (!this.fetchJournalData) return;
        const condition = this.createCondition(limit);
        await this.fetchJournalData(condition);
    }

    // -------------------------


    getStatusLabel(value: string) {
        if (!value) return '';
        const result = statusItems.filter((v) => v.value === value);
        if (result == undefined || result[0] == undefined) return "unknown"; //もし知らないステータスが出てきたら、未知にする。
        return result[0].label;
    }

    getCardKindLabel(value: string) {
        const result = kindItems.filter((v) => v.value === value);
        if (result == undefined || result[0] == undefined) return "unknown"; //もし知らないステータスが出てきたら、未知にする。
        return result[0].label;
    }

    isDateExist(itemTime: number, dates: string[]): boolean {
        let from: number;
        let to: number;
        // FIXME: JSTの+9を手動で入れている
        const tzDiff = -1 * 9 * 3600 * 1000;
        const dayDiff = 24 * 3600 * 1000;
        if (dates.length == 1) {
            from = new Date(dates[0]).getTime() + tzDiff;
            to = new Date(dates[0]).getTime() + tzDiff + dayDiff; 
        } else {
            from = new Date(dates[0]).getTime() + tzDiff;
            to = new Date(dates[1]).getTime()+ tzDiff + dayDiff; 
        }
        console.log('isDateExist', itemTime, from ,to);
        if (from <= itemTime && itemTime <= to) { // 指定期間内
            return true;
        }
        return false;
    }
}
