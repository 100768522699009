
















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AuthUser } from "@/models/internal/user.model";
import AccountList from "@/components/accounts/AccountList.vue";
import ListHeader from '@/components/functionalities/ListHeader.vue';
import * as auth from '@/store/modules/common/auth';

const Auth = namespace(auth.name);

@Component({
  components: {
    ListHeader,
    AccountList,
  },
})
export default class Account extends Vue {
  @Auth.Getter('hasSystemPermission') 
  hasSystemPermission!: boolean;
  @Auth.Getter('user') 
  user!: AuthUser;

  // -------------------------------

  companyId: string | undefined;
  storeId = "";
  search = "";

  // -------------------------------

  async created() {
    //jwtTokenにcompanyIdがない間に、直接にIdを入れる。Productionに移動する前に、消さなければならない。
    if (this.hasSystemPermission) {
      this.companyId = ""; // 全て対象
    } else {
      this.companyId = this.user.companyId;
    }
    // 念のためquery版の実装を残す
    if (this.$route.query.company) this.companyId = (this.$route.query.company as string);
    if (this.$route.query.store) this.storeId = (this.$route.query.store as string);
  }
}
