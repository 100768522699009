var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"loading-text":_vm.messages.loading,"items":_vm.items,"sort-by":"Id","search":_vm.search},on:{"click:row":_vm.openDetail},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.isDetailOpened),callback:function ($$v) {_vm.isDetailOpened=$$v},expression:"isDetailOpened"}},[_c('account-detail',{attrs:{"user":_vm.selectedItem}})],1)]},proxy:true},{key:"item.Affiliation",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getCompanyName(item.CompanyId))+" "+_vm._s(item.ShopId ? _vm.getShopName(item.ShopId) : ''))]}},{key:"item.CognitoGroup",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getRoleLabel(item.CognitoGroup)))]}},{key:"item.LastLoginDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.UnixSecToDate(item.LastLoginDate)))]}},{key:"item.MaintenanceCardId",fn:function(ref){
var item = ref.item;
return [(item.MaintenanceCardId !== null && item.MaintenanceCardId !== '')?[_c('v-icon',{attrs:{"color":"green","dark":""}},[_vm._v("mdi-checkbox-blank-circle-outline")])]:[_c('v-icon',{attrs:{"color":"grey","dark":""}},[_vm._v("mdi-minus-circle-outline")])]]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("データなし")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }