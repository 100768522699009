

























import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ShopEntity } from '@/models/internal/shopEntity.model';
import * as masters from '@/store/modules/masters';

const Masters = namespace(masters.name);

@Component
export default class ListHeader extends Vue {
    @Prop({ type: String, required: false, default: '*' })
    mode?: string; // *|any, company, shop
    @Prop({ type: Boolean, required: false, default: false })
    searchable?: boolean; // 検索可能かどうか
    @Prop({ type: String, required: false, default: null })
    company?: string; // 企業ID
    @Prop({ type: String, required: false, default: null })
    shop?: string; // 店舗ID
    @Prop({ type: String, required: false, default: null })
    query?: string; // 検索ワード

    @Masters.Getter('shops') shops!: ShopEntity[];

    shopDisabled = false;

    // 店舗ID
    get shopId() {
        return this.shop || '';
    }
    set shopId(value: string) {
        this.$emit('on-shop-changed', value);
    }
    // 検索ワード
    get search() {
        return this.query || '';
    }
    set search(value: string) {
        this.$emit('on-query-changed', value);
    }

    // 店舗リスト(select用)
    get shopItems() {
        // 企業IDが指定されている場合は、店舗を絞る
        if (this.company && this.company.length > 0) {
            return this.transform(this.shops.filter((v) => {
                return v.base.CompanyId == this.company;
            }));
        }
        return this.transform(this.shops);
    }

    mounted() {
        console.log(this.mode, this.company, this.shop, this.query);
        switch (this.mode) {
            case 'company': {
                this.shopDisabled = false;
                break;
            }
            case 'shop': {
                this.shopDisabled = true;
                break;
            }
            default: {
                this.shopDisabled = false;
            }
        }
    }

    transform(values: ShopEntity[]) {
        return values.map(v => {
            // NOTE: 企業名と店舗名を同時に選ばせる場合など、表示名を弄る可能性があるので 
            //      item-text/item-value プロパティは使わずにこうしておく
            return {
                text: v.base.DisplayName,
                value: v.base.Id
            };
        })
    }
}

