
import GraphQL from '@/interfaces/graphql';
import { getCompanyPropertiesById } from '@/graphql/queries';
import { CompanyProperty } from '@/models/topup/companyProperty.model';



export const getCompanyProperties = async (id: string) => {
    const params = { Id: id };
    const result = await GraphQL.query(getCompanyPropertiesById, params);
    const property = result.data.getCompanyPropertiesById as CompanyProperty;
    return property;
} 