
































import { Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MasterDataList from '@/components/abstracts/MasterDataList';
import { ShopEntity } from '@/models/internal/shopEntity.model';
import ShopDetail from '@/components/shops/ShopDetail.vue';
import ShopEdit from '@/components/shops/ShopEdit.vue';
import * as auth from '@/store/modules/common/auth';

const Auth = namespace(auth.name);

@Component({
    components: {
        ShopDetail,
        ShopEdit,
    }
})
export default class ShopList extends MasterDataList<ShopEntity> {
    @Prop({ type: String, required: false, default: null })
    company?: string;
    @Prop({ type: String, required: false, default: null })
    store?: string;
    @Prop({ type: String, required: false, default: null })
    search?: string;

    // -------------------------

    @Auth.Getter('hasSystemPermission')
    hasSystemPermission!: boolean;

    // -------------------------
    loading = true;
    selectedStore = '';

    /** 編集ダイアログの表示制御 */
    isEditDialogOpened = false;

    shopDelete = false;
    storeSetting = false;

    headers = [
        { text: '店舗', value: 'base.DisplayName', filter: this.shopFilter,},
        { text: '作成日', value: 'createdAt' },
        // { text: 'Id', value: 'id', align: ' d-none' },
        // { text: '', value: 'actions', align: 'right', sortable: false },
    ];
    headersAdmin = [
        { text: '企業', value: 'companyName' },
        { text: '店舗', value: 'base.DisplayName' },
        { text: '作成日', value: 'createdAt' },
        // { text: 'Id', value: 'id', align: ' d-none' },
        // { text: '', value: 'actions', align: 'right', sortable: false },
    ];

    // -------------------------------


    // @Watch('shopEdit', { deep: true })
    // async onShopEdit(val: any) {
    //     val || this.closeEditDialog();
    // }
    // @Watch('shopDelete', { deep: true })
    // async onShopDelete(val: any) {
    //     val || this.closeDelete();
    // }


    // -------------------------


    protected get items() {
        if(!this.shops) return [];
        console.log('storeItems', this.shops.length, this.company, this.store);
        return this.shops.filter((v) => {
            if (this.company && this.company.length > 0) {
                if (v.base.CompanyId != this.company) return false;
            }
            if (this.store && this.store.length > 0) {
                if (v.base.Id != this.store) return false;
            }
            if (v.base.IsDelete == true) return false;
            if(this.selectedStore && v.base.DisplayName != this.selectedStore) {
                return false;
            }
            return true;
        });
    }


    // -------------------------

    async mounted() {
        if (this.hasSystemPermission) {
            this.headers = this.headersAdmin;
        }
    }

    shopFilter(value: string): boolean {
        console.log("u enter", this.selectedStore)
        if(!this.selectedStore) {
            return true;
        } else if(value == this.selectedStore){
            return true;
        }
        return false;
    }

}
