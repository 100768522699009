import { RootState } from '@/store';
import {
    State,
    FetchCondition,
    Getters,
    Actions,
    Mutations,
} from './type';
import CommAdminApi from '@/interfaces/commAdminApi';
import { getJwt } from '@/store/modules/common/auth.utils';
import { Acl } from '@/interfaces/type';
// import { User } from '@/models/commadmin/users.model';
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import { ShopEntity } from '@/models/internal/shopEntity.model';
import { TerminalEntity } from '@/models/internal/terminalEntity.model';
import { Terminal } from '@/models/commadmin/terminals.model';
import { getCompanyProperties } from '@/interfaces/graphql/companyProperties';
import { getShopProperties } from '@/interfaces/graphql/shopProperties';
import { getTerminalProperties } from '@/interfaces/graphql/terminalProperties';
import * as auth from '@/store/modules/common/auth';
import { AuthUser } from '@/models/internal/user.model';
import { sliceByNumber } from '@/tools/array';

const LOAD_INTERVAL = 500 * 60 * 1000; //  再読込を行うインターバル

// -------------------------

export const namespaced = true;

export const name = 'masters';
// -------------------------

export const state = (): State => ({
    acl: null, // FIXME
    users: [], 
    companies: [],
    shops: [], 
    terminals: [],
    isLoading: false,
    fetchedAt: -1,
    loadingStatus: '',
});

export const getters: Getters<State, RootState> = {
    users: (state) => state.users,
    companies: (state) => state.companies, 
    shops: (state) => state.shops,
    terminals: (state) => state.terminals,
    terminalIds: (state) => state.terminals.map(x => x.base.Id),
    myCompany: (state, getters, rootState, rootGetters) => { 
        const user = rootGetters[`${auth.name}/user`]
            ? (rootGetters[`${auth.name}/user`] as AuthUser)
            : null;
        if (!user) return null;
        const filterd = state.companies.filter(x => x.base.Id == user.companyId);
        if (filterd.length == 0) return null;
        return filterd[0];
    },
    isLoading: (state) => state.isLoading,
    loadingStatus: (state) => state.loadingStatus,
}

export const actions: Actions<State, RootState> = {
    async fetch({ state, commit, rootGetters }, condition?: FetchCondition) {
        let needLoad = false;
        if (condition && condition.force) { 
            needLoad = true;
        }
        const now = new Date().getTime();
        if (!needLoad && (now - state.fetchedAt) > LOAD_INTERVAL) {
            needLoad = true;
        }
        if (!needLoad && (state.users.length == 0 || state.companies.length == 0 || state.shops.length == 0)) { 
            // 前回取得件数が0
            needLoad = true;
        }
        if (!needLoad) return; // 読込は不要

        console.log('loading master data...', condition, state.users.length, state.companies.length, state.shops.length, state.fetchedAt);

        // loadingフラグ
        commit('setIsLoading', true);

        commit('setLoadingStatus', 'マスタデータ読み込み開始');

        // 既存のクリア
        commit('setUsers', []);
        commit('setCompanies', []);
        commit('setShops', []);
    
        const authKey = await getJwt();
        const api = new CommAdminApi(authKey);
    
        let acl: Acl | null = null;
        const companies: CompanyEntity[] = [];
        const shops: ShopEntity[] = [];
        const terminals: TerminalEntity[] = [];
    
        // load acl
        { 
            commit('setLoadingStatus', '権限情報の読み込み中');

            const user = rootGetters['auth/user'];
            acl = await api.getUserAcl(user.id);
            commit('setAcl', acl);
            console.log('6imOeaa', acl.Shops.filter(x => x == '6imOeaa'));
        }
    
        // load companies
        { 
            commit('setLoadingStatus', '企業マスタの読み込み中');
            let count = 0;

            // NOTE: net::ERR_INSUFFICIENT_RESOURCES 対策 > Promise.all() をやめる
            for (const base of (await api.getCompanies())) { 
                // console.log('getCompanies', v.Id);
                const entity: CompanyEntity = {
                    base: base,
                };
                try {
                    // チャージ機管理用のプロパティ項目を取得
                    const property = await getCompanyProperties(base.Id);
                    entity.property = property;
                } catch (ex) {
                    // 取得できない場合も継続する
                    console.error('error in getCompanyProperties', ex, base);
                }
                companies.push(entity);
                count++;
                commit('setLoadingStatus', `企業マスタの読み込み中: ${count}`);

            }
            // const promises = (await api.getCompanies()).map(async (v) => {
            //     // console.log('getCompanies', v.Id);
            //     const entity: CompanyEntity = {
            //         base: v,
            //     };
            //     try {
            //         // チャージ機管理用のプロパティ項目を取得
            //         const property = await getCompanyProperties(v.Id);
            //         entity.property = property;
            //     } catch (ex) {
            //         // 取得できない場合も継続する
            //         console.error('error in getCompanyProperties', ex, v);
            //     }
            //     companies.push(entity);
            // });
            // for (const deffereds of sliceByNumber(promises, 1000)) { // 分割実行
            //     await Promise.all(deffereds);
            // }
            commit('setCompanies', companies);
        }

        // load users
        { 
            commit('setLoadingStatus', `企業ごとのユーザ情報の読み込み中(0/${companies.length})`);

            let count = 0;
            for (const company of companies) {
                // 共通管理からマスター取得
                const users = await api.getCompanyUsers(company.base.Id);
                commit('addUsers', users);
                count++;

                commit('setLoadingStatus', `企業ごとのユーザ情報の読み込み中(${count}/${companies.length})`);
            }
        }

        // load shops
        { 
            commit('setLoadingStatus', `企業ごとの店舗マスタの読み込み中(0/${companies.length})`);

            let count = 0;
            for (const company of companies) {
                // 共通管理からマスター取得
                const baseShops = await api.getStores(company.base.Id);
                for (const shop of baseShops) {
                    const entity = { base: shop } as ShopEntity;
                    try {
                        // チャージ機管理用のプロパティ項目を取得
                        const property = await getShopProperties(shop.Id);
                        entity.property = property;
                    } catch (ex) {
                        // 取得できない場合も継続する
                        console.error('error in getShopProperties', ex, shop);
                    }
                    shops.push(entity);
                }
                count++;
                commit('setLoadingStatus', `企業ごとの店舗マスタの読み込み中(${count}/${companies.length})`);
            }
            // await Promise.all(acl.Companies.map(async (id) => { 
            //     // 共通管理からマスター取得
            //     const baseShops = await api.getStores(id);
            //     for (const shop of baseShops) {
            //         const entity = { base: shop } as ShopEntity;
            //         try {
            //             // チャージ機管理用のプロパティ項目を取得
            //             const property = await getShopProperties(shop.Id);
            //             entity.property = property;
            //         } catch (ex) {
            //             // 取得できない場合も継続する
            //             console.error('error in getShopProperties', ex, shop);
            //         }
            //         shops.push(entity);
            //     }
            //     return true;
            // }));
            commit('setShops', shops);
        }

        // load terminals
        { 
            const numOfTerminals = acl.Terminals.length;
            commit('setLoadingStatus', `端末マスタの読み込み中(0/${numOfTerminals})`);

            const kind = 'TopUp';
            // for (const company of companies) { 
            //     for (const shop of shops) {
            //         let baseTerminals: Terminal[] = [];
            //         try {
            //             baseTerminals = await api.getTerminals(company.base.Id, shop.base.Id);
            //         } catch (error) {
            //             console.log(error)
            //             continue;
            //         }
            //         for (const terminal of baseTerminals) {
            //             // if (entities.filter(x => x.base.Id == terminal.Id).length > 0) {
            //             //     continue; // 重複があればスキップ
            //             // }
            //             if (!acl.Terminals.includes(terminal.Id)) continue;
            //             if (terminal.Kind != kind) continue;
            //             // TODO: 非稼働の場合の対応
            //             const status = true; // 稼働中かどうか
            //             const entity = { base: terminal, status } as TerminalEntity;
            //             try {
            //                 // const entity = await process({ rootGetters }, terminal, companyId, shop.Id);
            //                 // チャージ機管理用のプロパティ項目を取得
            //                 const property = await getTerminalProperties(terminal.Id, kind);
            //                 if (!property) {
            //                     continue; // TerminalPropertiesが無い場合は異常データとしてスキップする
            //                 }
            //                 entity.property = property;
            //             } catch (ex) {
            //                 // 取得できない場合も継続する
            //                 console.error('error in getTerminalProperties', ex, terminal);
            //             }
            //             terminals.push(entity)
            //         }
            //     }
            // }
            // NOTE: net::ERR_INSUFFICIENT_RESOURCES 対策 > Promise.all() をやめる
            for (const id of acl.Terminals) {
                try {
                    // チャージ機管理用のプロパティ項目を取得
                    const property = await getTerminalProperties(id, kind);
                    if (!property) {
                        // continue; // not found
                        // return null;
                        continue;
                    }
                    const base = {
                        Id: id,
                        Kind: kind,
                        AffiliatedGroups: null,
                        IsDelete: false,
                        CreatedAt: property.CreatedAt,
                    } as Terminal;
                    // TODO: 非稼働の場合の対応
                    const status = true; // 稼働中かどうか
                    const entity = { base, property, status } as TerminalEntity;
                    terminals.push(entity);

                    commit('setLoadingStatus', `端末マスタの読み込み中(${terminals.length}/${numOfTerminals})`);
                    // return entity;
                } catch (ex) {
                    // 取得できない場合も継続する
                    console.error('error in getTerminalProperties', ex, id);
                    // return null; // not found
                }
            }
            // for (const terminalId of acl.Terminals) { 
            //     try { 
            //         if (terminals.filter(x => x.base.Id == terminalId).length > 0) {
            //             continue; // 重複があればスキップ
            //         }
            //         // const entity = await process({ rootGetters }, terminal, companyId, shop.Id);
            //         const termianl = await api.getTerminalById(terminalId);
            //         if (!termianl) { 
            //             continue; // not found
            //         }

            //         // チャージ機管理用のプロパティ項目を取得
            //         const property = await getTerminalProperties(terminalId, kind);
            //         if (!property) { 
            //             continue; // not found
            //         }
            //         const entity = { base: termianl, property } as TerminalEntity;
            //         terminals.push(entity)
            //     } catch (ex) {
            //         // 取得できない場合も継続する
            //         console.error('error in getTerminalProperties', ex, terminalId);
            //     }
            // }
            commit('setTerminals', terminals);
        }

        // load groups

        // loadingフラグ
        commit('setIsLoading', false);
        commit('setFetchedAt', now);
        commit('setLoadingStatus', 'マスタデータ読み込み完了');
        console.log('loading master data...finished.', companies);
    },
    async clear({ commit }) { 
        commit('setAcl', null);
        commit('setUsers', []);
        commit('setCompanies', []);
        commit('setShops', []);
    },
}

export const mutations: Mutations<State> = {
    setAcl(state, value) { 
        state.acl = value;
    }, 
    setUsers(state, items) { 
        state.users = items;
    },
    addUsers(state, items) { 
        state.users.push(...items);
    },
    setCompanies(state, items) { 
        state.companies = items;
    },
    addCompanies(state, items) { 
        state.companies = items;
    },
    setShops(state, items) { 
        state.shops = items;
    },
    setTerminals(state, items) { 
        state.terminals = items;
    },
    setIsLoading(state, value) { 
        state.isLoading = value;
    },
    setFetchedAt(state, value) { 
        state.fetchedAt = value;
    },
    setLoadingStatus(state, value) {
        state.loadingStatus = value;
    },
}