






































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
/* @ts-ignore */
import { formatEpochMilliSec } from '@/tools/datetime';

@Component
export default class LogsOperation extends Vue {
  @Action("systemMalfunctions/getSysMalfuncHistories")
  getSysMalfuncHistories!: () => void;
  @Getter("systemMalfunctions/sysMalfunctionHistories")
  sysMalfunctionHistories!: any;

  @Action("systemOperationHistories/getSystemOperationHistories")
  getSystemOperationHistories!: () => void;
  @Getter("systemOperationHistories/SystemOperationHistories")
  sysOperationHistories!: any;

  search = "";

  headers = [
    { text: "操作日時", value: "TimestampMs" },
    { text: "ユーザID", value: "UserMail" },
    { text: "画面", value: "ViewName" },
    { text: "操作", value: "OperationName" },
    { text: "詳細", value: "Detail", width: "20%" },
  ];

  async created() {
    // this.getSysMalfuncHistories();
    this.getSystemOperationHistories();
  }

  paraseDetails(item: any): string[] {
    if (!item.Detail) {
      return [];
    }
    const detail = JSON.parse(item.Detail);
    const ip = detail.clientIpAddress || "不明";
    switch (item.OperationId) {
      case "login": 
        return [`利用者IP: ${ip}`,`メールアドレス: ${detail.email}`];
      case "setupTerminal":
        return  [`利用者IP: ${ip}`,`端末ID: ${detail.terminalId}`,`設置シリアル: ${detail.authCode}`];
      case "editTerminalProperty":
        return  [`利用者IP: ${ip}`,`端末ID: ${detail.terminalId}`];
      case "startFunctionRestriction":
        return  [`利用者IP: ${ip}`,`端末ID: ${detail.message}`];
      default:
        return [`利用者IP: ${ip}`];
    }
  }

  pretty(value: string) {
    // for(let v in value){

    // }
    return JSON.parse(value);
  }

  formatEpochMilliSec(time: number) {
    return formatEpochMilliSec(time, true);
  }
}
