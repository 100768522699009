















































import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ShopEntity } from '@/models/internal/shopEntity.model';
import { statusItems, disabilityItems } from '@/models/view/terminal';
import * as masters from "@/store/modules/masters";

const Masters = namespace(masters.name);

@Component
export default class TerminalListHeader extends Vue {
    @Prop({ type: String, required: false, default: null })
    company?: string; // 企業ID
    @Prop({ type: String, required: false, default: null })
    shop?: string; // 店舗ID
    @Prop({ type: Boolean, required: false, default: true })
    shopEditable?: boolean; // 店舗が指定可能か
    @Prop({ type: String, required: false, default: null })
    query?: string; // 検索ワード
    @Prop({ type: String, required: false, default: null })
    status?: string; // 端末ステータス
    @Prop({ type: String, required: false, default: null })
    disability?: string; // 障害

    @Masters.Getter('shops') shops!: ShopEntity[];

    shopDisabled = false;

    // 店舗ID
    get shopId() {
        return this.shop || '';
    }
    set shopId(value: string) {
        this.$emit('on-shop-changed', value);
    }
    // 検索ワード
    get search() {
        return this.query || '';
    }
    set search(value: string) {
        this.$emit('on-query-changed', value);
    }
    // 端末ステータス
    get statusValue() {
        return this.status || '';
    }
    set statusValue(value: string) {
        this.$emit('on-status-changed', value);
    }
    // 障害
    get disabilityValue() {
        return this.disability || '';
    }
    set disabilityValue(value: string) {
        this.$emit('on-disability-changed', value);
    }

    // 店舗リスト(select用)
    get shopItems() {
        // 企業IDが指定されている場合は、店舗を絞る
        if (this.company && this.company.length > 0) {
            return this.transform(this.shops.filter((v) => {
                return v.base.CompanyId == this.company;
            }));
        }
        return this.transform(this.shops);
    }

    get statusItems() {
      return statusItems;
    }
    get disabilityItems() {
      return disabilityItems;
    }


    mounted() {
        // console.log(this.company, this.shop, this.query);
    }

    transform(values: ShopEntity[]) {
        return values.map(v => {
            // NOTE: 企業名と店舗名を同時に選ばせる場合など、表示名を弄る可能性があるので 
            //      item-text/item-value プロパティは使わずにこうしておく
            return {
                text: v.base.DisplayName,
                value: v.base.Id
            };
        })
    }
}

