import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import '@/assets/scss/style.scss';
import * as dotenv from 'dotenv';

dotenv.config();

// import awsmobile from './aws-exports';
// Amplify.configure(awsmobile)

import awsmobile from '@/aws-exports';

amplify.configure(awsmobile);

// Vue.config.devtools = true;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
