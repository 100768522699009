



















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ListSearchHeader extends Vue {
    @Prop({ type: String, required: false, default: null })
    query?: string; // 検索ワード

    // 検索ワード
    get search() {
        return this.query || '';
    }
    set search(value: string) {
        this.$emit('on-query-changed', value);
    }
}

