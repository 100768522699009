
































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyEntity } from "@/models/internal/companyEntity.model";
import { formatEpochSec } from "@/tools/datetime";

@Component
export default class AccountDetail extends Vue {
  @Prop({ type: Object, required: true, default: null })
  company?: CompanyEntity;

  // -------------------------

  //   async mounted() {
  //   }

  formatEpochSec(time: number) {
    return formatEpochSec(time);
  }

  // --------------------

  /** 企業・店舗別 / アカウント */
  moveToAccountsView() {
    if (!this.company) {
      console.error("invalid operation. selectedItem is null");
      return;
    }
    const companyId = this.company.base.Id;

    const target = `/companies/${companyId}/accounts`;
    this.$router.push({ path: target });
  }

  /** 企業・店舗別 / 端末 */
  moveToTerminalsView() {
    if (!this.company) {
      console.error("invalid operation. selectedItem is null");
      return;
    }
    const companyId = this.company.base.Id;

    const target = `/companies/${companyId}/terminals`;
    this.$router.push({ path: target });
  }

  /** 企業別 / 店舗 */
  moveToStoresView() {
    if (!this.company) {
      console.error("invalid operation. selectedItem is null");
      return;
    }
    const companyId = this.company.base.Id;

    const target = `/companies/${companyId}/stores`;
    this.$router.push({ path: target });
  }
}
