











import { Component, Vue, Prop } from "vue-property-decorator";
import TopupAdminApi from "@/interfaces/topupAdminApi";

@Component
export default class AssetImageFileInput extends Vue {
    @Prop({ type: String, required: true })
    company?: string; // companyId

    @Prop({ type: String, required: true })
    terminal?: string // terminalId

    @Prop({ type: String, required: true })
    name?: string // file key

    @Prop({ type: String, required: false, default: null })
    current?: string // current file arn

    inputFile: string[] = [];

    get label(): string[] {
        // if (this.inputFile.length > 0) return this.inputFile;
        if (this.current) {
            return [this.current];
        }
        return this.inputFile;
    }

    mounted() {
        if (this.current) {
            this.inputFile = [ this.current ];
        }
    }

    /** S3にファイルをアップロードし、対応するフィールドに保存する */
    async saveFileS3(file: File) {
        console.log("saveFileS3", file);
        if (this.company == undefined) return;
        if (this.terminal == undefined) return;
        if (this.name == undefined) return; 
        if (file == null || file == undefined) return; // empty
        // if (file.length == 0 || !file[0] || file[0].length == 0) return; // [] or [""]
        if (file.size == 0) return; // empty

        try {
            // this.deviceEditFormValid = false;
            console.log(`TYPE: ${typeof file} FILE`, file);
            if (file.size <= 0) {
                // this.deviceEditFormValid = true;
                return;
            }
            // BASE64に変換
            const fileData = await (() => {
                return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
                });
            })();
            console.log(fileData);

            // S3にファイルをアップロード
            const content = fileData as string;
            const contentType = file.type as string;
            const api = new TopupAdminApi();
            const { arn } = await api.uploadImage(
                this.company,
                this.terminal,
                this.name,
                contentType,
                content
            );


            console.log('save file', this.name, arn);
            this.$emit('save', arn)
        } catch (error) {
            console.error("Save file s3 error", error);
        } finally {
            // this.deviceEditFormValid = true;
        }
    }

  clearImageField(): void {
    this.$emit('clear', this.name); // 画像を削除したことを親コンポーネントに伝える
  }
}
