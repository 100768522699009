import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { GroupState } from './type';
import CommAdminApi from '@/interfaces/commAdminApi';
import {
    UserGroupEntity,
    TerminalGroupEntity,
    UserItem,
    TerminalItem,
} from '@/models/internal/groupEntity.model';
import { User } from '@/models/commadmin/users.model';
import { AuthUser } from '@/models/internal/user.model';
import { Group } from '@/models/commadmin/groups.model';
import { CompanyEntity } from '@/models/internal/companyEntity.model';
import { ShopEntity } from '@/models/internal/shopEntity.model';
import { TerminalEntity} from '@/models/internal/terminalEntity.model';
import { getRoleLabel } from '@/models/view/role';

type ProcessContext = {
    rootGetters: any;
};

const getTerminalById = (ctx: ProcessContext, terminalId: string) => {
    const terminals: TerminalEntity[] = ctx.rootGetters['masters/terminals'];
    const result = terminals.find(x => x.base.Id == terminalId);
    if (!result) {
        return null;
    }
    return result;
}
// const getShopById = (ctx: ProcessContext, shopId: string | null) => {
//     if (!shopId) return null;
//     const shops: ShopEntity[] = ctx.rootGetters['masters/shops'];
//     const result = shops.filter((v) => { v.base.Id == shopId });
//     if (result.length == 0) {
//         return null;
//     }
//     return result[0];
// }
const getUserById = (ctx: ProcessContext, userId: string | null) => {
    if (!userId) return null;
    const users: User[] = ctx.rootGetters['masters/users'];
    const result = users.find(x => x.Id == userId);
    if (!result) {
        return null;
    }
    return result;
}

const processTerminalGroupEntity = (ctx: ProcessContext, group: Group) => {
    const terminals: TerminalItem[] = [];
    if (group.Members.Terminals) { 
        for (const v of group.Members.Terminals) { 
            const item: TerminalItem = {
                id: v.Id,
                name: `${v.Kind}-${v.Id}`,
                shopId: '',
                shopName: '',
            };
            const terminal = getTerminalById(ctx, v.Id);
            if (terminal && terminal.property) {
                item.name = terminal.property.DisplayName || '';
                item.shopId = terminal.property.ShopId || '';
                // item.roleTitle = getRoleLabel(user.CognitoGroup);

                // const shop = getShopById(ctx, terminal.property.ShopId);
                // if (shop) {
                //     item.shopName = shop.base.DisplayName || '';
                // }
            }
            terminals.push(item);
        }
    }
    const entity = {
        base: group,
        id: group.Id,
        name: group.Name,
        terminals,
    } as TerminalGroupEntity;
    return entity;
} // TerminalGroupEntity

const processUserGroupEntity = (ctx: ProcessContext, group: Group) => {
    const users: UserItem[] = [];
    if (group.Members.Users) { 
        for (const v of group.Members.Users) { 
            const item: UserItem = {
                id: v.Id,
                name: '',
                role: '',
                roleTitle: '',
                shopId: '',
                shopName: '',
            };
            const user = getUserById(ctx, v.Id);
            if (user) {
                item.name = user.Email;
                item.role = user.CognitoGroup;
                // item.roleTitle = getRoleLabel(user.CognitoGroup);
                item.shopId = user.ShopId;

                // const shop = getShopById(ctx, user.ShopId);
                // if (shop) { 
                //     item.shopName = shop.base.DisplayName || '';
                // }
            }
            users.push(item);
        }
    }
    const entity = {
        base: group,
        id: group.Id,
        name: group.Name,
        users,
    } as UserGroupEntity;
    return entity;
} // UserGroupEntity

const actions: ActionTree<GroupState, RootState> = {
    async fetchGroups({ state, commit, dispatch, rootGetters }) {
        if (state.userGroups.length != 0 && state.terminalGroups.length != 0) { 
            return; // 読込済み
        }
        commit('clearGroups');
        // ログイン中のユーザが参照可能なリソースを読み込む
        // await Promise.all([
        // //   dispatch('company/getCompany', null, { root: true }),
        // //   dispatch('users/getUsers', null, { root: true }),
        // //   dispatch('terminals/getTerminals', null, { root: true }),
        //   dispatch('auth/authAction', null, {root: true}),
        // ])

        const companies: CompanyEntity[] = rootGetters['masters/companies']
        const authKey = await rootGetters['auth/jwt'];
        const api = new CommAdminApi(authKey);
        const user: AuthUser = rootGetters['auth/user'];

        let userGroups: UserGroupEntity[] = [];
        let terminalGroups: TerminalGroupEntity[] = [];


        for (const company of companies) {
            const groups = await api.getCompanyGroups(company.base.Id);
            for (const group of groups) {
                if (group.Kind == 'Principal') { // 画面表示に使うのは Secondary のみ
                    continue;
                }
                // TODO: 企業なしの扱いチェック
                switch (group.MemberKind) {
                    case 'Users':
                        userGroups.push(processUserGroupEntity({
                            rootGetters,
                        }, group));
                        break;
                    case 'Terminals':
                        terminalGroups.push(processTerminalGroupEntity({
                            rootGetters,
                        }, group));
                        break;
                }
            }
        }
        // ユーザーグループIDがセカンダリ時の制限
        if (user.groupId.startsWith("Secondary")){
          userGroups = userGroups.filter(x => x.id === user.groupId)
          terminalGroups = terminalGroups.filter(x => userGroups.map(y => y.base.RelGroups).flat().includes(x.id))
        }else{
          if (user.role === "ShopManager" || user.role === "ShopUser"){
            userGroups = userGroups.filter(x => x.base.ShopId === user.shopId)
            terminalGroups = terminalGroups.filter(x => x.base.ShopId === user.shopId)
          }
        }
        for (const group of userGroups) {
            group.relGroups = group.base.RelGroups.map((v) => {
                const g = terminalGroups.filter((t) => t.id == v );
                return g[0];
            })
            commit('addUserGroup', group);
        }
        for (const group of terminalGroups) {
            group.relGroups = group.base.RelGroups.map((v) => {
                const g = userGroups.filter((t) => t.id == v );
                return g[0];
            })
            commit('addTerminalGroup', group);
        }
    }
};


export default actions;
