
import GraphQL from '@/interfaces/graphql';
import { getTerminalPropertiesById } from '@/graphql/queries';
import { TerminalProperty } from '@/models/topup/terminalProperty.model';



export const getTerminalProperties = async (id: string, kind: string) => {
    const params = { Id: id, Kind: kind };
    const result = await GraphQL.query(getTerminalPropertiesById, params);
    const property = result.data.getTerminalPropertiesById
        ? result.data.getTerminalPropertiesById as TerminalProperty
        : null;
    return property;
} 