












import { Component, Vue } from 'vue-property-decorator';
import TerminalOperationList from '@/components/terminalOperationHistories/TerminalOperationList.vue';
import ListSearchHeader from '@/components/functionalities/ListSearchHeader.vue';

@Component({
  components: {
    TerminalOperationList,
    ListSearchHeader,
  },
})
export default class LogsDeviceOperation extends Vue {

  terminalId?: string = "";
  search = "";

  async created() {
    this.terminalId = this.$route.query.terminal
      ? this.$route.query.terminal as string
      : undefined;
  }

}
