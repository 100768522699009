























/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Component, Vue } from "vue-property-decorator";
import TerminalList from "@/components/terminals/TerminalList.vue";
import TerminalListHeader from "@/components/terminals/TerminalListHeader.vue";

@Component({
  components: {
    TerminalList,
    TerminalListHeader,
  },
})
export default class StoreTerminals extends Vue {

  companyId: string | null = null;
  storeId: string | null = null;
  search = "";
  status = "";
  disability = "";

  async created() {
    this.companyId = this.$route.params.companyId;
    this.storeId = this.$route.params.storeId;
  }
}
