var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"loading-text":_vm.messages.loading,"sort-by":"TimestampMs","sort-desc":true,"search":_vm.search},scopedSlots:_vm._u([{key:"item.TimestampMs",fn:function(ref){
var item = ref.item;
return [_c('p',{class:item.TimestampMs},[_vm._v(_vm._s(_vm.unixMsToDateTime(item.TimestampMs)))])]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.Status,attrs:{"small":"","text-color":"white"}},[_vm._v(_vm._s(_vm.getStatusLabel(item.Status)))])]}},{key:"item.ShopId",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getShopName(item.ShopId)))]}},{key:"item.Card.Kind",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getCardKindLabel(item.Card.Kind)))]}},{key:"item.TotalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.TotalAmount.toLocaleString()))]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("データなし")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }