import { render, staticRenderFns } from "./HealthCheck.vue?vue&type=template&id=7fe1cf35&scoped=true&lang=pug&"
import script from "./HealthCheck.vue?vue&type=script&lang=ts&"
export * from "./HealthCheck.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fe1cf35",
  null
  
)

export default component.exports