






















import { Component, Prop } from "vue-property-decorator";
import MasterDataList from '@/components/abstracts/MasterDataList';
import CompanyDetail from '@/components/companies/CompanyDetail.vue';
import { CompanyEntity } from '@/models/internal/companyEntity.model';


@Component({
    components: {
        CompanyDetail,
    }
})
export default class CompanyList extends MasterDataList<CompanyEntity> {
  @Prop({ type: String, required: false, default: null })
  company?: string;
  @Prop({ type: String, required: false, default: null })
  search?: string;

  // /** 削除済み企業を表示するかどうか */
  isShowDeleted = false;
  // // NOTE: 共通管理と同じように削除済みを表示する必要が出た場合のために準備

  // -------------------------

  headers = [
    {
      text: 'ID',
      value: 'base.Id',
    },
    {
      text: '企業',
      value: 'base.DisplayName',
    },
    {
      text: '作成日',
      value: 'base.CreatedAt',
    },
  ];

  // -------------------------

  protected get items() {
    return this.companies.filter(x => {
      if (this.company && this.company.length > 0) {
        if (x.base.Id != this.company) return false;
      }
      if (!this.isShowDeleted) {
        if (x.base.IsDelete) return false;
      }
      return true;
    })
  }

  // -------------------------
}
