



















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HealthCheckResult extends Vue {
  data = [
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
    { confirmation: '確認項目', result: '○' },
  ];
}
