import { RouteConfig } from 'vue-router';
//-------
import CompanyAccounts from '@/views/account/CompanyAccounts.vue';
import StoreAccounts from '@/views/account/StoreAccounts.vue';
//-------
import CompanyTerminals from '@/views/device/CompanyTerminals.vue';
import StoreTerminals from '@/views/device/StoreTerminals.vue';
//-------
import CompanyStores from '@/views/store/CompanyStores.vue';
import UniqueStore from '@/views/store/UniqueStore.vue';
// ------
import StoreTransactions from '@/views/transaction/StoreTransactions.vue';
import TerminalTransactions from '@/views/transaction/TerminalTransactions.vue';
// ------
import TerminalMalfunctions from '@/views/terminal/TerminalMalfunctions.vue'


// FIXME: children で書き直したい。

export const companies: RouteConfig[] = [
    // 企業・店舗別 / アカウント
    {
        path: '/companies/:companyId/accounts',
        name: 'CompanyAccounts',
        component: CompanyAccounts,
        meta: { requiresAuth: true },
    },
    {
        path: '/companies/:companyId/stores/:storeId/accounts',
        name: 'StoreAccounts',
        component: StoreAccounts,
        meta: { requiresAuth: true },
    },
    // 企業・店舗別 / 端末
    {
        path: '/companies/:companyId/terminals',
        name: 'CompanyTerminals',
        component: CompanyTerminals,
        meta: { requiresAuth: true },
    },
    {
        path: '/companies/:companyId/stores/:storeId/terminals',
        name: 'StoreTerminals',
        component: StoreTerminals,
        meta: { requiresAuth: true },
    },
    // 店舗別 / 取引履歴
    {
        path: '/companies/:companyId/stores/:storeId/transactions',
        name: 'StoreTransactions',
        component: StoreTransactions,
        meta: { requiresAuth: true },
    },
    // 企業・店舗別 / 端末 / 取引履歴
    {
        path: '/companies/:companyId/stores/:storeId/terminals/:terminalId/transactions',
        name: 'StoreTerminalTransactions',
        component: TerminalTransactions,
        meta: { requiresAuth: true },
    },
    // 企業・店舗別 / 端末 / 障害履歴
    {
        path: '/companies/:companyId/stores/:storeId/terminals/:terminalId/malfunctions',
        name: 'StoreTerminalMalfunctions',
        component: TerminalMalfunctions,
        meta: { requiresAuth: true },
    },
    // TODO: 端末グループ
    // 企業別 / 店舗
    {
        path: '/companies/:companyId/stores',
        name: 'CompanyStores',
        component: CompanyStores,
        meta: { requiresAuth: true },
    },
    // 特定店舗のみのリスト
    {
        path: '/companies/:companyId/stores/:storeId',
        name: 'UniqueStore',
        component: UniqueStore,
        meta: { requiresAuth: true },
    },
];