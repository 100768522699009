


























import { Component, Vue } from "vue-property-decorator";
import TransactionList from '@/components/transactions/TransactionList.vue';
import TransactionListHeader from '@/components/transactions/TransactionListHeader.vue';
import { statusItems, kindItems } from '@/models/view/transaction';


@Component({
  components: {
    TransactionListHeader,
    TransactionList,
  },
})
export default class TerminalTransactions extends Vue {

    companyId? = "";
    storeId? = "";
    terminalId? = "";
    kind = "";
    status = "";
    search = "";
    range: Array<string> = [];

    get statusItems() {
      return statusItems;
    }
    get kindItems() {
      return kindItems;
    }

    async created() {
        this.companyId = this.$route.params.companyId;
        this.storeId = this.$route.params.storeId;
        this.terminalId = this.$route.params.terminal;
    }
}
