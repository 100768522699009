import { RouteConfig } from 'vue-router';
import Device from '../views/device/Device.vue';
import Discontinuation from '../views/device/Discontinuation.vue';
import DeviceTrouble from '../views/device/DeviceTrouble.vue';
import Operation from '../views/device/operation/Operation.vue';
import OperationLogo from '../views/device/operation/OperationLogo.vue';
import OperationTaxOffice from '../views/device/operation/OperationTaxOffice.vue';
import HealthCheck from '../views/device/healthCheck/HealthCheck.vue';
import HealthCheckResult from '../views/device/healthCheck/HealthCheckResult.vue';
import DeviceMaintenance from '../views/device/maintenance/DeviceMaintenance.vue';
import DeviceMaintenanceConnect from '../views/device/maintenance/DeviceMaintenanceConnect.vue';
import DeviceMaintenanceHistory from '../views/device/maintenance/DeviceMaintenanceHistory.vue';

// TODO: 古いコードなので整理対象. 使っていないものも多い

export const device: RouteConfig[] = [
    {
        path: '/device',
        name: 'Device',
        component: Device,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/discontinuation',
        name: 'Device-Discontinuation',
        component: Discontinuation,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/trouble',
        name: 'Device-Trouble',
        component: DeviceTrouble,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/operation',
        name: 'Device-Operation',
        component: Operation,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/operation/logo',
        name: 'Device-Operation-Logo',
        component: OperationLogo,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/operation/taxisOffice',
        name: 'Device-Operation-TaxOffice',
        component: OperationTaxOffice,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/healthCheck',
        name: 'Device-HealthCheck',
        component: HealthCheck,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/healthCheck/result',
        name: 'Device-HealthCheck-Result',
        component: HealthCheckResult,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/maintenance',
        name: 'Device-Maintenance',
        component: DeviceMaintenance,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/maintenance/connect',
        name: 'Device-Maintenance-Connect',
        component: DeviceMaintenanceConnect,
        meta: { requiresAuth: true },
    },
    {
        path: '/device/maintenance/history',
        name: 'Device-Maintenance-History',
        component: DeviceMaintenanceHistory,
        meta: { requiresAuth: true },
    },
];