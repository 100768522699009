
export function emptyToNull (
    value: string | null | undefined,
    mode: "plane" | "s3arn" = "plane"
) {
    if (!value) return null;
    if (value.length == 0) return null;
    if (mode == "s3arn") {
      // arn:aws:s3:::test-topup-brandingassets/Manjaro_logo_text.png
      if (value.indexOf("arn:aws:s3:::") != 0) return null;
    }
    return value;
}

  // NOTE: デフォルトロゴは無しでOK
export function emptyToDefault (
    value: string | null | undefined,
    defaultValue: string,
    mode: "plane" | "s3arn" = "plane"
) {
    return emptyToNull(value, mode) || defaultValue;
}

/** booleanのフィールドについてnull/undefinedの場合はデフォルト値を返す */
export function emptyToDefaultBool(
    value: boolean | null | undefined,
    defaultValue: boolean
) {
    if (value == undefined) return defaultValue;
    if (value == null) return defaultValue;
    return value;
}
  