






































































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import DialogWithMasters from "@/components/abstracts/DialogWithMasters";
import AssetImageFileInput from "@/components/functionalities/AssetImageFileInput.vue";
import { TerminalEntity } from "../../models/internal/terminalEntity.model";
import { AuthUser } from "@/models/internal/user.model";
import {
  emptyToNull,
  emptyToDefault,
  emptyToDefaultBool,
} from "@/tools/editUtils";
import { TerminalPropertyConfig } from "@/models/topup/terminalProperty.model";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";


@Component({
  components: {
    AssetImageFileInput,
  },
})
export default class TerminalEdit extends DialogWithMasters {
  @Prop({ type: Object, required: true })
  terminal?: TerminalEntity;

  @Getter("auth/user") user!: AuthUser;

  @Action("terminals/updateTerminalProperties")
  updateTerminalProperties!: (payload: any) => void;

  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;

  // @Watch("terminal", { immediate: false, deep: true })
  // onTerminalChange() {
  //   if (this.terminal?.property?.Shiagel?.AuthCode) {
  //     this.authCode = this.terminal.property.Shiagel.AuthCode;
  //   } else {
  //     this.authCode = "";
  //   }
  //   this.error.status = false;
  //   this.error.message = "";
  // }

  @Watch("user", { immediate: true, deep: true })
  watchUser() {
    this.isShopUser = this.user?.role === "ShopUser" ? true : false;
  }

  closeEventName = 'terminal-edit-close-event';

  isShopUser = false;

  panel = "location";

  deviceEditFormValid = true;
  terminalPropsEdit = false;

  previousConfig = {} as TerminalPropertyConfig;

  edit = {
    error: {
      status: false,
      message: "",
    },
  };


  volumeBranding = 0;
  volumeAttention = 0;
  volumeInformation = 0;
  backgroundImageLabel: string | null = null;
  backgroundImageColor: string | null = null;
  topupButtonBackgroundColor: string | null = null;
  topupButtonShadowColor: string | null = null;
  topupButtonFontColor: string | null = null;
  topupButtonBorderColor: string | null = null;
  balanceButtonBackgroundColor: string | null = null;
  balanceButtonShadowColor: string | null = null;
  balanceButtonFontColor: string | null = null;
  balanceButtonBorderColor: string | null = null;
  timeout: number | null = null;
  scheduleAllDay: boolean | null = null;
  scheduleEndMode: string | null = null;
  isUsePoints: boolean | null = null;
  standByMode: string | null = null;
  standByImageLabel: string | null = null;
  currentItem: TerminalEntity | null = null;

  editViewModel = {
    prop: {
      name: "",
    },
    location: {
      settingLocation: "",
      inquiryInfo: "",
    },
    sound: {},
    logo: {
      // TODO:  変更した場合のみ saveFileS3したい
      top: {
        position: "left",
        arn: "",
      },
      bottom: {
        position: "left",
        arn: "",
      },
      receiptImage: "",
    },
    design: {},
    timeout: {
        defaultTimeout: 0,
    },
    schedule: {
      startTime: "10:00",
      endTime: "19:00",
      // 無人店舗設定
      hasSupportStaff: true,
      notInServiceArn: "",
    },
    // ポイント設定は Shiagel の設定を表示するのみ
    point: {
      moneyName: "電子マネー", // Shiagelの設定から
      moneyUnit: "円", // Shiagelの設定から
      pointName: "ポイント", // Shiagelの設定から
      pointUnit: "pt", // Shiagelの設定から
      secondPointName: "セカンドP", // Shiagelの設定から
      secondPointUnit: "P", // Shiagelの設定から
    },
    amountLimit: {
      dailyUpperLimit: 10000, // 店舗設定にあれば、初期値はコピーする
      totalUpperLimit: 50000, // Shiagelの設定から
    },
  };

  mounted() {
    // if (this.terminal?.property?.Shiagel?.AuthCode) {
    //   this.authCode = this.terminal.property.Shiagel.AuthCode;
    // } else {
    //   this.authCode = "";
    // }
    // this.error.status = false;
    // this.error.message = "";
    this.load();
  }


  
  // ------------------------------

  load() {
    if (!this.terminal) {
      throw new Error("invalid operation");
    }

    if (!this.terminal.property) throw new Error("invalid operation");

    this.editViewModel.prop.name =
    this.terminal.base.DisplayName || this.terminal.property?.DisplayName || this.terminal.base.Id;

    const company = this.companies.filter(
    (v) => v.base.Id == this.terminal?.property?.CompanyId
    )[0];
    const shop = this.shops.filter(
    (v) => v.base.Id == this.terminal?.property?.ShopId
    )[0];
    if (!shop || !company) {
    throw new Error("company/shop is not found");
    }

    // if (this.terminal.property.Configs) {
    const config = this.terminal.property.Configs;

    this.editViewModel.location.settingLocation = config?.Location || "";
    this.editViewModel.location.inquiryInfo =
    config?.InquiryInfo || shop.property?.InquiryInfo || "";

    // スケジュール設定
    this.editViewModel.schedule.startTime = emptyToDefault(
    config?.WorkSchedule.StartTime,
    "10:00"
    );
    this.editViewModel.schedule.endTime = emptyToDefault(
    config?.WorkSchedule.EndTime,
    "19:00"
    );
    this.editViewModel.schedule.hasSupportStaff = emptyToDefaultBool(
    config?.HasSupportStaff,
    true
    );

    this.editViewModel.amountLimit.dailyUpperLimit =
    config?.UpperLimitAmount || company.property?.MaxTopupAmount || 0;

    //ターミナルプロパティ設定がないと、null値にする。
    this.volumeBranding = config?.Volumes.Branding || 50;
    this.volumeAttention = config?.Volumes.Attention || 50;
    this.volumeInformation = config?.Volumes.Information || 50;

    // ロゴ関連
    this.editViewModel.logo.top.arn = config?.BrandingLogo.Top.Arn || "";
    this.editViewModel.logo.top.position = emptyToDefault(
    config?.BrandingLogo.Top.Position,
    "left"
    );
    this.editViewModel.logo.bottom.arn =
    config?.BrandingLogo.Bottom.Arn || "";
    this.editViewModel.logo.bottom.position = emptyToDefault(
    config?.BrandingLogo.Bottom.Position,
    "left"
    );
    this.editViewModel.logo.receiptImage =
    config?.BrandingLogo.ReceiptImage || "";

    // ダッシュボード関連
    this.backgroundImageLabel =
    config?.Dashboard.Background.Image.Arn || null;

    this.standByImageLabel = config?.Standby.Signage.Slide.Arns[0] || null;
    this.editViewModel.timeout.defaultTimeout = config?.Timeout.DefaultTimeout || 30; // デフォルト30秒
    this.editViewModel.schedule.notInServiceArn = config?.WorkSchedule.NotInService.Arn || "";
    // }

    // Shiagelの設定から取得する項目
    // if (this.terminal.property.Shiagel) {
    const shiagel = this.terminal.property.Shiagel;
    this.editViewModel.point.moneyName =
    shiagel?.OperationalConfigs.Money?.Name || "";
    this.editViewModel.point.moneyUnit =
    shiagel?.OperationalConfigs.Money?.Unit || "";
    this.editViewModel.point.pointName =
    shiagel?.OperationalConfigs.Point?.Name || "";
    this.editViewModel.point.pointUnit =
    shiagel?.OperationalConfigs.Point?.Unit || "";
    this.editViewModel.point.secondPointName =
    shiagel?.OperationalConfigs.SecondPoint?.Name || "";
    this.editViewModel.point.secondPointUnit =
    shiagel?.OperationalConfigs.SecondPoint?.Unit || "";
    this.editViewModel.amountLimit.totalUpperLimit =
    shiagel?.OperationalConfigs.Money?.UpperLimitAmount || 0;
    // }

    this.scheduleAllDay = emptyToDefaultBool(config?.WorkSchedule.All, false);
    this.scheduleEndMode = emptyToDefault(
    config?.WorkSchedule.EndMode,
    "sleep"
    );
    this.isUsePoints = emptyToDefaultBool(config?.Point.Use, true);
    this.standByMode = emptyToDefault(config?.Standby.Mode, "sleep");

    // 色関連
    this.backgroundImageColor = emptyToDefault(
    config?.Dashboard.Background.Color,
    "#E2EAFD"
    );
    this.topupButtonBackgroundColor = emptyToDefault(
    config?.Dashboard.TopUpButton.BackgroundColor,
    "#F9FBFF"
    );
    this.topupButtonBorderColor = emptyToDefault(
    config?.Dashboard.TopUpButton.BorderColor,
    "#E6EAF1"
    );
    this.topupButtonShadowColor = emptyToDefault(
    config?.Dashboard.TopUpButton.ShadowColor,
    "#005AA0"
    );
    this.topupButtonFontColor = emptyToDefault(
    config?.Dashboard.TopUpButton.FontColor,
    "#46546D"
    );
    this.balanceButtonBackgroundColor = emptyToDefault(
    config?.Dashboard.BalanceButton.BackgroundColor,
    "#F9FBFF"
    );
    this.balanceButtonBorderColor = emptyToDefault(
    config?.Dashboard.BalanceButton.BorderColor,
    "#E6EAF1"
    );
    this.balanceButtonShadowColor = emptyToDefault(
    config?.Dashboard.BalanceButton.ShadowColor,
    "#005AA0"
    );
    this.balanceButtonFontColor = emptyToDefault(
    config?.Dashboard.BalanceButton.FontColor,
    "#46546D"
    );

    this.terminalPropsEdit = this.terminal.property.Configs ? true : false;


    // this.panel = "sound";
    // this.deviceEdit = true;
    this.edit.error.status = false;
    this.edit.error.message = "";

    const emptyToNull = (value: string, mode: "plane" | "s3arn" = "plane") => {
      if (value.length == 0) return null;
      if (mode == "s3arn") {
        // arn:aws:s3:::test-topup-brandingassets/Manjaro_logo_text.png
        if (value.indexOf("arn:aws:s3:::") != 0) return null;
      }
      return value;
    };
    this.previousConfig = {
      Volumes: {
        Branding: this.volumeBranding,
        Attention: this.volumeAttention,
        Information: this.volumeInformation,
      },
      BrandingLogo: {
        Top: {
          Position: this.editViewModel.logo.top.position,
          Arn: emptyToNull(this.editViewModel.logo.top.arn, "s3arn"),
        },
        Bottom: {
          Position: this.editViewModel.logo.bottom.position,
          Arn: emptyToNull(this.editViewModel.logo.bottom.arn, "s3arn"),
        },
        ReceiptImage: emptyToNull(
          this.editViewModel.logo.receiptImage,
          "s3arn"
        ),
      },
      Timeout: {
        DefaultTimeout: this.editViewModel.timeout.defaultTimeout,
      },
      UpperLimitAmount: this.editViewModel.amountLimit.dailyUpperLimit,
      WorkSchedule: {
        All: this.scheduleAllDay,
        StartTime: emptyToNull(this.editViewModel.schedule.startTime),
        EndTime: emptyToNull(this.editViewModel.schedule.endTime),
        EndMode: this.scheduleEndMode,
        NotInService: {
          Arn: emptyToNull(
            this.editViewModel.schedule.notInServiceArn,
            "s3arn"
          ),
        },
      },
      Point: {
        Use: this.isUsePoints,
      },
      Standby: {
        Mode: this.standByMode,
        Signage: {
          Slide: {
            Arns: [],
            Interval: 0, //need to fix
          },
        },
      },
      Dashboard: {
        Background: {
          Color: this.backgroundImageColor,
          Image: {
            Arn: this.backgroundImageLabel,
          },
        },
        TopUpButton: {
          BackgroundColor: this.topupButtonBackgroundColor,
          BorderColor: this.topupButtonBorderColor,
          ShadowColor: this.topupButtonShadowColor,
          FontColor: this.topupButtonFontColor,
        },
        BalanceButton: {
          BackgroundColor: this.balanceButtonBackgroundColor,
          BorderColor: this.balanceButtonBorderColor,
          ShadowColor: this.balanceButtonShadowColor,
          FontColor: this.balanceButtonFontColor,
        },
        Logo: {
          Arn: "",
        },
      },
      Background: {
        Color: this.backgroundImageColor,
        Image: {
          Arn: this.backgroundImageLabel,
        },
      },
      Location: this.editViewModel.location.settingLocation,
      InquiryInfo: this.editViewModel.location.inquiryInfo,
      HasSupportStaff: this.editViewModel.schedule.hasSupportStaff,
    };
  }

  /** 端末詳細の編集結果を保存してダイアログを閉じる */
  async save() {
    try {
      // 入力値チェック
      const form = this.$refs.deviceEditForm as any;
      if (form.validate() == false) {
        console.log("save", "入力値に誤りがある");
        return;
      }

      if (!this.terminal || !this.terminal.property) {
        throw new Error("invalid operation");
      }

      // const defaultLogo = "arn:aws:s3:::test-topup-brandingassets/default_logo.bmp";

      const config: TerminalPropertyConfig = {
        Volumes: {
          Branding: Math.floor(this.volumeBranding),
          Attention: Math.floor(this.volumeAttention),
          Information: Math.floor(this.volumeInformation),
        },
        BrandingLogo: {
          Top: {
            Position: emptyToDefault(
              this.editViewModel.logo.top.position,
              "left"
            ),
            Arn: emptyToNull(this.editViewModel.logo.top.arn, "s3arn"),
          },
          Bottom: {
            Position: emptyToDefault(
              this.editViewModel.logo.bottom.position,
              "left"
            ),
            Arn: emptyToNull(this.editViewModel.logo.bottom.arn, "s3arn"),
          },
          ReceiptImage: emptyToNull(
            this.editViewModel.logo.receiptImage,
            "s3arn"
          ),
        },
        Timeout: {
          DefaultTimeout: this.editViewModel.timeout.defaultTimeout,
        },
        UpperLimitAmount: this.editViewModel.amountLimit.dailyUpperLimit,
        WorkSchedule: {
          All: this.scheduleAllDay,
          StartTime: emptyToDefault(
            this.editViewModel.schedule.startTime,
            "10:00"
          ),
          EndTime: emptyToDefault(this.editViewModel.schedule.endTime, "19:00"),
          EndMode: emptyToDefault(this.scheduleEndMode, "sleep"),
          NotInService: {
            Arn: emptyToNull(
              this.editViewModel.schedule.notInServiceArn,
              "s3arn"
            ),
          },
        },
        Point: {
          Use: emptyToDefaultBool(this.isUsePoints, true),
        },
        Standby: {
          Mode: emptyToDefault(this.standByMode, "sleep"),
          Signage: {
            Slide: {
              Arns: [],
              Interval: 0, //need to fix
            },
          },
        },
        Dashboard: {
          Background: {
            Color: this.backgroundImageColor,
            Image: {
              Arn: emptyToDefault(this.backgroundImageLabel, "", "s3arn"), // NOT NULL
            },
          },
          TopUpButton: {
            BackgroundColor: this.topupButtonBackgroundColor,
            BorderColor: this.topupButtonBorderColor,
            ShadowColor: this.topupButtonShadowColor,
            FontColor: this.topupButtonFontColor,
          },
          BalanceButton: {
            BackgroundColor: this.balanceButtonBackgroundColor,
            BorderColor: this.balanceButtonBorderColor,
            ShadowColor: this.balanceButtonShadowColor,
            FontColor: this.balanceButtonFontColor,
          },
          Logo: {
            Arn: "",
          },
        },
        Background: {
          Color: this.backgroundImageColor,
          Image: {
            Arn: emptyToDefault(this.backgroundImageLabel, "", "s3arn"), // NOT NULL
          },
        },
        Location: this.editViewModel.location.settingLocation,
        InquiryInfo: this.editViewModel.location.inquiryInfo,
        HasSupportStaff: this.editViewModel.schedule.hasSupportStaff,
      };

      //ターミナルプロパティを更新
      const item = this.terminal.property;
      item.Configs = config;
      if (!item.TroubleShootingUrl) {
        // トラブルシュート用のURL
        // TODO: 適切な設定から引っ張ってくるようにする
        // NOTE: 既存の端末がデータを持っていない場合があるので、ここで差し込む
        item.TroubleShootingUrl =
          "https://test-topup-assets.s3.ap-northeast-1.amazonaws.com/app/troubleshoot.html";
      }
      // console.log("this.previousConfig");
      // console.log(this.previousConfig);
      // console.log("this.currentConfig");
      // console.log(item.Configs);
      // const diff: any = {
      //   OldValues: _.pickBy(this.previousConfig, (value: any, key: any) => {
      //     /* @ts-ignore */
      //     return !_.isEqual(value, config[key]);
      //   }),
      //   NewValues: _.pickBy(config, (value: any, key: any) => {
      //     /* @ts-ignore */
      //     return !_.isEqual(this.previousConfig[key], value);
      //   }),
      // };

      if (!item.CreatedAt) item.CreatedAt = Date.now();
      await this.updateTerminalProperties(item);

      // 操作履歴
      await this.loggingOperationHistory({
        user: this.user,
        viewName: "端末設定編集画面",
        viewId: "EditTerminalProperty",
        operationName: "端末設定 保存",
        operationId: "editTerminalProperty",
        detail: { terminalId: this.terminal.base.Id },
      });

      // this.loadTerminals();
      this.closeDialog();
    } catch (error) {
      console.error(error);
      this.edit.error.status = true;
      this.edit.error.message = "端末設定の保存に失敗しました。";
    }
  }

  closeDialog() {
    // this.deviceEdit = false;
    this.edit.error.status = false;
    this.edit.error.message = "";
    this.emitClose(); // 親に閉じる処理をさせる
  }

  // ------------------------------

  onAssetImageSaved(key: string, arn: string) {
    console.log("onAssetImageSaved", key, arn);

    if ("logo-top" == key) {
        this.editViewModel.logo.top.arn = arn;
    } else if ("logo-bottom" == key) {
        this.editViewModel.logo.bottom.arn = arn;
    } else if ("logo-receipt" == key) {
        this.editViewModel.logo.receiptImage = arn;
    } else if ("dashboard-background" == key) {
        this.backgroundImageLabel = arn;
    } else if ("schedule-notinservice" == key) {
        this.editViewModel.schedule.notInServiceArn = arn;
    }
  }

  onAssetImageCleared(key: string) {
    console.log("onAssetImageCleared", key);
    if (key == "logo-top") {
      this.editViewModel.logo.top.arn = "";
    } else if (key == "logo-bottom") {
      this.editViewModel.logo.bottom.arn = "";
    } else if (key == "logo-receipt") {
      this.editViewModel.logo.receiptImage = "";
    } else if (key == "dashboard-background") {
      this.backgroundImageLabel = "";
    } else if (key == "schedule-notinservice") {
      this.editViewModel.schedule.notInServiceArn = "";
    }
  }

  // ------------------------------

  /** カラーコードのバリデーション */
  validationColorCode(value: string | null | undefined) {
    if (!value || value.length == 0) {
      return "カラーコードを入力してください";
    }
    if (value.length != 7) {
      return "カラーコード 7文字 を入力してください";
    }
    if (!value.match(/^#([A-Fa-f0-9]{6})$/)) {
      return "正しいカラーコードを入力してください";
    }
    return true;
  }

  /** 自国のバリデーション */
  validationTime(value: string | null | undefined) {
    console.log("validationTime", value);
    if (!value || value.length == 0 || value.length != 5) {
      return "時刻を入力してください";
    }
    if (!value.match(/^([0-9]{2}:[0-9]{2})$/)) {
      return "正しい時刻を入力してください";
    }
    return true;
  }

  /** 数値のバリデーション */
  validationNumber(value: string | null | undefined) {
    console.log('validationNumber', typeof(value));
    if (!value || value.length == 0) {
      return "数値を入力してください";
    }
    // if (!value.match(/^([0-9]+)$/)) {
    //   return "正しい数値を入力してください";
    // }
    return true;
  }

}
